import { httpService, HttpEffect } from '@core/http';
import { ZoneId } from '@shared/modules/referentials/model';

export namespace ReferentialsService {
  const URI = '/referentials';

  export function getZoneIds(): HttpEffect<Array<ZoneId>> {
    return httpService.get(`${URI}/zone-ids`);
  }

  export function getTags(): HttpEffect<Array<string>> {
    return httpService.get(`${URI}/tags`);
  }
}
