import { Auth } from '@modules/auth/model';
import { OAuthService } from '@core/oauth/service';
import { OAuth } from '@core/oauth/model';

export namespace AuthService {
  export function authenticate({ email, password }: Auth.AuthenticateParams): OAuth.OAuthHttpEffect {
    return OAuthService.passwordStrategyRequest(email, password);
  }

  export function logout() {
    return OAuthService.removeOAuthTokensInStorage();
  }
}
