import { useProfile } from '@modules/profile/loader';
import { useLocation } from 'react-router-dom';
import { FavouritesUtils } from '@modules/favourites/utils';

export function useOnBoardingRedirection(): string | null {
  const location = useLocation();
  const profile = useProfile();

  if (
    !profile.favouriteOnboardingDone &&
    FavouritesUtils.canAccess(profile) &&
    !location.pathname.includes('/onboarding') &&
    !location.pathname.includes('/favourites')
  ) {
    return '/onboarding/favourites';
  }

  return null;
}
