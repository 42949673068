import React, { createElement, FC } from 'react';
import { Drawer, Group, SimpleGrid, Text, ThemeIcon, Title, UnstyledButton } from '@mantine/core';
import { LayoutConstants } from '@layout/constants';
import { IconArrowLeft, IconBookmark, IconFileLike, TablerIconsProps } from '@tabler/icons-react';

import * as Styled from './SupportDrawer.styles';
import { useTranslation } from 'react-i18next';
import { To } from 'react-router-dom';
import { Translations } from '@core/translations';
import { useSavAuthenticateUrl } from '@modules/profile/hooks';

interface SupportItem {
  label: Translations.TranslationInterpolation;
  icon: FC<TablerIconsProps>;
  to: To;
  openExternal?: boolean;
}

export const SUPPORT_DRAWER_HASH = '#support';

interface SupportDrawerCardProps {
  item: SupportItem;
  onClose: () => void;
}

const SupportDrawerCard: FC<SupportDrawerCardProps> = ({ item, onClose }) => {
  const { t } = useTranslation();

  return (
    <Styled.SupportDrawerCard
      to={item.to}
      onClick={onClose}
      {...(item.openExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
    >
      <ThemeIcon size={44} color="background.0" c="black">
        {createElement(item.icon, { size: 34, strokeWidth: 1 })}
      </ThemeIcon>

      <Text size="sm" c="dark.6">
        {item.label(t)}
      </Text>
    </Styled.SupportDrawerCard>
  );
};

interface SupportDrawerProps {
  open: boolean;
  onClose: () => void;
}

const SupportDrawer: FC<SupportDrawerProps> = ({ open, onClose }) => {
  const savUrlBuilder = useSavAuthenticateUrl();

  const { t } = useTranslation();

  const items: Array<SupportItem> = [
    {
      label: t => t('nav.support.link.tickets-history'),
      icon: IconBookmark,
      to: '/support/tickets',
    },
    {
      label: t => t('nav.support.link.documents'),
      icon: IconFileLike,
      to: savUrlBuilder('/product-limacapt'),
      openExternal: true,
    },
  ];

  return (
    <Drawer opened={open} onClose={onClose} withinPortal size={LayoutConstants.NAV_OPEN_WIDTH} withCloseButton={false}>
      <UnstyledButton onClick={onClose}>
        <Group>
          <ThemeIcon size={36} variant="outline" color="background.5" c="background.9">
            <IconArrowLeft />
          </ThemeIcon>

          <Title order={4}>{t('nav.support')}</Title>
        </Group>
      </UnstyledButton>

      <SimpleGrid cols={1} mt={32} spacing={10}>
        {items.map((item, i) => (
          <SupportDrawerCard key={i} item={item} onClose={onClose} />
        ))}
      </SimpleGrid>
    </Drawer>
  );
};

export default SupportDrawer;
