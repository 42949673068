import z from 'zod';
import { emailSchema, nonEmptyStringSchema } from '@shared/schemas';
import { Translations } from '@core/translations';
import { Role } from '@modules/roles/model';
import { FilterQueryParser, FilterUtils, SearchFilter } from '@shared/modules/filter';
import { PhoneNumber } from '@shared/modules/phone-number/model';
import { Address } from '@shared/modules/address/model';
import { ZoneId } from '@shared/modules/referentials/model';
import { Account } from '@modules/account/model';
import { Effect, ReadonlyArray } from 'effect';
import { QueryUtils } from '@shared/utils/queries';

// Pour résoudre les dépendances cycliques
const AccountId = z.string().uuid().brand<'AccountId'>();
type AccountId = z.infer<typeof AccountId>;

export namespace User {
  export const Id = z.string().uuid().brand<'Id'>();
  export type Id = z.infer<typeof Id>;

  export enum State {
    Invited = 'invited',
    Activated = 'activated',
  }

  export const userStateColor: Record<State, string> = {
    [State.Invited]: 'blue.5',
    [State.Activated]: 'lime.9',
  };

  export const userStateLabel: Translations.TranslatedEnumLabel<State> = {
    [State.Invited]: t => t('state.send', { ns: 'users' }),
    [State.Activated]: t => t('state.active', { ns: 'users' }),
  };

  export interface Filter extends SearchFilter {
    accountType: Role.AccountType | null;
    roleIds: ReadonlyArray.NonEmptyArray<Role.Id> | null;
    userState: State | null;
  }

  export const filterParser: FilterQueryParser<Filter> = query =>
    Effect.all({
      search: FilterUtils.getSearchQuery(query),
      accountType: QueryUtils.getEnumQuery(query, Role.AccountType, 'accountType'),
      roleIds: QueryUtils.getSchemaArrayQuery(query, Role.Id, 'roleIds'),
      userState: QueryUtils.getEnumQuery(query, State, 'userState'),
    });

  export interface RangeItem {
    id: Id;
    lastName: string | null;
    firstName: string | null;
    roleLabel: string;
    accountId: AccountId | null;
    accountType: Role.AccountType | null;
    accountName: string | null;
    email: string;
    phoneNumber: string | null;
    userState: State;
  }

  export const Params = z.object({
    accountType: z.nativeEnum(Role.AccountType),
    accountId: AccountId,
    lastName: nonEmptyStringSchema,
    firstName: nonEmptyStringSchema,
    email: emailSchema,
    postalAddress: Address,
    phoneNumber: PhoneNumber,
    language: z.nativeEnum(Translations.Language),
    zoneId: ZoneId,
    roleId: Role.Id,
  });
  export type Params = z.infer<typeof Params>;

  export interface Role {
    id: Role.Id;
    label: string;
  }
}

export interface User {
  id: User.Id;
  lastName: string | null;
  firstName: string | null;
  email: string;
  postalAddress: Address;
  phoneNumber: PhoneNumber | null;
  language: Translations.Language;
  zoneId: ZoneId;
  account: Account.Light;
  role: User.Role;
  state: User.State;
}
