import { TranslationsUtils } from '@core/translations';
import { z } from 'zod';
import { Instant } from '@shared/modules/dates';

export enum Countries {
  FR = 'FR',
  AU = 'AU',
  DE = 'DE',
  ES = 'ES',
  UK = 'UK',
}

export const countriesLabel = TranslationsUtils.createTranslatedEnumLabel(
  Countries,
  code => t => t(`country.${code.toLowerCase()}`, { ns: 'common' }),
);

export const Temperature = z.number().brand('Temperature');
export type Temperature = z.infer<typeof Temperature>;

export const Volume = z.number().brand('Volume');
export type Volume = z.infer<typeof Volume>;

export const Percentage = z.number().int().brand('Percentage');
export type Percentage = z.infer<typeof Percentage>;

export enum Direction {
  North = 'north',
  NorthWest = 'north-west',
  NorthEast = 'north-east',
  South = 'south',
  SouthWest = 'south-west',
  SouthEast = 'south-east',
  East = 'east',
  West = 'west',
}

export const directionOrientation: Record<Direction, number> = {
  [Direction.North]: 180,
  [Direction.NorthWest]: 135,
  [Direction.NorthEast]: 225,
  [Direction.South]: 0,
  [Direction.SouthWest]: 45,
  [Direction.SouthEast]: 315,
  [Direction.East]: 270,
  [Direction.West]: 90,
};

export const AbsoluteUrl = z.string().url().brand('AbsoluteUrl');
export type AbsoluteUrl = z.infer<typeof AbsoluteUrl>;

export const UUID = z.string().uuid().brand('UUID');
export type UUID = z.infer<typeof UUID>;

export const FiniteDuration = z.number().int().brand('FiniteDuration');
export type FiniteDuration = z.infer<typeof FiniteDuration>;

export interface Audit {
  createdAt: Instant;
  createdBy: string;
  updatedAt: Instant;
  updatedBy: string;
  deletedAt: Instant | null;
  deletedBy: string | null;
}

export const ElectricPotential = z.number().brand('ElectricPotential');
export type ElectricPotential = z.infer<typeof ElectricPotential>;

export const ElectricCurrent = z.number().brand('ElectricCurrent');
export type ElectricCurrent = z.infer<typeof ElectricCurrent>;

export const Power = z.number().brand('Power');
export type Power = z.infer<typeof Power>;

export const Velocity = z.number().brand('Velocity');
export type Velocity = z.infer<typeof Velocity>;
