import { z } from 'zod';
import { ActionFunctionArgs } from 'react-router-dom';
import {
  Action,
  ActionFlashOptions,
  ActionHandler,
  Actions,
  EmptyAction,
  FormDataAction,
  PayloadAction,
} from '@core/router/action/index';
import { parseParams, throwResponse } from '@core/router';
import { showFlashNotification } from '@shared/modules/notification/utils';
import { Translation } from 'react-i18next';
import { SentryUtils } from '@shared/modules/sentry/utils';
import { Cause, Console, Effect, Exit, Function, pipe, ReadonlyArray, String } from 'effect';

export function defineAction<
  PayloadSchema extends z.ZodType,
  ParamsSchema extends z.ZodType,
  Type extends string,
  R = unknown,
  E = unknown,
>(
  action: PayloadAction<PayloadSchema, ParamsSchema, R, E, Type>,
): PayloadAction<PayloadSchema, ParamsSchema, R, E, Type>;

export function defineAction<ParamsSchema extends z.ZodType, Type extends string, R = unknown, E = unknown>(
  action: FormDataAction<ParamsSchema, R, E, Type>,
): FormDataAction<ParamsSchema, R, E, Type>;

export function defineAction<ParamsSchema extends z.ZodType, Type extends string, R = unknown, E = unknown>(
  action: EmptyAction<ParamsSchema, R, E, Type>,
): EmptyAction<ParamsSchema, R, E, Type>;

export function defineAction(action: Action): Action {
  return action;
}

function findActionFromFormData(actions: Actions, formData: FormData) {
  return pipe(
    Effect.sync(() => formData.get('_type')),
    Effect.flatMap(type =>
      pipe(
        Object.values(actions),
        ReadonlyArray.findFirst(action => action.type === type),
        Effect.orDieWith(() => new Error(`Cannot find action with type ${type}`)),
      ),
    ),
  );
}

/**
 * Return payload if action is of type Payload
 *
 * @param action
 * @param formData
 */
function parseActionPayload(action: PayloadAction, formData: FormData) {
  const invalidPayload = throwResponse(new Response('[action] failed to parse payload', { status: 422 }));

  return pipe(
    Effect.fromNullable(() => formData.get('payload')),
    Effect.filterOrElse(String.isString, () => invalidPayload),
    Effect.flatMap(payload => Effect.try(() => JSON.parse(payload))),
    Effect.flatMap(payload => Effect.tryPromise(() => action.payload.parseAsync(payload))),
    Effect.tapError(err => Console.error('[action] failed to parse payload', err)),
    Effect.orElse(() => invalidPayload),
  );
}

export function actionHandler<A extends Actions>(
  actions: A,
): (args: ActionFunctionArgs) => Promise<Exit.Exit<unknown, unknown>> {
  return async args => {
    /**
     * Generic handler for action
     *
     * @param args
     * @param handler
     * @param flashOptions
     */
    const runAction = <Args, R, E>(
      args: Args,
      handler: ActionHandler<Args, R, E>,
      { success = Function.constNull, error = Function.constTrue }: ActionFlashOptions<Args, R, E> = {},
    ) => {
      const successFlashHandler = (result: R) => {
        const message = success({ ...args, result });

        if (message) {
          return showFlashNotification('success', message);
        }

        return Effect.unit;
      };

      const errorFlashHandler = (err: E) => {
        const message = error({ ...args, error: err });

        if (message) {
          return showFlashNotification(
            'error',
            message === true ? <Translation>{t => t('errors.technical')}</Translation> : message,
          );
        }

        return Effect.unit;
      };

      const loggerHandler = (err: E) => {
        const message = error({ ...args, error: err });

        if (message === true) {
          return SentryUtils.logMessage(`[router] action error`, 'error', { error });
        }

        return Effect.unit;
      };

      return pipe(
        handler(args),
        Effect.tap(successFlashHandler),
        Effect.tapError(errorFlashHandler),
        Effect.tapError(loggerHandler),
      );
    };

    return pipe(
      Effect.Do,
      Effect.let('request', () => args.request),
      Effect.bind('formData', ({ request }) => Effect.promise(() => request.formData())),
      Effect.bind('action', ({ formData }) => findActionFromFormData(actions, formData)),
      Effect.bind('params', ({ action }) => parseParams(args.params, action.params)),
      Effect.flatMap(({ request, formData, action, params }) => {
        if ('payload' in action) {
          return pipe(
            parseActionPayload(action, formData),
            Effect.flatMap(payload => runAction({ request, params, payload }, action.handler, action.flashOptions)),
          );
        }

        if ('formData' in action) {
          return runAction({ request, params, formData }, action.handler, action.flashOptions);
        }

        return runAction({ request, params }, action.handler, action.flashOptions);
      }),
      Effect.runPromiseExit,
    ).then(res => {
      return pipe(
        res,
        Exit.match<unknown, unknown, Exit.Exit<unknown, unknown>>({
          onSuccess: Exit.succeed,
          onFailure: cause => {
            // Si on a une Response dans le die, on throw la response
            if (Cause.isDieType(cause) && cause.defect instanceof Response) {
              throw cause.defect;
            }

            return Exit.failCause(cause);
          },
        }),
      );
    });
  };
}
