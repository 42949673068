import React, { FC, PropsWithChildren, useMemo } from 'react';
import { MantineProvider, MantineThemeOverride, useMantineTheme } from '@mantine/core';

import { pipe, ReadonlyRecord } from 'effect';

const COMPONENTS_TO_DISABLED = [
  'TextInput',
  'Textarea',
  'Select',
  'MultiSelect',
  'Radio',
  'Switch',
  'Checkbox',
  'NumberInput',
  'DatePickerInput',
];

interface DisableableFormProps {
  disabled?: boolean;
}

const DisableableForm: FC<PropsWithChildren<DisableableFormProps>> = ({ disabled, children }) => {
  const theme = useMantineTheme();

  const disabledTheme = useMemo<MantineThemeOverride | null>(() => {
    if (!disabled) {
      return null;
    }

    return {
      components: pipe(
        theme.components,
        ReadonlyRecord.map((themeComponent, name) => {
          if (COMPONENTS_TO_DISABLED.includes(name)) {
            const { defaultProps, ...rest } = themeComponent;

            return {
              ...rest,
              defaultProps:
                typeof defaultProps === 'function'
                  ? theme => ({
                      ...defaultProps(theme),
                      disabled: true,
                    })
                  : { ...defaultProps, disabled: true },
            };
          }

          return themeComponent;
        }),
      ),
    };
  }, [disabled, theme]);

  if (disabledTheme) {
    return (
      <MantineProvider theme={disabledTheme} inherit>
        {children}
      </MantineProvider>
    );
  }

  return <>{children}</>;
};

export function useIsFormDisabled() {
  const theme = useMantineTheme();

  const defaultProps = theme.components['TextInput']?.defaultProps ?? {};

  const disabled = (typeof defaultProps === 'function' ? defaultProps(theme) : defaultProps)?.disabled ?? false;

  return disabled;
}

export default DisableableForm;
