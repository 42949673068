import { CSSProperties, ReactNode } from 'react';

import isEqual from 'lodash.isequal';

import { Filter } from '@shared/modules/filter';
import { Sx } from '@mantine/core';
import { Option } from 'effect';

export class RangeCursor {
  static DEFAULT_SIZE = 50;

  constructor(
    public startIndex: number,
    public endIndex: number,
  ) {}

  static fromPage(page: number) {
    return new RangeCursor((page - 1) * RangeCursor.DEFAULT_SIZE, page * RangeCursor.DEFAULT_SIZE - 1);
  }

  static initial() {
    return RangeCursor.fromPage(0);
  }

  static fromIndex(index: number) {
    const startIndex = Math.max(0, index - RangeCursor.DEFAULT_SIZE / 2);

    return new RangeCursor(startIndex, startIndex + RangeCursor.DEFAULT_SIZE - 1);
  }

  toPage() {
    return Math.floor(this.startIndex / RangeCursor.DEFAULT_SIZE) + 1;
  }
}

export interface RangeResult<T, F extends Filter = {}> extends Required<Pick<RangeCursor, 'startIndex' | 'endIndex'>> {
  total: number;
  items: Array<T>;
  filter: F;
  sort: string | null;
}

export type NestedRangeResult<Key extends string, T, F extends Filter = {}> = {
  [key in Key]: RangeResult<T, F>;
};

export class Range<T, F extends Filter = {}> {
  constructor(
    readonly items: ReadonlyMap<number, T>,
    readonly total: number,
    readonly filter: F,
    readonly sort: string | null,
  ) {}

  merge(newRange: Range<T, F>): Range<T, F> {
    if (isEqual(this.filter, newRange.filter)) {
      return new Range<T, F>(
        new Map<number, T>([...Array.from(this.items.entries()), ...Array.from(newRange.items.entries())]),
        newRange.total,
        newRange.filter,
        newRange.sort,
      );
    }

    return newRange;
  }

  has(index: number): boolean {
    return this.items.has(index);
  }

  get(index: number): Option.Option<T> {
    return Option.fromNullable(this.items.get(index));
  }

  toList(): Array<T> {
    return Array.from(this.items.values());
  }

  map<B>(fa: (a: T) => B): Range<B, F> {
    return new Range(
      new Map<number, B>(Array.from(this.items, ([key, value]) => [key, fa(value)])),
      this.total,
      this.filter,
      this.sort,
    );
  }

  setFilter(newFilter: F): Range<T, F> {
    return new Range(this.items, this.total, newFilter, this.sort);
  }

  static fromRangeResult<T, F extends Filter = {}>(result: RangeResult<T, F>) {
    return new Range(
      new Map<number, T>(result.items.map((item, i) => [i + result.startIndex, item])),
      result.total,
      result.filter,
      result.sort,
    );
  }

  static fromArray<T, F extends Filter = {}>(list: Array<T>, filter: F, sort?: string | null): Range<T, F> {
    return new Range<T, F>(new Map<number, T>(list.map((item, i) => [i, item])), list.length, filter, sort ?? null);
  }
}

export interface VirtualizedListChildrenProps<T> {
  item: T;
  index: number;
  style: CSSProperties;
  ref: (element: Element | null) => void;
}

export type VirtualizedRenderer<T> = (item: T, index: number) => ReactNode;

export interface VirtualizedListColumn<T> {
  key: Extract<keyof T, string> | string;
  sortable?: boolean;
  label?: ReactNode;
  width?: string;
  show?: boolean;
  headerStyle?: Sx;
  style?: Sx;
  renderer?: VirtualizedRenderer<T>;
}

export type VirtualizedListDesktopTemplate<T> = Array<VirtualizedListColumn<T>>;
export type VirtualizedListRowStyle<T> = (item: T, index: number) => CSSProperties | undefined;

export interface VirtualizedListTemplate<T> {
  desktopTemplate: VirtualizedListDesktopTemplate<T>;
  mobileTemplate?: VirtualizedRenderer<T>;
  rowStyle?: VirtualizedListRowStyle<T>;
}

export interface VirtualizedListProps<T> extends VirtualizedListTemplate<T> {
  range: Range<T>;
  count?: string;
  actions?: ReactNode;
  loadPage: (page: number) => void;
  onSort: (sort: string | null) => void;
  getItemKey?: (item: T, index: number) => string | number;
}
