import React, { forwardRef } from 'react';

import { BoxProps, createPolymorphicComponent } from '@mantine/core';

import * as Styled from './BottomPage.styles';

const defaultBoxProps: BoxProps = {
  px: 20,
  py: 10,
  bg: 'background.0',
};

const _Test = forwardRef<HTMLDivElement, BoxProps>(({ children, ...providedProps }, ref) => {
  const props = {
    ...defaultBoxProps,
    ...providedProps,
  };

  return (
    <Styled.BottomPageContainer ref={ref} {...props}>
      {children}
    </Styled.BottomPageContainer>
  );
});

export const BottomPage = createPolymorphicComponent<'div', BoxProps>(_Test);
