import styled from '@emotion/styled';

export const AuthLayoutContainer = styled.div`
  display: grid;
  grid-template: 100% / 50% 50%;
  grid-template-areas: 'content background';
  height: 100vh;
  overflow: hidden;

  ${props => props.theme.fn.smallerThan('md')} {
    grid-template: 160px 1fr / 1fr;
    overflow: auto;
    grid-template-areas: 'background' 'content';
    height: auto;
    min-height: 100vh;
  }
`;

export const AuthLayoutBackgroundWrapper = styled.div`
  position: relative;
  grid-area: background;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
  }

  ${props => props.theme.fn.smallerThan('md')} {
    height: 200px;
  }
`;

export const AuthLayoutContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px 160px;
  background: ${props => props.theme.colors.background[0]};
  overflow-y: auto;

  ${props => props.theme.fn.smallerThan('md')} {
    padding: 20px;
    border-radius: 20px 20px 0 0;
    z-index: 1;
  }
`;

export const AuthLayoutLogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 53px;

  ${props => props.theme.fn.smallerThan('md')} {
    padding-bottom: 33px;
  }
`;

export const AuthLayoutContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;

  ${props => props.theme.fn.smallerThan('md')} {
    align-items: flex-start;
  }
`;

export const AuthLayoutContent = styled.div`
  width: 444px;
  max-width: 100%;
`;
