import { OAuth } from '@core/oauth/model';
import { HttpError, httpService } from '@core/http';
import * as localforage from 'localforage';
import config from '@root/config';
import queryString from 'query-string';
import { getApiBaseURL } from '@core/http/utils';
import { AuthUtils } from '@modules/auth/utils';
import { Auth } from '@modules/auth/model';
import { createPath } from 'history';
import { resolvePath } from 'react-router-dom';
import { Effect, Option, pipe } from 'effect';
import { EffectUniq } from '@core/fp';

const tokensStorage = localforage.createInstance({
  name: 'limacapt',
  storeName: 'oauth',
});

const TOKENS_KEY = 'tokens';

export namespace OAuthService {
  import OAuthHttpError = OAuth.OAuthHttpError;

  const uniqRefreshToken = new EffectUniq<OAuth.OAuthHttpError, void>();

  export function getOAuthTokensFromStorage() {
    return pipe(
      Effect.promise(() => tokensStorage.getItem<OAuth.SavedOAuthTokens>(TOKENS_KEY)),
      Effect.map(Option.fromNullable),
    );
  }

  export function saveOAuthTokensInStorage({ refresh_token, access_token }: OAuth.SavedOAuthTokens) {
    return Effect.promise(() =>
      tokensStorage.setItem<OAuth.SavedOAuthTokens>(TOKENS_KEY, { refresh_token, access_token }),
    );
  }

  export function removeOAuthTokensInStorage() {
    return Effect.promise(() => tokensStorage.removeItem(TOKENS_KEY));
  }

  export function sendOAuthRequest(request: OAuth.OAuthRequest): OAuth.OAuthHttpEffect {
    return pipe(
      httpService.post<OAuth.OAuthTokens, OAuth.OAuthError>(`/tokens`, queryString.stringify(request), {
        baseURL: getApiBaseURL(config.VITE_API_OAUTH_PREFIX),
      }),
      Effect.tap(saveOAuthTokensInStorage),
      Effect.asUnit,
    );
  }

  export function passwordStrategyRequest(email: string, password: string): OAuth.OAuthHttpEffect {
    return sendOAuthRequest({ grant_type: 'password', username: email, password });
  }

  export function refreshToken(): OAuth.OAuthHttpEffect {
    const task = pipe(
      getOAuthTokensFromStorage(),
      Effect.map(Option.flatMapNullable(({ refresh_token }) => refresh_token)),
      Effect.flatMap(
        Option.match({
          onSome: Effect.succeed,
          onNone: () => Effect.fail(HttpError.notFound as OAuthHttpError),
        }),
      ),
      Effect.flatMap(refresh_token =>
        pipe(
          sendOAuthRequest({
            grant_type: 'refresh_token',
            refresh_token,
          }),
          Effect.tapError(() =>
            pipe(
              removeOAuthTokensInStorage(),
              Effect.flatMap(() =>
                Effect.sync(() =>
                  window.location.replace(
                    createPath(resolvePath(AuthUtils.createLoginLink(true, Auth.Reason.Expired))),
                  ),
                ),
              ),
              // On temporise le temps que la redirection passe
              Effect.flatMap(() => Effect.sleep(10000)),
            ),
          ),
        ),
      ),
    );

    return uniqRefreshToken.lock(task);
  }
}
