import React, { FC, useState } from 'react';
import { ActionIcon, Anchor, Avatar, Box, Button, Divider, Group, Popover, Stack, Text } from '@mantine/core';
import { useProfile } from '@modules/profile/loader';
import { IconArrowLeft, IconMail } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import * as Styled from './UserMenu.styles';
import { useTranslation } from 'react-i18next';
import { UsersUtils } from '@modules/users/utils';
import { useProfilePredicate } from '@modules/profile/hooks';
import { ProfilePredicates } from '@modules/profile/predicates';
import { Role } from '@modules/roles/model';
import AccountLabel from '@modules/account/components/label/AccountLabel';
import { useSendTask } from '@core/http/hooks';
import { ProfileService } from '@modules/profile/service';
import { Effect } from 'effect';

const UserMenu: FC = () => {
  const { t } = useTranslation();

  const profile = useProfile();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const [, downloadTos] = useSendTask(ProfileService.downloadTos);

  const toggleMenu = () => setMenuOpen(old => !old);
  const closeMenu = () => setMenuOpen(false);

  const isDS = useProfilePredicate(ProfilePredicates.hasAccountType(Role.AccountType.DeSangosse));
  const isAgri = useProfilePredicate(ProfilePredicates.hasAccountType(Role.AccountType.Farmer));

  const canAccessMyAccount = useProfilePredicate(ProfilePredicates.canAccess(Role.AccessKey.MyAccount));

  const handleDownload = () => Effect.runPromise(downloadTos());

  return (
    <Popover opened={menuOpen} onChange={setMenuOpen} position="bottom-end" withinPortal>
      <Popover.Target>
        <ActionIcon onClick={toggleMenu}>
          <Avatar color="lime.1" size={36} radius={10}>
            <Text c="lime.9" tt="uppercase">
              {UsersUtils.getUserInitial(profile)}
            </Text>
          </Avatar>
        </ActionIcon>
      </Popover.Target>

      <Styled.UserMenuDroUserMenuDropdown>
        <Box w={270} py={7}>
          <Styled.UserMenuCloseButton
            size={36}
            mb={35}
            variant="outline"
            color="background.5"
            c="background.9"
            onClick={closeMenu}
          >
            <IconArrowLeft />
          </Styled.UserMenuCloseButton>

          <Group spacing={16}>
            <Avatar color="lime.1" size={36} radius={10}>
              <Text c="lime.9" tt="uppercase">
                {UsersUtils.getUserInitial(profile)}
              </Text>
            </Avatar>

            <Box>
              <Text fz={18} fw="bold" c="dark.6">
                {profile.firstName} {profile.lastName}
              </Text>

              <Group spacing={4}>
                <IconMail size={14} />
                <Text fz={12} c="dark.6">
                  {profile.email}
                </Text>
              </Group>
            </Box>
          </Group>

          <Box mt={20}>
            <Anchor
              component={Link}
              to="/profile"
              c="dark.6"
              fz={14}
              sx={{ textDecoration: 'none' }}
              onClick={closeMenu}
            >
              {t('user-menu.link.profile')}
            </Anchor>

            <Stack>
              <Divider mt={14} color="#ebe4e1" />
              <Anchor
                component={Link}
                to="/settings/alert-notifications"
                c="dark.6"
                fz={14}
                sx={{ textDecoration: 'none' }}
                onClick={closeMenu}
              >
                {t('user-menu.link.alerts')}
              </Anchor>

              {!isAgri && (
                <Anchor
                  component={Link}
                  to="/favourites"
                  c="dark.6"
                  fz={14}
                  sx={{ textDecoration: 'none' }}
                  onClick={closeMenu}
                >
                  {t('user-menu.link.favourites')}
                </Anchor>
              )}
            </Stack>

            {!isDS ? (
              <Box mt={20}>
                <AccountLabel
                  account={{ id: profile.accountId, name: profile.accountName, type: profile.accountType }}
                />

                {canAccessMyAccount ? (
                  <>
                    <Box mt={15}>
                      <Anchor
                        component={Link}
                        to="/account/profile"
                        c="dark.6"
                        fz={14}
                        sx={{ textDecoration: 'none' }}
                        onClick={closeMenu}
                      >
                        {t('user-menu.link.account-profile')}
                      </Anchor>

                      <Divider mt={15} />
                    </Box>

                    <Box mt={15}>
                      <Anchor
                        component={Link}
                        to="/subscriptions-mine"
                        c="dark.6"
                        fz={14}
                        sx={{ textDecoration: 'none' }}
                        onClick={closeMenu}
                      >
                        {t('user-menu.link.subscriptions')}
                      </Anchor>

                      <Divider mt={15} />
                    </Box>
                  </>
                ) : null}

                {isAgri && profile.hasRentContracts ? (
                  <Box mt={15}>
                    <Anchor
                      component={Link}
                      to="/contracts-mine"
                      c="dark.6"
                      fz={14}
                      sx={{ textDecoration: 'none' }}
                      onClick={closeMenu}
                    >
                      {t('user-menu.link.contracts')}
                    </Anchor>

                    <Divider mt={15} />
                  </Box>
                ) : null}

                <Box mt={15}>
                  <Anchor
                    component="button"
                    c="dark.6"
                    fz={14}
                    sx={{ textDecoration: 'none' }}
                    onClick={handleDownload}
                  >
                    {t('user-menu.link.cgu')}
                  </Anchor>
                </Box>
              </Box>
            ) : null}
          </Box>

          <Button component={Link} mt={20} size="sm" color="lime.0" c="lime.9" to="/logout">
            {t('user-menu.action.logout')}
          </Button>
        </Box>
      </Styled.UserMenuDroUserMenuDropdown>
    </Popover>
  );
};

export default UserMenu;
