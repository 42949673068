import React, { FC } from 'react';
import AuthLayout from '@layout/auth/AuthLayout';
import LoginForm from '@modules/auth/components/LoginForm';
import { Auth } from '@modules/auth/model';
import { AuthService } from '@modules/auth/service';
import Seo from '@shared/modules/seo/Seo';
import { defineRoute, redirect } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { QueryUtils } from '@shared/utils/queries';
import { useTranslation } from 'react-i18next';
import { Alert, Text } from '@mantine/core';
import { MatomoService } from '@shared/modules/analytics';
import { Effect, Function, pipe } from 'effect';
import { defineLoader, useLoader } from '@core/router/loader';

const loader = defineLoader({
  handler: ({ request }) =>
    Effect.gen(function* (_) {
      const queries = yield* _(QueryUtils.parseFromUrl(request.url));
      const reason = yield* _(QueryUtils.getEnumQuery(queries, Auth.Reason, 'reason'));

      return { reason };
    }),
});

const actions = {
  authenticate: defineAction({
    type: 'authenticate',
    payload: Auth.AuthenticateParams,
    handler: ({ request, payload }) => {
      const redirectEffect = pipe(
        QueryUtils.parseFromUrl(request.url),
        Effect.flatMap(queries => QueryUtils.getStringQuery(queries, 'referrer')),
        Effect.flatMap(url => redirect(url ?? '/')),
      );

      return pipe(
        AuthService.authenticate(payload),
        Effect.tap(() => MatomoService.trackLogin()),
        Effect.tap(() => redirectEffect),
      );
    },
    flashOptions: {
      error: Function.constFalse,
    },
  }),
};

const LoginPage: FC = () => {
  const { reason } = useLoader<typeof loader>();

  const { t } = useTranslation('login');

  const [loading, authenticate, error] = useAction(actions.authenticate);

  const handleAuthenticate = (params: Auth.AuthenticateParams) => Effect.runPromise(authenticate(params));

  return (
    <AuthLayout>
      <Seo title={t('title')} />

      {reason === Auth.Reason.Expired ? (
        <Alert color="blue" mb={20}>
          <Text color="blue" size="sm" weight={600}>
            {t('errors.expired')}
          </Text>
        </Alert>
      ) : null}

      <LoginForm loading={loading} error={error} onSubmit={handleAuthenticate} />
    </AuthLayout>
  );
};

const loginRoute = defineRoute({
  ns: 'login',
  element: <LoginPage />,
  loader,
  actions,
});

export default loginRoute;
