import styled from '@emotion/styled';
import { Header } from '@mantine/core';
import { LayoutConstants } from '@layout/constants';

export const MobileHeaderContainer = styled(Header)`
  display: none;

  ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    display: flex;
    align-items: center;
    padding: 4px 20px;
    gap: 15px;
  }
`;
