import React, { ReactNode } from 'react';

import { HttpError, HttpRemoteData } from '@core/http';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { Boolean, Function, Option } from 'effect';
import { RemoteData } from '@core/fp';

export function renderOptional<T, R extends ReactNode>(
  value: Option.Option<T>,
  onSome: (data: T) => R,
  onNone: () => R | null = Function.constNull,
): R | null {
  return Option.match(value, { onNone, onSome });
}

export function renderNullable<T, R extends ReactNode>(
  value: T | null | undefined,
  onSome: (data: T) => R,
  onNone: () => R | null = Function.constNull,
): R | null {
  return renderOptional(Option.fromNullable(value), onSome, onNone);
}

export function renderConditional<R extends ReactNode>(
  value: boolean,
  onTrue: () => R,
  onFalse: () => R | null = Function.constNull,
): R | null {
  return Boolean.match(value, { onTrue, onFalse });
}

export function renderHttpRemoteData<E, A, R extends ReactNode>(
  value: HttpRemoteData<A, E>,
  onSuccess: (data: A) => R,
  onPending: () => R | JSX.Element = () => <DebouncedLineLoader />,
  onFailure: (error: HttpError<E>) => R | JSX.Element = error => <ErrorPage error={error} />,
): R | JSX.Element {
  return RemoteData.match(value, { onSuccess, onPending, onFailure });
}
