import z from 'zod';
import { StringifiableRecord } from 'query-string';

export enum DateFormat {
  LocalDate = 'dd/MM/yyyy',
  LocalDateTime = 'dd/MM/yyyy HH:mm',
  LocalTime = 'HH:mm',
  MonthDay = 'MM-dd',
  YearMonth = 'MM/yyyy',
}

export enum LocalizedDayJsFormat {
  LocalDate = 'L',
  LocalDateTime = 'LLL',
  LocalTime = 'LT',
}

export enum LocalizedDateFnsFormat {
  LocalDate = 'P',
  LocalDateTime = 'Pp',
  LocalTime = 'p',
}

export const LocalDate = z.string().brand<'LocalDate'>();
export type LocalDate = z.infer<typeof LocalDate>;

export const LocalDateTime = z.string().brand<'LocalDateTime'>();
export type LocalDateTime = z.infer<typeof LocalDateTime>;

export const LocalTime = z.string().brand<'LocalTime'>();
export type LocalTime = z.infer<typeof LocalTime>;

export const Instant = z.string().datetime().brand('Instant');
export type Instant = z.infer<typeof Instant>;

export const MonthDay = z.string().brand<'MonthDay'>();
export type MonthDay = z.infer<typeof MonthDay>;

export const YearMonth = z.string().brand<'YearMonth'>();
export type YearMonth = z.infer<typeof YearMonth>;

export type DateTypes<Format extends DateFormat> = {
  [DateFormat.LocalDate]: LocalDate;
  [DateFormat.LocalDateTime]: LocalDateTime;
  [DateFormat.LocalTime]: LocalTime;
  [DateFormat.MonthDay]: MonthDay;
  [DateFormat.YearMonth]: YearMonth;
}[Format];

export interface DateRangeFilter extends StringifiableRecord {
  from: LocalDate;
  to: LocalDate;
}
