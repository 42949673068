import { Drawer } from '@mantine/core';
import React, { FC } from 'react';
import NotificationsList, { NOTIFICATIONS_SCROLL_CLASSNAME } from '@modules/notifications/components/NotificationsList';

export const NOTIFICATIONS_DRAWER_HASH = '#notifications';

interface NotificationsDrawerProps {
  opened: boolean;
  onClose: () => void;
}

const NotificationsDrawer: FC<NotificationsDrawerProps> = ({ opened, onClose }) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      withCloseButton={false}
      classNames={{ content: NOTIFICATIONS_SCROLL_CLASSNAME }}
      styles={{ body: { padding: 0 } }}
      size={630}
    >
      <NotificationsList onClose={onClose} />
    </Drawer>
  );
};

export default NotificationsDrawer;
