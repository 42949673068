import config from '@root/config';
import { HttpError } from '@core/http/model';

export function getApiBaseURL(prefix: string): string {
  if (import.meta.env.DEV) {
    return `/api${prefix}`;
  } else {
    return new URL(prefix, config.VITE_API_URL).toString();
  }
}

export function isHttpError(error: unknown): error is HttpError {
  return (error as any)._tag === 'HttpError';
}
