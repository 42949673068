import React, { FC, PropsWithChildren } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Group,
  Paper,
  rem,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { Profile } from '@modules/profile/model';
import { useTranslation } from 'react-i18next';
import AddressForm from '@shared/modules/address/components/AddressForm';
import PhoneNumberInput from '@shared/modules/phone-number/components/PhoneNumberInput';
import { Controller } from 'react-hook-form';
import { getDropdownOptionsFromTranslatedEnumLabels } from '@shared/utils/enum';
import { Translations } from '@core/translations';
import { useFetchTaskArray } from '@core/http/hooks';
import { ReferentialsService } from '@shared/modules/referentials/service';
import { useProfilePredicate, useSavAuthenticateUrl } from '@modules/profile/hooks';
import { ProfilePredicates } from '@modules/profile/predicates';
import { Role } from '@modules/roles/model';
import { UsersUtils } from '@modules/users/utils';
import { useProfile } from '@modules/profile/loader';
import { CardProps } from '@mantine/core/lib/Card/Card';
import { PolymorphicComponentProps } from '@mantine/utils';
import { Link } from 'react-router-dom';

type DeleteProfileCardProps<C> = PropsWithChildren<
  PolymorphicComponentProps<C, CardProps> & Omit<Pick<any, string | number | symbol>, 'component' | keyof CardProps>
>;

function DeleteProfileCard<C = 'div'>({ children, ...rest }: DeleteProfileCardProps<C>) {
  const profile = useProfile();
  const { t } = useTranslation('profile');

  return (
    <Card
      display="inline-block"
      bg="background.2"
      px={20}
      py={15}
      radius={4}
      sx={theme => ({ borderColor: theme.colors.background[4] })}
      {...rest}
    >
      <Group noWrap>
        <Avatar color="lime.1" size={36} radius={10}>
          <Text c="lime.9" tt="uppercase" size={14}>
            {UsersUtils.getUserInitial(profile)}
          </Text>
        </Avatar>

        <Box>
          <Text size={14} fw={700} c="dark.6">
            {t('delete.title')}
          </Text>

          <Text size={14} c="dark.6">
            {children}
          </Text>
        </Box>
      </Group>
    </Card>
  );
}

const UpdateProfileForm: FC = () => {
  const profile = useProfile();
  const savUrl = useSavAuthenticateUrl('/contact');

  const { t } = useTranslation('profile');

  const {
    register,
    formState: { errors },
    control,
    watch,
    handleSubmit,
  } = useEnhancedFormContext<Profile.UpdateProfileParams>();

  const [zoneIds] = useFetchTaskArray(ReferentialsService.getZoneIds);

  const isDs = useProfilePredicate(ProfilePredicates.hasAccountType(Role.AccountType.DeSangosse));
  const canEditMyUsers = useProfilePredicate(ProfilePredicates.canEdit(Role.AccessKey.MyUsers));

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Paper m={20} p={20} withBorder={false}>
        <Title order={3}>{t('form.identity.title')}</Title>

        <SimpleGrid
          cols={2}
          spacing={15}
          sx={{ maxWidth: '840px', padding: '20px 0' }}
          breakpoints={[{ maxWidth: rem(500), cols: 1 }]}
        >
          <TextInput
            id="lastName"
            label={t('form.identity.lastName.label')}
            placeholder={t('form.identity.lastName.label')}
            error={!!errors.lastName}
            required
            {...register('lastName')}
          />

          <TextInput
            id="firstName"
            label={t('form.identity.firstName.label')}
            placeholder={t('form.identity.firstName.label')}
            error={!!errors.firstName}
            required
            {...register('firstName')}
          />

          <TextInput
            id="email"
            value={profile.email}
            label={t('form.identity.email.label')}
            placeholder={t('form.identity.email.label')}
            required
            disabled
          />
        </SimpleGrid>

        <Divider my={20} />

        <Title order={3}>{t('form.postalAddress.title')}</Title>

        <AddressForm name="postalAddress" register={register} control={control} />

        <Box sx={{ maxWidth: 300 }}>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <PhoneNumberInput
                label={t('form.phoneNumber.label')}
                placeholder={t('form.phoneNumber.label')}
                error={!!errors.phoneNumber}
                defaultCountry={watch('postalAddress.country')}
                required
                {...field}
              />
            )}
          />
        </Box>

        <Divider my={20} />

        <Title order={3}>{t('form.language.title')}</Title>

        <Grid gutter={15} pt={20} sx={{ maxWidth: '510px' }} columns={13}>
          <Grid.Col xs={12} sm={8}>
            <Controller
              control={control}
              name="language"
              render={({ field }) => (
                <Select
                  label={t('form.language.interface.label')}
                  placeholder={t('form.select.placeholder', { ns: 'common' })}
                  error={!!errors.language}
                  data={getDropdownOptionsFromTranslatedEnumLabels(Translations.languageLabels, t)}
                  {...field}
                />
              )}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={5}>
            <Controller
              control={control}
              name="zoneId"
              render={({ field }) => (
                <Select
                  label={t('form.language.hour.label')}
                  placeholder={t('form.select.placeholder', { ns: 'common' })}
                  data={zoneIds}
                  error={!!errors.zoneId}
                  searchable
                  {...field}
                />
              )}
            />
          </Grid.Col>
        </Grid>

        {!isDs ? (
          <>
            <Divider my={25} />

            {canEditMyUsers ? (
              <DeleteProfileCard component={Link} to={savUrl} target="_blank" rel="noopener noreferrer">
                {t('delete.contact-sav')}
              </DeleteProfileCard>
            ) : (
              <DeleteProfileCard>{t('delete.contact-admin')}</DeleteProfileCard>
            )}
          </>
        ) : null}
      </Paper>
    </form>
  );
};

export default UpdateProfileForm;
