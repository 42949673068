import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { TranslationsUtils } from '@core/translations';
import { MatomoService } from '@shared/modules/analytics';
import { Effect } from 'effect';

const LanguageSync: FC = () => {
  const { i18n } = useTranslation();

  const language = TranslationsUtils.parseLanguage(i18n.language);

  useEffect(() => {
    Effect.runSync(MatomoService.setLanguageDimension(language));
  }, [language]);

  return (
    <Helmet>
      <html lang={language} />
    </Helmet>
  );
};

export default LanguageSync;
