import { z } from 'zod';
import { emailSchema, nonEmptyStringSchema } from '@shared/schemas';

export namespace Auth {
  export const Token = z.string().brand('AuthToken');
  export type Token = z.infer<typeof Token>;

  export const AuthenticateParams = z.object({
    email: emailSchema,
    password: nonEmptyStringSchema,
  });

  export type AuthenticateParams = z.infer<typeof AuthenticateParams>;

  export enum Reason {
    Expired = 'expired',
  }
}
