import React from 'react';
import { Route } from 'react-router-dom';

import { createLazyRoute } from '@core/router';

const module = () => import('./module');

export const settingsRoutes = (
  <Route path="settings">
    <Route {...createLazyRoute(module, m => m.settingsLayoutRoute)}>
      <Route index {...createLazyRoute(module, m => m.settingsIndexRoute)} />

      <Route path="global" {...createLazyRoute(module, m => m.globalSettingsRoute, 'global-settings')}>
        <Route path="update" {...createLazyRoute(module, m => m.globalSettingsUpdateRoute)} />
      </Route>

      <Route path="issues" {...createLazyRoute(module, m => m.issuesLevelSettingsRoute)} />
      <Route path="alerts" {...createLazyRoute(module, m => m.alertsTargetsSettingsRoute)} />

      <Route path="internationalization" {...createLazyRoute(module, m => m.internationalizationSettingsRoute)} />

      <Route path="syncs" {...createLazyRoute(module, m => m.syncErrorsRoute)} />
      <Route path="tos" {...createLazyRoute(module, m => m.tosListRoute)} />
      <Route path="firmware" {...createLazyRoute(module, m => m.firmwareUpdateRoute)} />
    </Route>

    <Route
      path="internationalization/:country"
      {...createLazyRoute(module, m => m.internationalizationSettingsDetailRoute)}
    />
    <Route path="tos/new" {...createLazyRoute(module, m => m.createTosRoute)} />
    <Route path="alert-notifications" {...createLazyRoute(module, m => m.alertNotificationRoute)} />
  </Route>
);

export default settingsRoutes;
