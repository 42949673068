import React, { createElement, FC } from 'react';
import { Account } from '@modules/account/model';
import { useProfile } from '@modules/profile/loader';
import { Anchor, Group, Text, TextProps, ThemeIcon } from '@mantine/core';
import { Role } from '@modules/roles/model';
import { AccountUtils } from '@modules/account/utils';
import { renderNullable } from '@shared/utils/render';
import { Link } from 'react-router-dom';

interface AccountLabelProps extends TextProps {
  account: Account.Light;
  hideLink?: boolean;
}

const AccountLabel: FC<AccountLabelProps> = ({ account, hideLink, ...props }) => {
  const profile = useProfile();

  const link = hideLink || account.type === Role.AccountType.Anonymous ? null : AccountUtils.getLink(account, profile);

  const label = (
    <Text size={12} fw={600} c="dark.6" {...props}>
      {account.name}
    </Text>
  );

  return (
    <Group spacing={10} noWrap>
      <ThemeIcon size={30} c="background.9" bg="background.2" radius={10}>
        {createElement(Role.roleAccountTypeIcons[account.type], { width: 18, height: 18 })}
      </ThemeIcon>

      {renderNullable(
        link,
        link => (
          <Anchor
            component={Link}
            to={link}
            underline={false}
            color="dark.6"
            sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
          >
            {label}
          </Anchor>
        ),
        () => label,
      )}
    </Group>
  );
};

export default AccountLabel;
