import i18next, { BackendModule, ReadCallback } from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { Translations } from '@core/translations/model';
import { Resources } from '@core/translations/resources';
import { TranslationsService } from '@core/translations/service';
import { SentryUtils } from '@shared/modules/sentry/utils';
import { Effect, pipe } from 'effect';

const TranslationsLoader: BackendModule = {
  type: 'backend',
  init() {},
  read(language: Translations.Language, namespace: keyof Resources, callback: ReadCallback) {
    pipe(
      TranslationsService.loadTranslations(language, namespace),
      Effect.tapError(SentryUtils.logHttpError),
      Effect.matchEffect({
        onSuccess: translations => Effect.sync(() => callback(null, translations)),
        onFailure: () => Effect.sync(() => callback(new Error('Failed to load translations'), null)),
      }),
      Effect.runPromise,
    );
  },
};

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(TranslationsLoader)
  .use(initReactI18next)
  .init({
    supportedLngs: Object.values(Translations.Language),
    fallbackLng: Translations.Language.English,
    debug: false,
    defaultNS: 'common',
    ns: ['common'],
    detection: {
      order: ['localStorage', 'navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key) => {
      const message = `[i18n] missing key ${key} on ns ${ns}`;

      Effect.runSync(
        SentryUtils.logMessage(message, 'warning', {
          lngs,
          ns,
          key,
        }),
      );

      console.warn(message);
    },
  });

export default i18next;

export * from './model';
export * from './utils';
