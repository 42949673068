import React, { forwardRef, useMemo } from 'react';
import PhoneInput, { Country } from 'react-phone-number-input';
import { Input, TextInput } from '@mantine/core';

import { Countries } from '@shared/model';
import { InputWrapperProps } from '@mantine/core/lib/Input/InputWrapper/InputWrapper';
import { useTranslation } from 'react-i18next';
import { Translations } from '@core/translations';

import frTranslations from 'react-phone-number-input/locale/fr.json';
import deTranslations from 'react-phone-number-input/locale/de.json';
import esTranslations from 'react-phone-number-input/locale/es.json';
import enTranslations from 'react-phone-number-input/locale/en.json';
import { useIsFormDisabled } from '@shared/modules/form/components/DisableableForm';

import 'react-phone-number-input/style.css';

function countryToPhoneNumberCountry(country: Countries): Country {
  if (country === Countries.UK) {
    return 'GB';
  }

  return country;
}

interface PhoneNumberInputProps extends Omit<InputWrapperProps, 'value' | 'onChange' | 'children'> {
  value?: string | null;
  disabled?: boolean;
  defaultCountry?: Countries;
  onChange: (value: string | undefined) => void;
  autoComplete?: string;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  ({ value, onChange, defaultCountry, placeholder, error, disabled, autoComplete, ...rest }, ref) => {
    const isFormDisabled = useIsFormDisabled();

    const { i18n } = useTranslation();

    const labels = useMemo(() => {
      switch (i18n.language) {
        case Translations.Language.French:
          return frTranslations;
        case Translations.Language.German:
          return deTranslations;
        case Translations.Language.Spanish:
          return esTranslations;
        default:
          return enTranslations;
      }
    }, [i18n.language]);

    return (
      <Input.Wrapper {...rest} error={error}>
        <PhoneInput
          id={rest.id}
          autoComplete={autoComplete}
          placeholder={placeholder}
          labels={labels}
          value={value ?? ''}
          onChange={onChange}
          inputComponent={TextInput}
          disabled={disabled ?? isFormDisabled}
          defaultCountry={defaultCountry ? countryToPhoneNumberCountry(defaultCountry) : undefined}
          countryOptionsOrder={Object.values(Countries).map(countryToPhoneNumberCountry)}
          error={error}
          ml={5}
        />
      </Input.Wrapper>
    );
  },
);

export default PhoneNumberInput;
