import React, { FC } from 'react';
import { Global } from '@mantine/core';

const GlobalStyles: FC = () => {
  return (
    <Global
      styles={theme => ({
        'html, body': {
          background: theme.colors.background[2],
        },
        //https://github.com/recharts/recharts/issues/3170#issuecomment-1419027846
        '.recharts-layer': {
          '&:focus, *:focus': {
            outline: 'none !important',
            outlineColor: 'none',
            outlineStyle: 'none',
            outlineWidth: 'none',
          },
        },
      })}
    />
  );
};

export { GlobalStyles };
