import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  ScrollRestoration,
  useLocation,
  useNavigation,
} from 'react-router-dom';

import { createRoute, defineRoute } from '@core/router';
import Layout from '@layout/Layout';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';

import { Role } from '@modules/roles/model';

import providersRoutes from '@modules/providers/routes';
import farmersRoutes from '@modules/farmers/routes';
import usersRoutes from '@modules/users/routes';
import rolesRoutes from '@modules/roles/routes';
import logoutRoute from '@modules/auth/logout';
import passwordsRoutes from '@modules/passwords/routes';
import authRoutes from '@modules/auth/routes';
import registerRoutes from '@modules/register/routes';
import profileLoaderRoute, { useProfile } from '@modules/profile/loader';
import profileRoutes, { savRedirectRoutes } from '@modules/profile/routes';
import sensorsRoutes from '@modules/sensors/routes';
import parcelsRoutes, { parcelsFullPageRoutes } from '@modules/parcels/routes';
import parcelsSharingRoutes from '@modules/parcels-sharing/routes';
import accountRoutes from '@modules/account/routes';
import subscriptionsRoutes from '@modules/subscriptions/routes';
import dashboardRoutes from '@modules/dashboard/routes';
import settingsRoutes from '@modules/settings/routes';
import profileInteractionsRoute from '@modules/profile/ProfileInteractionsPage';
import acceptTosRoutes from '@modules/accept-tos/routes';
import favouritesRoutes, { favouritesFullPageRoutes } from '@modules/favourites/routes';
import onBoardingRoutes from '@modules/onboarding/routes';
import issuesRoutes from '@modules/issues/routes';
import { MatomoService } from '@shared/modules/analytics';
import supportRoutes from '@modules/support/routes';
import { Effect, Function, pipe } from 'effect';
import { FavouritesService } from '@modules/favourites/service';
import { defineLoader } from '@core/router/loader';

const Root: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  const location = useLocation();

  const pathToTrack = location.pathname + location.search;

  useEffect(() => {
    setTimeout(() => {
      Effect.runSync(MatomoService.trackPageView());
    }, 100);
  }, [pathToTrack]);

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

const IndexPage: FC = () => {
  const profile = useProfile();

  const startPage = Role.startPageInformations[profile.role.startPage]?.route ?? '/parcels';

  return <Navigate to={startPage} replace />;
};

const cacheClearRoute = defineRoute({
  ns: [],
  loader: defineLoader({
    handler: () => pipe(FavouritesService.clearAll(), Effect.map(Function.constNull)),
  }),
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route {...createRoute(cacheClearRoute)}>
        {authRoutes}
        {passwordsRoutes}
        {registerRoutes}
      </Route>

      <Route {...createRoute(profileLoaderRoute)}>
        <Route {...createRoute(profileInteractionsRoute)}>
          <Route element={<Layout />}>
            <Route index element={<IndexPage />} />

            {dashboardRoutes}

            {/* ---------- Exploitation ---------- */}
            {parcelsRoutes}
            {parcelsSharingRoutes}
            {sensorsRoutes}
            {issuesRoutes}

            {/* ---------- Gestion des clients ---------- */}
            {providersRoutes}
            {farmersRoutes}
            {usersRoutes}
            {subscriptionsRoutes}

            {/* ---------- Administration ---------- */}
            {rolesRoutes}
            {settingsRoutes}

            {/* ---------- Menu user ---------- */}
            {profileRoutes}
            {accountRoutes}
            {favouritesRoutes}

            {supportRoutes}

            <Route path="/logout" {...createRoute(logoutRoute)} />

            <Route path="*" element={<ErrorPage />} />
          </Route>

          {/* ---------- Full page ---------- */}
          {parcelsFullPageRoutes}
          {favouritesFullPageRoutes}
          {onBoardingRoutes}
        </Route>

        {acceptTosRoutes}
        {savRedirectRoutes}

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>,
  ),
);

export default router;
