import React, { createElement, FC, forwardRef, FunctionComponent, SVGProps, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as Styled from './Nav.styles';
import {
  ActionIcon,
  Group,
  Indicator,
  Navbar,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import {
  IconAdjustmentsHorizontal,
  IconAlertTriangle,
  IconBuildingStore,
  IconFileDollar,
  IconLayoutGrid,
  IconLeaf,
  IconQuestionMark,
  IconSend,
  IconTractor,
  IconUserCheck,
  IconUsers,
  IconWorld,
} from '@tabler/icons-react';
import { renderNullable } from '@shared/utils/render';

import logo from '@assets/logos/logo.svg';
import logoDS from '@assets/logos/desangosse.svg';

import { useTranslation } from 'react-i18next';
import SupportDrawer, { SUPPORT_DRAWER_HASH } from '@modules/support/components/drawer/SupportDrawer';
import { Profile } from '@modules/profile/model';
import { ProfilePredicates } from '@modules/profile/predicates';
import { Role } from '@modules/roles/model';
import { IconSensor } from '@styles/icons';
import { useProfile } from '@modules/profile/loader';
import { TFunction } from 'i18next';
import { DashBoardUtils } from '@modules/dashboard/utils';
import { useHashDisclosure } from '@core/router';
import { Function, pipe, Predicate, ReadonlyArray } from 'effect';
import { ParcelsSharingUtils } from '@modules/parcels-sharing/utils';

interface NavEntry {
  title: string;
  to: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  end?: boolean;
  desktopOnly?: boolean;
  indicator?: () => boolean;
  restriction: Predicate.Predicate<Profile>;
}

interface NavGroup {
  title?: string;
  entries: Array<NavEntry>;
}

const entries = (t: TFunction): Array<NavGroup> => [
  {
    entries: [
      {
        title: t('nav.dashboard'),
        to: '/dashboard',
        icon: IconLayoutGrid,
        restriction: DashBoardUtils.canAccess,
      },
    ],
  },
  {
    title: t('nav.farm'),
    entries: [
      {
        title: t('nav.parcels'),
        to: '/parcels',
        icon: IconLeaf,
        restriction: ProfilePredicates.canAccess(Role.AccessKey.Parcels),
      },
      {
        title: t('nav.sensors'),
        to: '/sensors',
        icon: IconSensor,
        restriction: ProfilePredicates.canAccess(Role.AccessKey.Sensors),
      },
      {
        title: t('nav.issues'),
        to: '/issues',
        icon: IconAlertTriangle,
        restriction: ProfilePredicates.and(
          ProfilePredicates.canAccess(Role.AccessKey.Defaults),
          Predicate.not(ProfilePredicates.hasAccountType(Role.AccountType.Farmer)),
        ),
      },
      {
        title: t('nav.parcels-sharing'),
        to: '/parcels-sharing',
        icon: IconLeaf,
        restriction: ParcelsSharingUtils.canAccess,
      },
    ],
  },
  {
    title: t('nav.customers-management'),
    entries: [
      {
        title: t('nav.providers-accounts'),
        to: '/providers',
        icon: IconBuildingStore,
        restriction: ProfilePredicates.canAccess(Role.AccessKey.ProviderAccounts),
      },
      {
        title: t('nav.farmers-accounts'),
        to: '/farmers',
        icon: IconTractor,
        restriction: ProfilePredicates.canAccess(Role.AccessKey.FarmerAccounts),
      },
      {
        title: t('nav.customers-users'),
        to: '/users',
        icon: IconUsers,
        restriction: ProfilePredicates.canAccess(Role.AccessKey.Users),
      },
      {
        title: t('nav.subscriptions'),
        to: '/subscriptions',
        icon: IconFileDollar,
        restriction: ProfilePredicates.canAccess(Role.AccessKey.Subscriptions),
      },
    ],
  },
  {
    title: t('nav.admin'),
    entries: [
      {
        title: t('nav.my-users'),
        to: '/my-users',
        icon: IconUsers,
        restriction: ProfilePredicates.canAccess(Role.AccessKey.MyUsers),
      },
      {
        title: t('nav.roles'),
        to: '/roles',
        icon: IconUserCheck,
        restriction: ProfilePredicates.canAccess(Role.AccessKey.Roles),
      },
      {
        title: t('nav.settings'),
        to: '/settings',
        icon: IconAdjustmentsHorizontal,
        restriction: ProfilePredicates.and(
          ProfilePredicates.hasAccountType(Role.AccountType.DeSangosse),
          ProfilePredicates.canAccess(Role.AccessKey.Configuration),
        ),
        desktopOnly: true,
      },
      {
        title: t('nav.settings.alerts'),
        to: '/settings/alerts',
        icon: IconSend,
        restriction: ProfilePredicates.and(
          ProfilePredicates.hasAccountType(Role.AccountType.Provider, Role.AccountType.Farmer),
          ProfilePredicates.canAccess(Role.AccessKey.Configuration),
        ),
        desktopOnly: true,
      },
    ],
  },
];

interface NavEntryProps {
  entry: NavEntry;
  navOpen: boolean;
}

const NavEntryComponent: FC<NavEntryProps> = ({ entry, navOpen }) => {
  const hasIndicator = (entry.indicator ?? Function.constFalse)();

  return (
    <Styled.NavEntry to={entry.to} end={entry.end} $compact={!navOpen}>
      <Tooltip label={entry.title} withinPortal disabled={navOpen} position="right" fz="xs" zIndex={1000}>
        <Indicator size={9} color="gray.8" disabled={!hasIndicator}>
          <ThemeIcon size={30} variant="light" radius={8}>
            {createElement(entry.icon, { width: 16, height: 16 })}
          </ThemeIcon>
        </Indicator>
      </Tooltip>

      <Text component="span" fz="sm" fw={600} c="background.9">
        {entry.title}
      </Text>
    </Styled.NavEntry>
  );
};

interface NavProps {
  open: boolean;
  isMobileLayout: boolean;
}

const Nav = forwardRef<HTMLElement, NavProps>(({ open, isMobileLayout }, ref) => {
  const { t } = useTranslation();

  const [supportOpen, supportTriggers] = useHashDisclosure(SUPPORT_DRAWER_HASH);

  const profile = useProfile();

  const navEntries = useMemo(
    () =>
      pipe(
        entries(t),
        ReadonlyArray.map(group => ({
          ...group,
          entries: pipe(
            group.entries,
            ReadonlyArray.filter(entry => {
              const hasAccess = entry.restriction(profile);
              const showFromResolution = entry.desktopOnly ? !isMobileLayout : true;

              return hasAccess && showFromResolution;
            }),
          ),
        })),
        ReadonlyArray.filter(group => ReadonlyArray.isNonEmptyArray(group.entries)),
      ),
    [isMobileLayout, profile, t],
  );

  return (
    <Styled.Nav ref={ref} $open={open}>
      <Styled.NavHeader $open={open}>
        <Link to="/">
          <img src={logo} width={60} height={60} alt="Limacapt" />
        </Link>

        <img src={logoDS} width={100} height={40} alt="De Sangosse" />
      </Styled.NavHeader>

      <Navbar.Section grow component={ScrollArea}>
        <Styled.NavContent $open={open}>
          {navEntries.map((group, i) => (
            <Styled.NavGroup key={i}>
              {renderNullable(group.title, title => (
                <Styled.NavGroupTitle size={12} c="background.9" tt="uppercase" fw={700} $hidden={!open}>
                  {title}
                </Styled.NavGroupTitle>
              ))}

              {group.entries.map((entry, i) => (
                <NavEntryComponent key={i} entry={entry} navOpen={open} />
              ))}
            </Styled.NavGroup>
          ))}
        </Styled.NavContent>
      </Navbar.Section>

      <Styled.NavFooter $open={open}>
        <Stack spacing={5}>
          <Tooltip label={t('nav.support.link.anti-slugs')} zIndex={1000} position="right" fz="xs">
            <ActionIcon
              size={36}
              component="a"
              href="https://anti-limaces.desangosse.fr/produits"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconWorld color="black" size={22} strokeWidth={1.5} />
            </ActionIcon>
          </Tooltip>

          <UnstyledButton onClick={supportTriggers.toggle}>
            <Group spacing={8} noWrap>
              <ThemeIcon size={36} color="lime.0" radius="xl">
                <IconQuestionMark color="black" size={22} />
              </ThemeIcon>

              {open ? <Text size="sm">{t('nav.support')}</Text> : null}
            </Group>
          </UnstyledButton>
        </Stack>

        <SupportDrawer open={supportOpen} onClose={supportTriggers.close} />
      </Styled.NavFooter>
    </Styled.Nav>
  );
});

export default Nav;
