import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import loginRoute from '@modules/auth/LoginPage';
import tokenLoginRoute from '@modules/auth/TokenLoginPage';

export const authRoutes = (
  <Route path="/login">
    <Route index {...createRoute(loginRoute)} />
    <Route path="token/:token" {...createRoute(tokenLoginRoute)} />
  </Route>
);

export default authRoutes;
