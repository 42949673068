import { To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Notification } from '@modules/notifications/model';
import { DateFormat, formatDate, LocalDate, LocalizedDateFnsFormat, parseDate } from '@shared/modules/dates';
import { Parcel } from '@modules/parcels/model';
import { Issue } from '@modules/issues/model';

export interface NotificationDisplay {
  type: string;
  text: string;
  to: To;
}

function formatToLocalizedDate(date: LocalDate): string | null {
  return formatDate(parseDate(date, DateFormat.LocalDate, null), LocalizedDateFnsFormat.LocalDate);
}

export function useNotificationDisplay(notification: Notification): NotificationDisplay {
  const { t } = useTranslation('common', { keyPrefix: 'notifications' });

  const [parcelsT] = useTranslation('parcels');
  const [issuesT] = useTranslation('issues');

  const textArg = `${notification.type}.text` as const;

  switch (notification.type) {
    case Notification.Type.SensorAffected: {
      const { sensorId, sensorSerialNumber } = notification.payload;

      return {
        type: t('type.sensor'),
        text: t(textArg, { sensorSerialNumber }),
        to: `/sensors/${sensorId}`,
      };
    }
    case Notification.Type.IssueOpened: {
      const { issueId, issueLevel, issueType, sensorSerialNumber, parcelLabel } = notification.payload;

      return {
        type: t(`type.issue.${issueLevel}`),
        text: t(textArg, { issueType: Issue.typeLabel[issueType]?.(issuesT), sensorSerialNumber, parcelLabel }),
        to: `/issues/${issueId}`,
      };
    }
    case Notification.Type.ValidateEstimatedSeedingDate: {
      const { parcelId, cropId, parcelLabel, estimatedSeedingDate } = notification.payload;

      return {
        type: t('type.crop'),
        text: t(textArg, { parcelLabel, estimatedSeedingDate: formatToLocalizedDate(estimatedSeedingDate) }),
        to: `/parcels/${parcelId}/crops/${cropId}`,
      };
    }
    case Notification.Type.EstimatedSeedingDatePassed: {
      const { parcelId, cropId, parcelLabel, estimatedSeedingDate } = notification.payload;

      return {
        type: t('type.crop'),
        text: t(textArg, { parcelLabel, estimatedSeedingDate: formatToLocalizedDate(estimatedSeedingDate) }),
        to: `/parcels/${parcelId}/crops/${cropId}`,
      };
    }
    case Notification.Type.PhenologicalStageUpdateReminder: {
      const { parcelId, cropId, parcelLabel, stage } = notification.payload;

      return {
        type: t('type.crop'),
        text: t(textArg, { parcelLabel, stage: Parcel.Crop.stageLabels[stage]?.(parcelsT) }),
        to: `/parcels/${parcelId}/crops/${cropId}`,
      };
    }
    case Notification.Type.SubscriptionToRenew: {
      const { sensorSerialNumber } = notification.payload;

      return {
        type: t('type.subscription'),
        text: t(textArg, { sensorSerialNumber }),
        to: '/subscriptions-mine',
      };
    }
    case Notification.Type.SubscriptionExpired: {
      const { sensorSerialNumber } = notification.payload;

      return {
        type: t('type.subscription'),
        text: t(textArg, { sensorSerialNumber }),
        to: '/subscriptions-mine',
      };
    }
    case Notification.Type.Distance: {
      const { sensorSerialNumber, parcelLabel, parcelId } = notification.payload;

      return {
        type: t('type.sensor'),
        text: t(textArg, { sensorSerialNumber, parcelLabel }),
        to: `/parcels/${parcelId}`,
      };
    }
    case Notification.Type.FillInterventionReminder:
      const { parcelId, cropId } = notification.payload;

      return {
        type: t('type.crop'),
        text: t(textArg),
        to: `/parcels/${parcelId}/crops/${cropId}`,
      };
    case Notification.Type.RentContractToValidate:
      return {
        type: t('type.rent-contract'),
        text: t(textArg),
        to: '/contracts-mine',
      };
  }
}
