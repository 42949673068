import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { createPolymorphicComponent, em, Navbar, Text, TextProps } from '@mantine/core';
import { LayoutConstants } from '@layout/constants';
import { SectionProps } from '@mantine/core/lib/AppShell/HorizontalSection/Section/Section';
import { FC } from 'react';
import { css } from '@emotion/react';
import { styledOptions } from '@styles/utils';

interface OpenProps {
  $open: boolean;
}

export const Nav = styled(Navbar, styledOptions)<OpenProps>`
  width: ${props => em(props.$open ? LayoutConstants.NAV_OPEN_WIDTH : LayoutConstants.NAV_CLOSE_WIDTH)};
  transition: width 0.25s ease-in-out;
  overflow: hidden;
  z-index: 1000;

  ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    width: ${em(LayoutConstants.NAV_OPEN_WIDTH)};
    transform: translateX(${props => (props.$open ? '0' : '-100%')});
    transition: transform 0.25s ease-in-out;
  }

  ${props => props.theme.fn.largerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    top: 0;
    height: 100vh;
  }
`;

export const NavHeader = createPolymorphicComponent<'div', SectionProps & OpenProps>(styled(
  Navbar.Section as FC<SectionProps>,
  styledOptions,
)<OpenProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 13px;
  height: 75px;
  width: ${em(LayoutConstants.NAV_OPEN_WIDTH - 1)};
  flex: 0 0 75px;
  border-bottom: 1px solid ${props => props.theme.colors.background[5]};

  ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    display: none;
  }
`);

export const NavContent = styled.div<OpenProps>`
  padding: 10px 16px;
  width: ${props => (props.$open ? em(LayoutConstants.NAV_OPEN_WIDTH - 1) : em(LayoutConstants.NAV_CLOSE_WIDTH - 1))};
  overflow-x: hidden;
  transition: width 0.25s ease-in-out;
`;

export const NavGroup = styled.div``;

export const NavGroupTitle = createPolymorphicComponent<'span', TextProps & { $hidden: boolean }>(styled(
  Text,
  styledOptions,
)<{
  $hidden: boolean;
}>`
  position: relative;
  transition: color 0.15s linear;
  margin: 10px 0 8px;
  opacity: 0.4;
  white-space: nowrap;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 50%;
    left: 12px;
    width: 30px;
    height: 1px;
    background-color: transparent;
    transition: background-color 0.15s linear;
  }

  ${props =>
    props.$hidden
      ? css`
          color: transparent;

          &:after {
            background: ${props.theme.fn.rgba(props.theme.colors.background[9], 0.4)};
          }
        `
      : null}
`);

export const NavEntry = styled(NavLink, styledOptions)<{ $compact: boolean }>`
  display: grid;
  grid-template-columns: 26px 1fr;
  grid-column-gap: 13px;
  padding: 3px 12px;
  align-items: center;
  text-decoration: none;
  margin-top: 5px;
  background-color: transparent;
  border-radius: 4px;
  transition: all 0.25s linear;
  width: ${em(LayoutConstants.NAV_OPEN_WIDTH - 1 - 2 * 16)};

  > span {
    opacity: ${props => (props.$compact ? 0 : 1)};
    transition: all 0.25s linear;
  }

  .mantine-ThemeIcon-root {
    background-color: ${props => props.theme.colors.background[2]};
    transition: background-color 0.25s linear;

    > svg {
      color: ${props => props.theme.colors.background[9]};
      transition: color 0.25s linear;
    }
  }

  &:hover,
  &.active {
    background-color: ${props => props.theme.colors.background[3]};

    .mantine-ThemeIcon-root {
      background-color: ${props => props.theme.colors.lime[0]};

      > svg {
        color: ${props => props.theme.colors.lime[9]};
      }
    }

    > span {
      color: ${props => props.theme.colors.lime[9]};
    }
  }

  ${props =>
    props.$compact &&
    css`
      background-color: transparent !important;
    `}
`;

export const NavFooter = createPolymorphicComponent<'div', SectionProps & OpenProps>(styled(
  Navbar.Section as FC<SectionProps>,
  styledOptions,
)<OpenProps>`
  display: flex;
  width: ${em(LayoutConstants.NAV_OPEN_WIDTH)};
  padding: 13px 25px;
  border-top: 1px solid ${props => props.theme.colors.background[5]};
`);
