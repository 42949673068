import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

export const registerRoutes = (
  <Route path="register">
    <Route index {...createLazyRoute(module, m => m.registerRoute)} />
    <Route path=":activationToken" {...createLazyRoute(module, m => m.activationRoute)} />
    <Route path=":activationToken/farmers" {...createLazyRoute(module, m => m.activationFarmerRoute)} />
    <Route path=":invitationId/sharing-invitation" {...createLazyRoute(module, m => m.invitationRoute)} />
  </Route>
);

export default registerRoutes;
