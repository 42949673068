import queryString, { ParsedQuery, ParseOptions, StringifiableRecord } from 'query-string';
import { camelToSnake, filterEmptyStringToOption, snakeToCamel } from '@shared/utils/string';
import { StringifyOptions } from 'query-string/base';
import { Effect, Option, pipe, ReadonlyArray, ReadonlyRecord } from 'effect';
import z from 'zod';

export namespace QueryUtils {
  export function queriesToSnakeCase(queries: StringifiableRecord): StringifiableRecord {
    return pipe(
      queries,
      ReadonlyRecord.toEntries,
      ReadonlyArray.map(([key, value]) => [camelToSnake(key), value] as const),
      ReadonlyRecord.fromEntries,
    );
  }

  export function queriesToCamelCase(queries: ParsedQuery): ParsedQuery {
    return pipe(
      queries,
      ReadonlyRecord.toEntries,
      ReadonlyArray.map(([key, value]) => [snakeToCamel(key), value] as const),
      ReadonlyRecord.fromEntries,
    );
  }

  const stringifyOptions: StringifyOptions = {
    skipEmptyString: true,
    skipNull: true,
    arrayFormat: 'none',
  };

  export function stringify(queries: StringifiableRecord): string {
    return queryString.stringify(queriesToSnakeCase(queries), stringifyOptions);
  }

  const parseOptions: ParseOptions = {
    arrayFormat: 'none',
  };

  export function parse(queries: string) {
    return Effect.sync(() => queriesToCamelCase(queryString.parse(queries, parseOptions)));
  }

  export function parseFromUrl(url: string) {
    return Effect.sync(() => queriesToCamelCase(queryString.parseUrl(url, parseOptions).query));
  }

  export function parseFromRequest(request: Request) {
    return parseFromUrl(request.url);
  }

  function getQueryValue(query: ParsedQuery, key: string) {
    return Effect.sync(() =>
      pipe(
        Option.fromNullable(query[key]),
        Option.flatMap(value =>
          Array.isArray(value)
            ? ReadonlyArray.head(value).pipe(Option.flatMap(filterEmptyStringToOption))
            : filterEmptyStringToOption(value),
        ),
      ),
    );
  }

  function getArrayQueryValue(query: ParsedQuery, key: string) {
    return Effect.sync(() =>
      pipe(
        Option.fromNullable(query[key]),
        Option.map(value => (Array.isArray(value) ? value : [value])),
        Option.map(ReadonlyArray.filterMap(filterEmptyStringToOption)),
        Option.filter(ReadonlyArray.isNonEmptyReadonlyArray),
      ),
    );
  }

  export function getStringQuery(query: ParsedQuery, key: string) {
    return getQueryValue(query, key).pipe(Effect.map(Option.getOrNull));
  }

  export function getBooleanQuery(query: ParsedQuery, key: string) {
    return pipe(
      getQueryValue(query, key),
      Effect.map(value =>
        pipe(
          value,
          Option.flatMap(value => {
            switch (value) {
              case 'true':
                return Option.some(true);
              case 'false':
                return Option.some(false);
              default:
                return Option.none();
            }
          }),
          Option.getOrNull,
        ),
      ),
    );
  }

  export function getIntQuery(query: ParsedQuery, key: string) {
    return pipe(
      getQueryValue(query, key),
      Effect.map(value =>
        pipe(
          value,
          Option.map(value => parseInt(value, 10)),
          Option.filter(value => !isNaN(value)),
          Option.getOrNull,
        ),
      ),
    );
  }

  export function getEnumQuery<E extends string>(query: ParsedQuery, enumeration: Record<string, E>, key: string) {
    return pipe(
      getQueryValue(query, key),
      Effect.map(value =>
        pipe(
          value,
          Option.filterMap(value =>
            Object.values(enumeration).includes(value as E) ? Option.some(value as E) : Option.none(),
          ),
          Option.getOrNull,
        ),
      ),
    );
  }

  export function getSchemaQuery<Schema extends z.ZodType>(
    query: ParsedQuery,
    schema: Schema,
    key: string,
  ): Effect.Effect<never, never, z.infer<Schema> | null> {
    return pipe(
      getQueryValue(query, key),
      Effect.flatMap(
        Option.match({
          onSome: value => Effect.tryPromise(() => schema.parseAsync(value)),
          onNone: () => Effect.fail(new Error('No value')),
        }),
      ),
      Effect.option,
      Effect.map(Option.getOrNull),
    );
  }

  export function getSchemaArrayQuery<Schema extends z.ZodType>(
    query: ParsedQuery,
    schema: Schema,
    key: string,
  ): Effect.Effect<never, never, ReadonlyArray.NonEmptyArray<z.infer<Schema>> | null> {
    return pipe(
      getArrayQueryValue(query, key),
      Effect.flatMap(
        Option.match({
          onSome: value =>
            pipe(
              value,
              ReadonlyArray.map(value => Effect.tryPromise(() => schema.parseAsync(value))),
              Effect.allSuccesses,
            ),
          onNone: () => Effect.succeed(ReadonlyArray.empty()),
        }),
      ),
      Effect.map(value => (ReadonlyArray.isNonEmptyArray(value) ? value : null)),
    );
  }
}
