import { AxiosResponse } from 'axios';
import { HttpEffect } from '@core/http';
import { pipe, Option, Effect } from 'effect';

const regExpFilename = /filename="(?<filename>.*)"/;

function extractFileName(response: AxiosResponse<Blob>): string | null {
  return pipe(
    Option.fromNullable(response.headers['content-disposition']),
    Option.filterMap(contentDisposition =>
      typeof contentDisposition === 'string' ? Option.some(contentDisposition) : Option.none(),
    ),
    Option.flatMapNullable(contentDisposition => regExpFilename.exec(contentDisposition)?.groups?.filename),
    Option.getOrNull,
  );
}

export function downloadUrl(url: string, fileName?: string | null) {
  return Effect.sync(() => {
    const link = document.createElement('a');

    link.href = url;
    link.download = fileName ?? '';
    link.click();
  });
}

export function downloadFile(response: AxiosResponse<Blob>) {
  const fileName = extractFileName(response);

  const url = window.URL.createObjectURL(response.data);

  return downloadUrl(url, fileName);
}

export function withDownload(task: HttpEffect<AxiosResponse<Blob>>): HttpEffect {
  return pipe(task, Effect.flatMap(downloadFile));
}
