import React, {
  createContext,
  FormEventHandler,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { UseFormReturn, UseFormSetValue } from 'react-hook-form';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { FormPreventLeaveModal } from '@shared/modules/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import DisableableForm from '@shared/modules/form/components/DisableableForm';
import { Effect, Function, pipe } from 'effect';

export interface EnhancedFormContextValue<Values extends FieldValues>
  extends Omit<UseFormReturn<Values>, 'handleSubmit'> {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  disabled: boolean;
}

export const EnhancedFormContext = createContext<EnhancedFormContextValue<any>>(undefined as any);

export interface EnhancedFormInnerProps<Values extends FieldValues> {
  form: UseFormReturn<Values>;
  preventLeave?: boolean;
  disabled?: boolean;
  onSubmit: (values: Values) => Effect.Effect<never, unknown, unknown>;
}

export type EnhancedFormExposedMethods = {
  handleSubmit: () => void;
};

function EnhanceFormInner<Values extends FieldValues>(
  { form, preventLeave, disabled, onSubmit, children }: PropsWithChildren<EnhancedFormInnerProps<Values>>,
  ref: React.ForwardedRef<EnhancedFormExposedMethods>,
) {
  const isSubmitting = useRef<boolean>(false);

  const blocker = useBlocker(() => !!preventLeave && form.formState.isDirty && !disabled && !isSubmitting.current);

  const handleSubmit = (values: Values) => {
    if (!disabled) {
      pipe(
        Effect.sync(() => (isSubmitting.current = true)),
        Effect.flatMap(() => onSubmit(values)),
        Effect.tap(() => Effect.sync(() => form.reset(undefined, { keepValues: true }))),
        Effect.onExit(() => Effect.sync(() => (isSubmitting.current = false))),
        Effect.runPromise,
      );
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: form.handleSubmit(handleSubmit),
  }));

  const setValue = useCallback<UseFormSetValue<Values>>(
    (name, value, options = {}) =>
      form.setValue(name, value, {
        ...options,
        shouldDirty: options.shouldDirty ?? true,
        shouldValidate: options.shouldValidate ?? form.formState.isSubmitted,
      }),
    [form],
  );

  const ctx: EnhancedFormContextValue<Values> = {
    ...form,
    setValue,
    handleSubmit: form.handleSubmit(handleSubmit),
    disabled: disabled ?? false,
  };

  return (
    <>
      {preventLeave && (
        <FormPreventLeaveModal
          open={blocker.state === 'blocked'}
          onLeave={blocker.proceed ?? Function.constVoid}
          onClose={blocker.reset ?? Function.constVoid}
        />
      )}

      <EnhancedFormContext.Provider value={ctx}>
        <DisableableForm disabled={disabled}>{children}</DisableableForm>
      </EnhancedFormContext.Provider>
    </>
  );
}

// Redecalare forwardRef
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const EnhancedForm = React.forwardRef(EnhanceFormInner);

export default EnhancedForm;
