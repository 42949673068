import { z } from 'zod';
import { Countries } from '@shared/model';
import { nonEmptyStringSchema } from '@shared/schemas';

export const Address = z.object({
  firstLine: nonEmptyStringSchema,
  secondLine: z.string().nullish(),
  complement: z.string().nullish(),
  postalCode: nonEmptyStringSchema,
  city: nonEmptyStringSchema,
  country: z.nativeEnum(Countries),
});

export type Address = z.infer<typeof Address>;

export const LightAddress = Address.pick({
  postalCode: true,
  city: true,
  country: true,
});

export type LightAddress = z.infer<typeof LightAddress>;
