import React, { FC, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Auth } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { Alert, Anchor, Button, Group, PasswordInput, Text, TextInput, Title } from '@mantine/core';
import { OAuth } from '@core/oauth/model';
import { OAuthUtils } from '@core/oauth/utils';
import { useTranslation } from 'react-i18next';
import OAuthErrorCode = OAuth.OAuthErrorCode;
import { SentryUtils } from '@shared/modules/sentry/utils';
import { pipe, Option } from 'effect';

interface LoginFormProps {
  loading: boolean;
  error: Option.Option<OAuth.OAuthHttpError>;
  onSubmit: (params: Auth.AuthenticateParams) => void;
}

const LoginForm: FC<LoginFormProps> = ({ loading, error, onSubmit }) => {
  const { t } = useTranslation('login');

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Auth.AuthenticateParams>({
    resolver: zodResolver(Auth.AuthenticateParams),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const errorMessage = useMemo(
    () =>
      pipe(
        error,
        Option.map(error => {
          switch (OAuthUtils.getOAuthErrorCode(error)) {
            case OAuthErrorCode.InvalidGrant:
              return t('errors.invalid-grant');
            case OAuthErrorCode.SlowDown:
              return t('errors.account-temporary-locked');
            case OAuthErrorCode.AccessDenied:
              return t('errors.account-locked');
            default:
              SentryUtils.logHttpError(error);

              return t('errors.technical', { ns: 'common' });
          }
        }),
      ),
    [error, t],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Title>{t('title')}</Title>

      <Text mt={10} mb={25}>
        {t('subtitle')}
      </Text>

      {renderOptional(errorMessage, errorMessage => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {errorMessage}
          </Text>
        </Alert>
      ))}

      <TextInput
        id="email"
        autoComplete="email"
        type="email"
        label={t('form.email.label')}
        required
        placeholder={t('form.email.placeholder')}
        error={!!errors.email}
        {...register('email')}
      />

      <PasswordInput
        mt="sm"
        id="password"
        autoComplete="current-password"
        label={t('form.password.label')}
        required
        placeholder={t('form.password.placeholder')}
        error={!!errors.password}
        {...register('password')}
      />

      <Group mt={25} position="apart">
        <Button type="submit" loading={loading} disabled={loading}>
          {t('form.actions.submit')}
        </Button>

        <Anchor component={Link} c="dark.9" to="/password-reset/forgot" size="xs" fw={500}>
          {t('form.actions.reset-password-link')}
        </Anchor>
      </Group>

      <Group mt={20} spacing={5}>
        <Text size="sm" c="dark.9">
          {t('link.register.label')}
        </Text>

        <Anchor component={Link} c="dark.9" to="/register" size="sm" fw={500}>
          {t('link.register.link')}
        </Anchor>
      </Group>
    </form>
  );
};

export default LoginForm;
