import { TranslationsUtils } from '@core/translations';
import { MantineColor } from '@mantine/core';
import { z } from 'zod';
import { Sensor } from '@modules/sensors/model';
import { Account } from '@modules/account/model';
import { Role } from '@modules/roles/model';
import { Parcel } from '@modules/parcels/model';
import { LocalDate, LocalDateTime } from '@shared/modules/dates';
import { StringifiableRecord } from 'query-string';
import { ReadonlyArray } from 'effect';

export namespace Issue {
  export const Id = z.string().uuid().brand('IssueId');
  export type Id = z.infer<typeof Id>;

  export enum Category {
    Business = 'business',
    Technical = 'technical',
  }

  export const categoryLabel = TranslationsUtils.createTranslatedEnumLabel(
    Category,
    category => t => t(`category.${category}`, { ns: 'issues' }),
  );

  export enum Type {
    OuvertureCouvercle = 'ouverture_couvercle',
    AbsencePanneauSolaire = 'absence_panneau_solaire',
    OuvertureTrappe = 'ouverture_trappe',
    ModeVol = 'mode_vol',
    BatterieAbsente = 'batterie_absente',
    Renversement = 'renversement',
    HorsServicePlus24H = 'hors_service_plus_24_h',
    AbsenceStationMeteo = 'absence_station_meteo',
    AbsenceResultats = 'absence_resultats',
    AbsenceCaptureComplementaire = 'absence_capture_complementaire',
    AbsenceMesuresMeteo = 'absence_mesures_meteo',
    ErreurModuleGPS = 'erreur_module_gps',
    ErreurMiseHeure = 'erreur_mise_heure',
    ErreurAccelerometre = 'erreur_accelerometre',
    AbsencePositionGPS = 'absence_position_gps',
    TauxRemplissageUsbEleve = 'taux_remplissage_usb_eleve',
    EchecCopieUsb = 'echec_copie_usb',
    NombreZonesNeutraliseesEleve = 'nombre_zones_neutralisees_eleve',
    AcquisitionPartielle = 'acquisition_partielle',
    DispositifPerdu = 'dispositif_perdu',
    ComptagePartiel = 'comptage_partiel',
    AbsencePrecipitations = 'absence_precipitations',
    ErreurModuleLinux = 'erreur_module_linux',
    EchecMiseAJour = 'echec_mise_jour',
    NiveauBatterieInsuffisant = 'niveau_batterie_insuffisant',
    InterventionCulturale = 'intervention_culturale',
    DefautEcartTypeNettete = 'defaut_ecart_type_nettete',
    DefautLuminositeGlobale = 'defaut_luminosite_globale',
    DefautEcartTypeLuminosite = 'defaut_ecart_type_luminosite',
  }

  export const typeLabel = TranslationsUtils.createTranslatedEnumLabel(
    Type,
    type => t => t(`type.${type}`, { ns: 'issues' }),
  );

  export const typeColor: Record<Type, MantineColor> = {
    [Type.OuvertureCouvercle]: 'yellow.8',
    [Type.AbsencePanneauSolaire]: 'yellow.6',
    [Type.AbsenceStationMeteo]: 'yellow.5',
    [Type.OuvertureTrappe]: 'yellow.4',
    [Type.Renversement]: 'yellow.3',
    [Type.AbsencePrecipitations]: 'yellow.2',
    [Type.ModeVol]: 'yellow.1',

    [Type.HorsServicePlus24H]: 'yellow.6',
    [Type.TauxRemplissageUsbEleve]: 'dark.3',
    [Type.EchecCopieUsb]: 'background.9',
    [Type.AbsenceCaptureComplementaire]: 'background.8',
    [Type.AbsenceMesuresMeteo]: 'lime.3',
    [Type.AbsencePositionGPS]: 'lime.4',
    [Type.NombreZonesNeutraliseesEleve]: 'lime.6',
    [Type.AbsenceResultats]: 'lime.7',
    [Type.AcquisitionPartielle]: 'lime.8',
    [Type.DispositifPerdu]: 'lime.9',
    [Type.ComptagePartiel]: 'lime.1',
    [Type.NiveauBatterieInsuffisant]: 'lime.0',
    [Type.ErreurModuleLinux]: 'teal.9',
    [Type.EchecMiseAJour]: 'teal.8',
    [Type.InterventionCulturale]: 'teal.7',
    [Type.ErreurModuleGPS]: 'teal.5',
    [Type.ErreurMiseHeure]: 'teal.3',
    [Type.ErreurAccelerometre]: 'teal.1',
    [Type.BatterieAbsente]: 'teal.0',
    [Type.DefautEcartTypeNettete]: 'blue.0',
    [Type.DefautLuminositeGlobale]: 'blue.1',
    [Type.DefautEcartTypeLuminosite]: 'blue.2',
  };

  export enum Level {
    Low = 'low',
    High = 'high',
    Blocking = 'blocking',
  }

  export const levelLabel = TranslationsUtils.createTranslatedEnumLabel(
    Level,
    level => t => t(`level.${level}`, { ns: 'issues' }),
  );

  export const levelColor: Record<Level, MantineColor> = {
    [Level.Low]: 'yellow.6',
    [Level.High]: 'orange.6',
    [Level.Blocking]: 'red.9',
  };

  export interface Acknowledgement {
    firstName: string | null;
    lastName: string | null;
    companyLabel: string;
    at: LocalDateTime;
  }

  export interface AcknowledgementDisplay {
    date: string | null;
    // Si null -> acquittement auto
    by?: string | null;
  }

  export interface Filter extends StringifiableRecord {
    category: Category | null;
    type: Type | null;
    startDate: LocalDate | null;
    endDate: LocalDate | null;
    acknowledgedIssue: true | null;
    sensor: Sensor.Id | null;
    parcel: Parcel.Id | null;
  }

  export interface Info {
    level: Level;
    types: ReadonlyArray.NonEmptyArray<Type>;
  }

  export interface Referential {
    type: Type;
    level: Level;
    issueCategory: Category;
  }
}

export interface Issue {
  id: Issue.Id;
  category: Issue.Category;
  type: Issue.Type;
  level: Issue.Level;
  sensorId: Sensor.Id;
  sensorSerialNumber: string;
  ownerId: Account.Id;
  ownerLabel: string;
  ownerType: Role.AccountType;
  parcelId: Parcel.Id;
  parcelLabel: string;
  startedAt: LocalDateTime;
  endedAt: LocalDateTime | null;
  acknowledgement: Issue.Acknowledgement | null;
}
