import i18next, { Translations } from './index';
import { Namespace } from 'i18next';
import { pipe, Effect, Option, ReadonlyArray } from 'effect';

export namespace TranslationsUtils {
  export function loadNamespace(namespaces: Namespace) {
    return Effect.promise(() => i18next.loadNamespaces(namespaces));
  }

  export function parseLanguage(language: string): Translations.Language {
    switch (language) {
      case 'fr':
        return Translations.Language.French;
      case 'de':
        return Translations.Language.German;
      case 'es':
        return Translations.Language.Spanish;
      case 'en':
      default:
        return Translations.Language.English;
    }
  }

  export function createTranslatedEnumLabel<E extends string>(
    enumeration: Record<string, E>,
    interpolation: (value: E) => Translations.TranslationInterpolation,
  ) {
    return pipe(
      Object.values(enumeration),
      ReadonlyArray.reduce({} as Translations.TranslatedEnumLabel<E>, (translations, value) => ({
        ...translations,
        [value]: interpolation(value),
      })),
    );
  }

  export function formatNumber(value: number, options?: Intl.NumberFormatOptions) {
    return Intl.NumberFormat(parseLanguage(i18next.language), options).format(value);
  }

  export interface UnitLabelOptions {
    unit: string;
    format?: 'short' | 'long';
    pluralize?: boolean;
  }

  export function getUnitLabel({ unit, format = 'long', pluralize = true }: UnitLabelOptions) {
    return pipe(
      Intl.NumberFormat(parseLanguage(i18next.language), {
        style: 'unit',
        unit,
        unitDisplay: format,
      }).formatToParts(pluralize ? 2 : 1),
      ReadonlyArray.findFirst(part => part.type === 'unit'),
      Option.map(part => part.value),
      Option.getOrNull,
    );
  }
}
