import React, { FC, useLayoutEffect, useState } from 'react';
import { Loader, Modal, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Effect, Function } from 'effect';

const API_DOWN_EVENT_NAME = 'api-down';
const API_UP_EVENT_NAME = 'api-up';

const ApiDownIndicator: FC = () => {
  const { t } = useTranslation('common');

  const [opened, setOpened] = useState<boolean>(false);

  useLayoutEffect(() => {
    const showIndicator = () => setOpened(true);
    const hideIndicator = () => setOpened(false);

    window.addEventListener(API_DOWN_EVENT_NAME, showIndicator);
    window.addEventListener(API_UP_EVENT_NAME, hideIndicator);

    return () => {
      window.removeEventListener(API_DOWN_EVENT_NAME, showIndicator);
      window.removeEventListener(API_UP_EVENT_NAME, hideIndicator);
    };
  }, []);

  return opened ? (
    <Modal size="md" opened={opened} onClose={Function.constVoid} withCloseButton={false} centered>
      <Stack p="md" align="center">
        <Title size="h3" align="center">
          {t('down.title')}
        </Title>

        <Loader size="lg" />

        <Text align="center">{t('down.description')}</Text>
      </Stack>
    </Modal>
  ) : null;
};

export const showApiDownIndicator = Effect.sync(() => window.dispatchEvent(new Event(API_DOWN_EVENT_NAME)));

export const hideApiDownIndicator = Effect.sync(() => window.dispatchEvent(new Event(API_UP_EVENT_NAME)));

export default ApiDownIndicator;
