import { z } from 'zod';
import { Sensor } from '@modules/sensors/model';
import { Parcel } from '@modules/parcels/model';
import { Issue } from '@modules/issues/model';
import { LocalDate, LocalDateTime } from '@shared/modules/dates';
import { Subscription } from '@modules/subscriptions/model';
import { Contract } from '@shared/modules/contracts/model';

export namespace Notification {
  export const Id = z.string().uuid().brand('NotificationId');
  export type Id = z.infer<typeof Id>;

  export namespace Payload {
    export interface SensorAffected {
      sensorId: Sensor.Id;
      sensorSerialNumber: string;
    }

    export interface IssueOpened {
      sensorId: Sensor.Id;
      sensorSerialNumber: string;
      parcelId: Parcel.Id;
      parcelLabel: string;
      issueId: Issue.Id;
      issueType: Issue.Type;
      issueLevel: Issue.Level;
    }

    export interface ValidateEstimatedSeedingDate {
      parcelId: Parcel.Id;
      parcelLabel: string;
      estimatedSeedingDate: LocalDate;
      cropId: Parcel.Crop.Id;
    }

    export interface EstimatedSeedingDatePassed {
      parcelId: Parcel.Id;
      parcelLabel: string;
      estimatedSeedingDate: LocalDate;
      cropId: Parcel.Crop.Id;
    }

    export interface PhenologicalStageUpdateReminder {
      parcelId: Parcel.Id;
      parcelLabel: string;
      currentCrop: Parcel.Crop.Type;
      stage: Parcel.Crop.Stage;
      cropId: Parcel.Crop.Id;
    }

    export interface SubscriptionToRenew {
      parcelId: Parcel.Id | null;
      parcelLabel: string | null;
      sensorSerialNumber: string;
      dayLeft: number | null;
      subscriptionId: Subscription.Id;
    }

    export interface SubscriptionExpired {
      sensorSerialNumber: string;
      subscriptionId: Subscription.Id;
    }

    export interface Distance {
      sensorSerialNumber: string;
      parcelId: Parcel.Id;
      parcelLabel: string;
      distance: number;
    }

    export interface FillInterventionReminder {
      parcelId: Parcel.Id;
      cropId: Parcel.Crop.Id;
    }

    export interface RentContractToValidate {
      rentContractId: Contract.RentContractId;
    }
  }

  export enum Type {
    SensorAffected = 'new-sensor-affected',
    IssueOpened = 'issue-opened',
    ValidateEstimatedSeedingDate = 'validate-estimated-seeding-date',
    EstimatedSeedingDatePassed = 'estimated-seeding-date-passed',
    PhenologicalStageUpdateReminder = 'phenological-stage-update-reminder',
    SubscriptionToRenew = 'subscription-to-renew',
    SubscriptionExpired = 'subscription-expired',
    Distance = 'distance',
    FillInterventionReminder = 'fill-intervention-reminder',
    RentContractToValidate = 'rent-contract-to-validate',
  }

  export interface GenericNotification<Type extends Notification.Type, Payload extends {} = {}> {
    id: Id;
    date: LocalDateTime;
    seenAt: LocalDateTime | null;
    type: Type;
    payload: Payload;
  }

  export type SensorAffected = GenericNotification<Notification.Type.SensorAffected, Payload.SensorAffected>;
  export type IssueOpened = GenericNotification<Notification.Type.IssueOpened, Payload.IssueOpened>;
  export type ValidateEstimatedSeedingDate = GenericNotification<
    Notification.Type.ValidateEstimatedSeedingDate,
    Payload.ValidateEstimatedSeedingDate
  >;
  export type EstimatedSeedingDatePassed = GenericNotification<
    Notification.Type.EstimatedSeedingDatePassed,
    Payload.EstimatedSeedingDatePassed
  >;
  export type PhenologicalStageUpdateReminder = GenericNotification<
    Notification.Type.PhenologicalStageUpdateReminder,
    Payload.PhenologicalStageUpdateReminder
  >;
  export type SubscriptionToRenew = GenericNotification<
    Notification.Type.SubscriptionToRenew,
    Payload.SubscriptionToRenew
  >;
  export type SubscriptionExpired = GenericNotification<
    Notification.Type.SubscriptionExpired,
    Payload.SubscriptionExpired
  >;
  export type Distance = GenericNotification<Notification.Type.Distance, Payload.Distance>;
  export type FillInterventionReminder = GenericNotification<
    Notification.Type.FillInterventionReminder,
    Payload.FillInterventionReminder
  >;
  export type RentContractToValidate = GenericNotification<
    Notification.Type.RentContractToValidate,
    Payload.RentContractToValidate
  >;
}

export type Notification<
  Type extends Notification.Type = Notification.Type,
  Map extends { [Type in Notification.Type]: Notification.GenericNotification<Type> } = {
    [Notification.Type.SensorAffected]: Notification.SensorAffected;
    [Notification.Type.IssueOpened]: Notification.IssueOpened;
    [Notification.Type.ValidateEstimatedSeedingDate]: Notification.ValidateEstimatedSeedingDate;
    [Notification.Type.EstimatedSeedingDatePassed]: Notification.EstimatedSeedingDatePassed;
    [Notification.Type.PhenologicalStageUpdateReminder]: Notification.PhenologicalStageUpdateReminder;
    [Notification.Type.SubscriptionToRenew]: Notification.SubscriptionToRenew;
    [Notification.Type.SubscriptionExpired]: Notification.SubscriptionExpired;
    [Notification.Type.Distance]: Notification.Distance;
    [Notification.Type.FillInterventionReminder]: Notification.FillInterventionReminder;
    [Notification.Type.RentContractToValidate]: Notification.RentContractToValidate;
  },
> = Map[Type];
