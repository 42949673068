import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Button, Modal, Stack, Title } from '@mantine/core';

import notificationsImage from '@assets/images/notifications.svg';
import { Link } from 'react-router-dom';
import { NOTIFICATIONS_DRAWER_HASH } from '@modules/notifications/components/NotificationsDrawer';

interface NotificationsModalProps {
  opened: boolean;
  onClose: () => void;
}

const NotificationsModal: FC<NotificationsModalProps> = ({ opened, onClose }) => {
  const { t } = useTranslation('common', { keyPrefix: 'notifications.modal' });

  return (
    <Modal size={780} opened={opened} onClose={onClose} centered withCloseButton={false}>
      <Stack p={30} align="center" spacing={20} ta="center">
        <img width={282} height={174} src={notificationsImage} alt="notifications" />

        <Title mt={10}>{t('title')}</Title>

        <Button component={Link} to={{ hash: NOTIFICATIONS_DRAWER_HASH }} onClick={onClose}>
          {t('action')}
        </Button>

        <Anchor component="button" color="dark" size="sm" fw={600} onClick={onClose}>
          {t('action.later')}
        </Anchor>
      </Stack>
    </Modal>
  );
};

export default NotificationsModal;
