import { Filter } from '@shared/modules/filter/model';
import { ParsedQuery } from 'query-string';
import { pipe, ReadonlyRecord } from 'effect';
import { QueryUtils } from '@shared/utils/queries';

export namespace FilterUtils {
  export function isEmpty(filter: Filter): boolean {
    return pipe(
      filter,
      ReadonlyRecord.filter(value => (Array.isArray(value) ? value.length > 0 : value != null)),
      ReadonlyRecord.isEmptyRecord,
    );
  }

  export function getSearchQuery(query: ParsedQuery) {
    return QueryUtils.getStringQuery(query, 'search');
  }
}
