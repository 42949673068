import React, { FC } from 'react';
import { useProfile } from '@modules/profile/loader';
import { useProfilePredicate } from '@modules/profile/hooks';
import { ProfilePredicates } from '@modules/profile/predicates';
import { Role } from '@modules/roles/model';

import * as Styled from './MissingInfoCounter.styles';
import { QueryUtils } from '@shared/utils/queries';
import { Parcel } from '@modules/parcels/model';
import { IconClipboardCheck } from '@tabler/icons-react';
import { Box, Indicator } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface MissingInfoCounterProps {}

const MissingInfoCounter: FC<MissingInfoCounterProps> = () => {
  const { t } = useTranslation();

  const profile = useProfile();

  const canAccessParcels = useProfilePredicate(ProfilePredicates.canAccess(Role.AccessKey.Parcels));

  if (!canAccessParcels) {
    return null;
  }

  return (
    <Styled.MissingInfoCounterContainer
      to={{
        pathname: '/parcels/list',
        search: QueryUtils.stringify({ missingInfoOnly: true } satisfies Partial<Parcel.Filter>),
      }}
    >
      <Indicator
        disabled={profile.counter.parcelMissingInfo === 0}
        color="yellow.3"
        position="bottom-end"
        withBorder
        size={10}
        w={24}
        h={24}
        offset={3}
        styles={theme => ({ indicator: { borderColor: theme.colors.background[3] } })}
      >
        <Box c="background.9" w={24} h={24}>
          <IconClipboardCheck size={24} />
        </Box>
      </Indicator>

      <Styled.MissingInfoCounterText>{t('layout.missing-info-parcels')}</Styled.MissingInfoCounterText>

      {profile.counter.parcelMissingInfo > 0 ? (
        <Styled.MissingInfoCounter>{profile.counter.parcelMissingInfo}</Styled.MissingInfoCounter>
      ) : null}
    </Styled.MissingInfoCounterContainer>
  );
};

export default MissingInfoCounter;
