import { useProfile } from '@modules/profile/loader';
import { Profile } from '@modules/profile/model';
import { Predicate } from 'effect';
import { To } from 'react-router-dom';
import { QueryUtils } from '@shared/utils/queries';

export function useProfilePredicate(predicate: Predicate.Predicate<Profile>) {
  return predicate(useProfile());
}

export function useSavAuthenticateUrl(): (referrer: string) => To;
export function useSavAuthenticateUrl(referrer: string): To;
export function useSavAuthenticateUrl(referrer?: string) {
  const builder = (referrer: string): To => ({
    pathname: '/sav-redirect',
    search: QueryUtils.stringify({ referrer }),
  });

  return referrer ? builder(referrer) : builder;
}
