import React from 'react';
import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

export const providersRoutes = (
  <Route path="providers">
    <Route index {...createLazyRoute(module, m => m.providersListRoute)} />
    <Route path="new" {...createLazyRoute(module, m => m.createProviderRoute)} />

    <Route path=":id" {...createLazyRoute(module, m => m.providerDetailLoaderRoute, 'detail-provider')}>
      <Route {...createLazyRoute(module, m => m.providerDetailLayoutRoute)}>
        <Route index {...createLazyRoute(module, m => m.providerDetailInfos)} />
        <Route path="users" {...createLazyRoute(module, m => m.providerDetailUsers)} />
        <Route path="contracts" {...createLazyRoute(module, m => m.providerContractsListRoute)} />
      </Route>

      <Route path="contracts">
        <Route path=":contractId" {...createLazyRoute(module, m => m.providerContractDetailRoute)} />
        <Route path="new" {...createLazyRoute(module, m => m.createProviderContractRoute)} />
      </Route>
    </Route>
  </Route>
);

export default providersRoutes;
