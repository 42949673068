import { MantineSize } from '@mantine/core';

export namespace LayoutConstants {
  export const NAV_OPEN_WIDTH = 270;
  export const NAV_CLOSE_WIDTH = 86;
  export const MOBILE_HEADER_HEIGHT = 66;
  export const LAYOUT_MOBILE_BREAKPOINT: MantineSize = 'md';

  export const TABS_HEIGHT = 50;
}
