import axios, { AxiosHeaders } from 'axios';

import { v4 as uuid } from 'uuid';
import { QueryUtils } from '@shared/utils/queries';
import { TRANSACTION_ID_HEADER_KEY } from '@core/http/model';
import config from '@root/config';
import { OAuthService } from '@core/oauth/service';
import { getApiBaseURL } from '@core/http/utils';
import { Effect, Option, pipe } from 'effect';

export const defaultAxiosInstance = axios.create({
  paramsSerializer: { serialize: QueryUtils.stringify },
  withCredentials: true,
  baseURL: getApiBaseURL(config.VITE_API_PREFIX),
});

defaultAxiosInstance.interceptors.request.use(config => {
  const setAuthorizationHeader = (headers: AxiosHeaders) =>
    pipe(
      OAuthService.getOAuthTokensFromStorage(),
      Effect.map(
        Option.match({
          onSome: ({ access_token }) => headers.setAuthorization(`Bearer ${access_token}`),
          onNone: () => headers,
        }),
      ),
    );

  const setTransactionId = (headers: AxiosHeaders) =>
    pipe(
      Effect.sync(() => uuid()),
      Effect.map(uuid => headers.set(TRANSACTION_ID_HEADER_KEY, uuid)),
    );

  const headers = pipe(
    Effect.succeed(config.headers),
    Effect.flatMap(setAuthorizationHeader),
    Effect.flatMap(setTransactionId),
  );

  return pipe(
    headers,
    Effect.map(headers => ({
      ...config,
      headers,
    })),
    Effect.runPromise,
  );
});
