import React from 'react';
import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

import { Dashboard } from '@modules/dashboard/model';

const module = () => import('./module');

export const dashboardRoutes = (
  <Route path="dashboard" {...createLazyRoute(module, m => m.dashboardLayoutRoute)}>
    <Route index {...createLazyRoute(module, m => m.dashboardIndexRoute)} />

    <Route path={Dashboard.Tabs.Path.Activity} {...createLazyRoute(module, m => m.activityDashboardRoute)} />
    <Route path={Dashboard.Tabs.Path.Sensors} {...createLazyRoute(module, m => m.sensorsDashboardRoute)} />
    <Route path={Dashboard.Tabs.Path.Support} {...createLazyRoute(module, m => m.supportDashboardRoute)} />
    <Route path={Dashboard.Tabs.Path.Metrics} {...createLazyRoute(module, m => m.metricsDashboardRoute)} />
    <Route path={Dashboard.Tabs.Path.Qos} {...createLazyRoute(module, m => m.qosDashboardRoute)} />
  </Route>
);

export default dashboardRoutes;
