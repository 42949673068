import { HttpEffect, HttpRange, httpService } from '@core/http';
import { Favourite } from '@modules/favourites/model';
import { Account } from '@modules/account/model';
import { Effect, pipe, Option } from 'effect';
import { HttpStatusCode } from 'axios';
import { EffectCache } from '@core/fp';
import localforage from 'localforage';

export namespace FavouritesService {
  const URI = '/favourites';

  const favouritesStorage = localforage.createInstance({
    name: 'limacapt',
    storeName: 'favourites',
  });

  export function getRange(page: number): HttpRange<Favourite.Item> {
    return httpService.getRange(URI, page, {}, null);
  }

  const listTask = pipe(
    httpService.get<Array<Favourite.Item>>(`${URI}/all`),
    // On triche un peu pour avoir une les agris
    Effect.matchEffect({
      onSuccess: Effect.succeed,
      onFailure: err => (err.status === HttpStatusCode.Forbidden ? Effect.succeed([]) : Effect.fail(err)),
    }),
  );
  const listCache = new EffectCache(listTask);

  function withCacheClear<R = unknown, E = unknown>(task: HttpEffect<R, E>): HttpEffect<R, E> {
    return pipe(task, Effect.tap(listCache.clear));
  }

  function clearCache() {
    return listCache.clear();
  }

  function clearStorage() {
    return Effect.promise(() => favouritesStorage.clear());
  }

  export function clearAll() {
    return Effect.all({ cache: clearCache(), storage: clearStorage() }, { concurrency: 'unbounded' });
  }

  export function getList(): HttpEffect<Array<Favourite.Item>> {
    return listCache.get();
  }

  export function createFavourite(params: Favourite.Params): HttpEffect<Favourite> {
    return withCacheClear(httpService.post(URI, params));
  }

  export function getFavourite(id: Favourite.Id): HttpEffect<Favourite> {
    return httpService.get(`${URI}/${id}`);
  }

  export function updateFavourite(id: Favourite.Id, params: Favourite.Params): HttpEffect<Favourite> {
    return withCacheClear(httpService.post(`${URI}/${id}`, params));
  }

  export function deleteFavourite(id: Favourite.Id): HttpEffect {
    return withCacheClear(httpService.delete(`${URI}/${id}`));
  }

  export function getReferentialAccounts(
    filter: Favourite.Referential.PostalZonesFilter,
  ): HttpEffect<Array<Account.Light>> {
    return httpService.post(`${URI}/referential/accounts`, filter);
  }

  export function getReferentialSensors(filter: Favourite.Referential.AccountFilter): HttpEffect<Array<string>> {
    return httpService.post(`${URI}/referential/sensors`, filter);
  }

  export function getReferentialTags(filter: Favourite.Referential.AccountFilter): HttpEffect<Array<string>> {
    return httpService.post(`${URI}/referential/tags`, filter);
  }

  export function getLastUsedFavourite(): Effect.Effect<never, never, Option.Option<Favourite.Filter.Id>> {
    return pipe(
      Effect.promise(() => favouritesStorage.getItem<Favourite.Filter.Id>('last')),
      Effect.map(Option.fromNullable),
    );
  }

  export function saveLastUsedFavourite(id: Favourite.Filter.Id) {
    return Effect.promise(() => favouritesStorage.setItem<Favourite.Filter.Id>('last', id));
  }
}
