import { Translations } from '@core/translations/model';
import { Resources } from '@core/translations/resources';
import { httpService, HttpEffect } from '@core/http';

export namespace TranslationsService {
  export function loadTranslations(
    language: Translations.Language,
    namespace: keyof Resources,
  ): HttpEffect<Record<string, string>> {
    return httpService.get(`/locales/${language}/${namespace}`);
  }
}
