import z from 'zod';
import { FilterQueryParser, FilterUtils, SearchFilter } from '@shared/modules/filter';
import { nonEmptyStringSchema } from '@shared/schemas';
import { Translations } from '@core/translations';
import { FunctionComponent, SVGProps } from 'react';
import { IconDeSangosse } from '@styles/icons';
import { IconBuildingStore, IconTractor, IconUser } from '@tabler/icons-react';
import { Effect, ReadonlyArray } from 'effect';
import { QueryUtils } from '@shared/utils/queries';

export namespace Role {
  export const Id = z.string().uuid().brand<'Id'>();
  export type Id = z.infer<typeof Id>;

  export enum AccountType {
    DeSangosse = 'desangosse',
    Provider = 'provider',
    Farmer = 'farmer',
    Anonymous = 'anonymous',
  }

  export const roleAccountTypeLabels: Translations.TranslatedEnumLabel<AccountType> = {
    [AccountType.DeSangosse]: t => t('type-desangosse', { ns: 'roles' }),
    [AccountType.Provider]: t => t('type-provider', { ns: 'roles' }),
    [AccountType.Farmer]: t => t('type-farmer', { ns: 'roles' }),
    [AccountType.Anonymous]: t => t('type-anonymous', { ns: 'roles' }),
  };

  export const roleAccountTypeIcons: Record<AccountType, FunctionComponent<SVGProps<SVGSVGElement>>> = {
    [AccountType.DeSangosse]: IconDeSangosse,
    [AccountType.Provider]: IconBuildingStore,
    [AccountType.Farmer]: IconTractor,
    [AccountType.Anonymous]: IconUser,
  };

  export enum Right {
    NoAccess = 'no-access',
    ReadOnly = 'read-only',
    FullAccess = 'full-access',
  }

  export const rightLabels: Translations.TranslatedEnumLabel<Right> = {
    [Right.NoAccess]: t => t('right.no-access', { ns: 'roles' }),
    [Right.ReadOnly]: t => t('right.read-only', { ns: 'roles' }),
    [Right.FullAccess]: t => t('right.full-access', { ns: 'roles' }),
  };

  export interface Filter extends SearchFilter {
    accountType: AccountType | null;
  }

  export const filterParser: FilterQueryParser<Filter> = query =>
    Effect.all({
      search: FilterUtils.getSearchQuery(query),
      accountType: QueryUtils.getEnumQuery(query, AccountType, 'accountType'),
    });

  export interface RangeItem {
    id: Id;
    label: string;
    accountType: AccountType;
  }

  export type SearchItem = Omit<RangeItem, 'accountType'>;

  export enum AccessKey {
    DashboardQos = 'dashboardQos',
    DashboardMaintenance = 'dashboardMaintenance',
    DashboardUsage = 'dashboardUsage',
    Parcels = 'parcels',
    Sensors = 'sensors',
    Defaults = 'defaults',
    ParcelsSharing = 'parcelSharing',
    ProviderAccounts = 'providerAccounts',
    FarmerAccounts = 'farmerAccounts',
    Users = 'users',
    Subscriptions = 'subscriptions',
    MyUsers = 'myUsers',
    Roles = 'roles',
    MyAccount = 'myAccount',
    Configuration = 'configuration',
  }

  export interface AccessInformation {
    accountTypes: ReadonlyArray.NonEmptyArray<AccountType>;
    title: Translations.TranslationInterpolation;
    subtitle?: Translations.TranslationInterpolation;
    infos?: Translations.TranslationInterpolation;
    route: string;
  }

  export const accessInformations: Record<AccessKey, AccessInformation> = {
    [AccessKey.DashboardQos]: {
      accountTypes: [AccountType.DeSangosse],
      title: t => t('access.dashboard', { ns: 'roles' }),
      subtitle: t => t('access.dashboard.qos', { ns: 'roles' }),
      route: '/stats/qos',
    },
    [AccessKey.DashboardMaintenance]: {
      accountTypes: [AccountType.DeSangosse],
      title: t => t('access.dashboard', { ns: 'roles' }),
      subtitle: t => t('access.dashboard.maintenance', { ns: 'roles' }),
      route: '/stats/maintenance',
    },
    [AccessKey.DashboardUsage]: {
      accountTypes: [AccountType.DeSangosse],
      title: t => t('access.dashboard', { ns: 'roles' }),
      subtitle: t => t('access.dashboard.usage', { ns: 'roles' }),
      route: '/stats/usage',
    },
    [AccessKey.Parcels]: {
      accountTypes: [AccountType.DeSangosse, AccountType.Provider, AccountType.Farmer],
      title: t => t('access.parcels', { ns: 'roles' }),
      infos: t => t('access.parcels.infos', { ns: 'roles' }),
      route: '/parcels',
    },
    [AccessKey.Sensors]: {
      accountTypes: [AccountType.DeSangosse, AccountType.Provider, AccountType.Farmer],
      title: t => t('access.sensors', { ns: 'roles' }),
      infos: t => t('access.sensors.infos', { ns: 'roles' }),
      route: '/sensors',
    },
    [AccessKey.Defaults]: {
      accountTypes: [AccountType.DeSangosse, AccountType.Provider, AccountType.Farmer],
      title: t => t('access.issues', { ns: 'roles' }),
      infos: t => t('access.issues.infos', { ns: 'roles' }),
      route: '/issues',
    },
    [AccessKey.ParcelsSharing]: {
      accountTypes: [AccountType.Provider],
      title: t => t('access.parcelsSharing', { ns: 'roles' }),
      route: '/parcels-sharing',
    },
    [AccessKey.ProviderAccounts]: {
      accountTypes: [AccountType.DeSangosse],
      title: t => t('access.providerAccount', { ns: 'roles' }),
      route: '/providers',
    },
    [AccessKey.FarmerAccounts]: {
      accountTypes: [AccountType.DeSangosse, AccountType.Provider],
      title: t => t('access.farmerAccount', { ns: 'roles' }),
      route: '/farmers',
    },
    [AccessKey.Users]: {
      accountTypes: [AccountType.DeSangosse, AccountType.Provider],
      title: t => t('access.users', { ns: 'roles' }),
      infos: t => t('access.users.infos', { ns: 'roles' }),
      route: '/users',
    },
    [AccessKey.Subscriptions]: {
      accountTypes: [AccountType.DeSangosse, AccountType.Provider],
      title: t => t('access.subscriptions', { ns: 'roles' }),
      route: '/subscriptions',
    },
    [AccessKey.MyUsers]: {
      accountTypes: [AccountType.DeSangosse, AccountType.Provider, AccountType.Farmer],
      title: t => t('access.myUsers', { ns: 'roles' }),
      route: '/my-users',
    },
    [AccessKey.Roles]: {
      accountTypes: [AccountType.DeSangosse],
      title: t => t('access.roles', { ns: 'roles' }),
      route: '/roles',
    },
    [AccessKey.MyAccount]: {
      accountTypes: [AccountType.Provider, AccountType.Farmer],
      title: t => t('access.myAccount', { ns: 'roles' }),
      route: '/account/profile',
    },
    [AccessKey.Configuration]: {
      accountTypes: [AccountType.DeSangosse, AccountType.Provider, AccountType.Farmer],
      title: t => t('access.configuration', { ns: 'roles' }),
      route: '/settings',
    },
  };

  export type StartPage = Exclude<AccessKey, AccessKey.Parcels> | 'dashboardActivity' | 'parcelsList' | 'parcelsMap';

  export interface StartPageInformation extends Omit<AccessInformation, 'accountTypes'> {
    relatedAccess: AccessKey;
    accountTypes?: ReadonlyArray.NonEmptyArray<AccountType>;
  }

  export const startPageInformations: Record<StartPage, StartPageInformation> = {
    dashboardActivity: {
      relatedAccess: AccessKey.Parcels,
      accountTypes: [Role.AccountType.DeSangosse, Role.AccountType.Provider],
      title: t => t('access.dashboard', { ns: 'roles' }),
      subtitle: t => t('access.dashboard.activity', { ns: 'roles' }),
      route: '/dashboard/activity',
    },
    parcelsList: {
      relatedAccess: AccessKey.Parcels,
      title: t => t('access.parcels', { ns: 'roles' }),
      subtitle: t => t('access.parcels.list', { ns: 'roles' }),
      route: '/parcels/list',
    },
    parcelsMap: {
      relatedAccess: AccessKey.Parcels,
      title: t => t('access.parcels', { ns: 'roles' }),
      subtitle: t => t('access.parcels.map', { ns: 'roles' }),
      route: '/parcels/map',
    },
    ...Object.keys(accessInformations)
      .filter(key => key !== AccessKey.Parcels)
      .reduce(
        (acc, curr) => {
          const key = curr as AccessKey;
          const { accountTypes, ...rest } = accessInformations[key];

          return { ...acc, [key]: { ...rest, relatedAccess: key } };
        },
        {} as Record<Exclude<AccessKey, AccessKey.Parcels>, StartPageInformation>,
      ),
  };

  export type Rights = Record<AccessKey, Right>;

  export const Body = z.object({
    accountType: z.nativeEnum(AccountType),
    labelFr: nonEmptyStringSchema,
    startPage: nonEmptyStringSchema,
    rights: z.record(z.nativeEnum(AccessKey), z.nativeEnum(Right)),
  });

  export type Body = z.infer<typeof Body>;

  export type Params = Omit<Body, 'rights'> & Partial<Rights>;

  export interface Detail extends Omit<Role, 'label'> {
    label: string;
  }

  export interface Label {
    fr: string;
    en: string | null;
    sp: string | null;
    de: string | null;
  }
}

export interface Role extends Role.Rights {
  id: Role.Id;
  label: Role.Label;
  accountType: Role.AccountType;
  startPage: Role.StartPage;
  isAdmin: boolean;
}
