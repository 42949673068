import { Favourite } from '@modules/favourites/model';
import { FilterQueryParser } from '@shared/modules/filter';
import { Dashboard } from '@modules/dashboard/model';
import { dateRangeFilterParser } from '@shared/modules/dates';
import { FavouritesUtils } from '@modules/favourites/utils';
import { Profile } from '@modules/profile/model';
import { Effect, pipe, Predicate, ReadonlyArray } from 'effect';

export namespace DashBoardUtils {
  export function getTabs(profile: Profile) {
    return pipe(
      Dashboard.Tabs.entries,
      ReadonlyArray.filter(tab => tab.canAccess(profile)),
    );
  }

  export const canAccess: Predicate.Predicate<Profile> = profile => ReadonlyArray.isNonEmptyArray(getTabs(profile));

  export function canAccessTab(tab: Dashboard.Tabs.Path): Predicate.Predicate<Profile> {
    return profile =>
      pipe(
        getTabs(profile),
        ReadonlyArray.filter(({ path }) => path === tab),
        ReadonlyArray.isNonEmptyArray,
      );
  }

  export const filterParser =
    (favourites: Array<Favourite.Item>): FilterQueryParser<Dashboard.Filter> =>
    query =>
      pipe(
        dateRangeFilterParser(query),
        Effect.zip(FavouritesUtils.withFavouriteFilterParser(query, favourites)),
        Effect.map(([dates, favourites]) => ({ ...dates, ...favourites })),
      );
}
