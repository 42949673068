import { ProfilePredicates } from '@modules/profile/predicates';
import { Role } from '@modules/roles/model';
import { ParsedQuery } from 'query-string';
import { Favourite } from '@modules/favourites/model';

import { HttpRange } from '@core/http';
import { Effect, pipe, ReadonlyArray } from 'effect';
import { QueryUtils } from '@shared/utils/queries';
import { FavouritesService } from '@modules/favourites/service';

export namespace FavouritesUtils {
  export const canAccess = ProfilePredicates.hasAccountType(Role.AccountType.DeSangosse, Role.AccountType.Provider);

  export function withFavouriteFilterParser(
    query: ParsedQuery,
    favourites: Array<Favourite.Item>,
  ): Effect.Effect<never, never, Favourite.Filter.WithFavouriteFilter> {
    return Effect.gen(function* (_) {
      const favouriteId = yield* _(QueryUtils.getSchemaQuery(query, Favourite.Filter.Id, 'favouriteId'));

      const filterFavouriteId = (favouriteId: Favourite.Filter.Id) => {
        if (favouriteId === 'none') {
          return Effect.succeed(favouriteId);
        }

        return pipe(
          ReadonlyArray.findFirst(favourites, favourite => favourite.id === favouriteId),
          Effect.map(favourite => favourite.id),
        );
      };

      const getInQuery = pipe(
        Effect.fromNullable(() => favouriteId),
        Effect.flatMap(filterFavouriteId),
      );

      const getInStorage = pipe(
        FavouritesService.getLastUsedFavourite(),
        Effect.flatten,
        Effect.flatMap(filterFavouriteId),
      );

      const getFirst = pipe(
        ReadonlyArray.findFirst(favourites, favourite => favourite.isFavourite),
        Effect.map(favourite => favourite.id),
      );

      const favourite = pipe(
        // Query
        getInQuery,
        // Sinon -> Enregistré
        Effect.orElse(() => getInStorage),
        // Sinon -> Premier favori flagué
        Effect.orElse(() => getFirst),
        Effect.orElse(() => Effect.succeed<Favourite.Filter.Id>('none')),
        // Enregistrement du sélectionné
        Effect.tap(FavouritesService.saveLastUsedFavourite),
      );

      return {
        favouriteId: yield* _(favourite),
      };
    });
  }

  export function serverFilterAdapter<F extends Favourite.Filter.WithFavouriteFilter>(filter: F) {
    return {
      ...filter,
      favouriteId: filter.favouriteId === 'none' ? null : filter.favouriteId,
    };
  }

  export function withServerFilter<T, F extends Favourite.Filter.WithFavouriteFilter>(
    filter: F,
    task: (filter: F) => HttpRange<T, F>,
  ): HttpRange<T, F> {
    return pipe(
      task(serverFilterAdapter(filter)),
      Effect.map(range => ({ ...range, filter })),
    );
  }
}
