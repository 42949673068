import { DateFormat, DateRangeFilter, DateTypes, LocalDate } from './model';
import { format, isValid, parse } from 'date-fns';

import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';

import { filterEmptyStringToOption } from '../../utils/string';
import i18next, { Translations } from '@core/translations';
import { FilterQueryParser } from '@shared/modules/filter';
import { dateFiltersPresets } from '@shared/modules/dates/components/DatesFilter';
import { Effect, Option, pipe, Tuple } from 'effect';
import { QueryUtils } from '@shared/utils/queries';
import config from '@root/config';
import { ParsedQuery } from 'query-string';

export function getDateFnsLocale(language: string | Translations.Language) {
  switch (language) {
    case Translations.Language.French:
      return fr;
    case Translations.Language.German:
      return de;
    case Translations.Language.Spanish:
      return es;
    default:
      return en;
  }
}

export function parseDate<F extends string = DateFormat.LocalDate>(
  date: (F extends DateFormat ? DateTypes<F> : string) | null | undefined,
  formatStr?: F,
): Option.Option<Date>;

export function parseDate<E, F extends string = DateFormat.LocalDate>(
  date: (F extends DateFormat ? DateTypes<F> : string) | null | undefined,
  formatStr: F,
  orElse: E,
): Date | E;

export function parseDate<E>(
  date: string | null | undefined,
  formatStr: string = DateFormat.LocalDate,
  orElse?: E,
): Option.Option<Date> | Date | E {
  return pipe(
    filterEmptyStringToOption(date),
    Option.map(date => parse(date, formatStr, new Date(), { locale: fr })),
    Option.filter(isValid),
    Option.match({
      onSome: date => (orElse === undefined ? Option.some(date) : date),
      onNone: () => (orElse === undefined ? Option.none() : orElse),
    }),
  );
}

type FormatDateResult<F> = F extends DateFormat ? DateTypes<F> : string;

export function formatDate<D extends Date | number | null | undefined, F extends string = DateFormat.LocalDate>(
  date: D,
  formatStr?: F,
): D extends NonNullable<D> ? FormatDateResult<F> : null;

export function formatDate<E, D extends Date | number | null | undefined, F extends string = DateFormat.LocalDate>(
  date: D,
  formatStr: F,
  orElse: E,
): D extends NonNullable<D> ? FormatDateResult<F> : E;

export function formatDate<E>(
  date: Date | number | null | undefined,
  formatStr: string = DateFormat.LocalDate,
  orElse?: E,
): string | E | null {
  return pipe(
    Option.fromNullable(date),
    Option.map(date =>
      format(date, formatStr, {
        locale: getDateFnsLocale(i18next.language),
      }),
    ),
    Option.getOrElse(() => orElse ?? null),
  );
}

export const genericDateRangeFilterParser = (
  query: ParsedQuery,
  startKey: string,
  endKey: string,
): Effect.Effect<never, never, [LocalDate, LocalDate]> => {
  const validateDate = (date?: LocalDate | null) =>
    pipe(
      parseDate(date, DateFormat.LocalDate, null),
      Option.fromNullable,
      Option.map(date => formatDate(date, DateFormat.LocalDate)),
    );

  const getDate = (key: string) => pipe(QueryUtils.getSchemaQuery(query, LocalDate, key), Effect.flatMap(validateDate));

  const getDemoRange = (): Option.Option<[LocalDate, LocalDate]> => {
    if (config.VITE_ENVIRONMENT === 'demo') {
      return Option.all([validateDate(config.VITE_DEMO_FILTER_FROM), validateDate(config.VITE_DEMO_FILTER_TO)]);
    }

    return Option.none();
  };

  return pipe(
    Effect.all([getDate(startKey), getDate(endKey)]),
    Effect.orElse(getDemoRange),
    Effect.orElse(() => {
      const [from, to] = dateFiltersPresets.week.preset();

      return Effect.succeed(Tuple.tuple(formatDate(from), formatDate(to)));
    }),
  );
};

export const dateRangeFilterParser: FilterQueryParser<DateRangeFilter> = query => {
  return pipe(
    genericDateRangeFilterParser(query, 'from', 'to'),
    Effect.map(([from, to]) => ({ from, to })),
  );
};
