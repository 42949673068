import { HttpEffect } from '@core/http/model';
import isEqual from 'lodash.isequal';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FlashNotificationOptions,
  showFlashFromExit,
  showTechnicalErrorFlashNotification,
} from '@shared/modules/notification/utils';
import { Cause, Console, Effect, Exit, Option, pipe } from 'effect';
import { SentryUtils } from '@shared/modules/sentry/utils';
import { RemoteData } from '@core/fp';

function useCurrentArgs<P extends unknown[]>(...args: P) {
  const [currentArgs, setCurrentArgs] = useState(args);

  useEffect(() => {
    setCurrentArgs(old => (!isEqual(old, args) ? args : old));
  }, [args]);

  return currentArgs;
}

export function useFetchTask<P extends unknown[], R, E>(
  effect: (...args: P) => Effect.Effect<never, E, R>,
  ...args: P
): [RemoteData<E, R>, Effect.Effect<never, E, R>, boolean, Dispatch<SetStateAction<RemoteData<E, R>>>] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<RemoteData<E, R>>(RemoteData.pending);

  const currentArgs = useCurrentArgs(...args);

  const program = useMemo(
    () =>
      pipe(
        Effect.sync(() => setLoading(true)),
        Effect.flatMap(() => effect(...currentArgs)),
        Effect.onExit(res =>
          pipe(
            Effect.sync(() => setLoading(false)),
            Effect.flatMap(() =>
              Exit.match(res, {
                onSuccess: data => Effect.sync(() => setData(RemoteData.success(data))),
                onFailure: cause =>
                  pipe(
                    Cause.failureOption(cause),
                    Option.match({
                      onSome: error => Effect.sync(() => setData(RemoteData.failure(error))),
                      onNone: () =>
                        pipe(
                          showTechnicalErrorFlashNotification(),
                          Effect.zip(Console.error(cause)),
                          Effect.zip(SentryUtils.logMessage('[useFetchTask] defect detected', 'error', { cause })),
                        ),
                    }),
                  ),
              }),
            ),
          ),
        ),
      ),
    [currentArgs, effect],
  );

  useEffect(() => {
    Effect.runPromise(program);
  }, [program]);

  return [data, program, loading, setData];
}

export function useFetchTaskOption<P extends unknown[], R, E>(
  effect: (...args: P) => Effect.Effect<never, E, R>,
  ...args: P
): [
  Option.Option<R>,
  Effect.Effect<never, never, Option.Option<R>>,
  boolean,
  Dispatch<SetStateAction<Option.Option<R>>>,
] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Option.Option<R>>(Option.none);

  const currentArgs = useCurrentArgs(...args);

  const program = useMemo(
    () =>
      pipe(
        Effect.sync(() => setLoading(true)),
        Effect.flatMap(() => effect(...currentArgs)),
        Effect.tapError(error => SentryUtils.logMessage('[useFetchTaskOption] error detected', 'error', { error })),
        Effect.option,
        Effect.tap(data =>
          Effect.sync(() => {
            setLoading(false);
            setData(data);
          }),
        ),
      ),
    [currentArgs, effect],
  );

  useEffect(() => {
    Effect.runPromise(program);
  }, [program]);

  return [data, program, loading, setData];
}

export function useFetchTaskArray<P extends unknown[], R, E>(
  effect: (...args: P) => HttpEffect<Array<R>, E>,
  ...args: P
): [Array<R>, Effect.Effect<never, never, Array<R>>, boolean, Dispatch<SetStateAction<Array<R>>>] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<R>>([]);

  const currentArgs = useCurrentArgs(...args);

  const program = useMemo(
    () =>
      pipe(
        Effect.sync(() => setLoading(true)),
        Effect.flatMap(() => effect(...currentArgs)),
        Effect.tapError(error => SentryUtils.logMessage('[useFetchTaskArray] error detected', 'error', { error })),
        Effect.orElse(() => Effect.succeed([])),
        Effect.tap(data =>
          Effect.sync(() => {
            setLoading(false);
            setData(data);
          }),
        ),
      ),
    [currentArgs, effect],
  );

  useEffect(() => {
    Effect.runPromise(program);
  }, [program]);

  return [data, program, loading, setData];
}

export function useSendTask<P1 extends unknown[], P2 extends unknown[], R, E>(
  effect: (...args: [...P1, ...P2]) => Effect.Effect<never, E, R>,
  flashOptions?: FlashNotificationOptions<E>,
  ...args: P1
): [boolean, (...args: P2) => Effect.Effect<never, E, R>, Option.Option<E>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Option.Option<E>>(Option.none);
  const [success, setSuccess] = useState<boolean>(false);

  const currentArgs = useCurrentArgs(...args);

  const flashOptionsRef = useRef(flashOptions);
  flashOptionsRef.current = flashOptions;

  const sendRequest = useCallback(
    (...args2: P2) => {
      return pipe(
        Effect.sync(() => {
          setLoading(true);
          setError(Option.none());
          setSuccess(false);
        }),
        Effect.flatMap(() => effect(...[...currentArgs, ...args2])),
        Effect.onExit(exit =>
          pipe(
            showFlashFromExit(flashOptionsRef.current)(exit),
            Effect.flatMap(() =>
              Effect.sync(() => {
                setLoading(false);
                setSuccess(Exit.isSuccess(exit));
                setError(pipe(exit, Exit.causeOption, Option.flatMap(Cause.failureOption)));
              }),
            ),
          ),
        ),
      );
    },
    [effect, currentArgs],
  );

  return [loading, sendRequest, error, success];
}

export function useDeleteTask<P1 extends unknown[], P2 extends unknown[], R, E>(
  effect: (...args: [...P1, ...P2]) => Effect.Effect<never, E, R>,
  returnUrl?: string,
  flashOptions?: FlashNotificationOptions<E>,
  ...args: P1
): [boolean, (...args: P2) => Effect.Effect<never, E, R>, Option.Option<E>, boolean] {
  const navigate = useNavigate();

  const [loading, send, error, success] = useSendTask(effect, flashOptions, ...args);

  const handleSend = useCallback(
    (...args2: P2) =>
      pipe(
        send(...args2),
        Effect.tap(() =>
          Effect.sync(() => {
            if (returnUrl) {
              navigate(returnUrl, { replace: true, state: { ignorePrevent: true } });
            }
          }),
        ),
      ),
    [navigate, send, returnUrl],
  );

  return [loading, handleSend, error, success];
}
