import React, { FC, useEffect } from 'react';
import { defineLoader, useLoader, useParentLoader } from '@core/router/loader';
import { Outlet, resolvePath } from 'react-router-dom';
import { defineRoute, redirect } from '@core/router';
import { ProfileService } from '@modules/profile/service';
import { createPath } from 'history';
import { AuthUtils } from '@modules/auth/utils';
import i18next from '@core/translations';
import { DatesProvider } from '@mantine/dates';
import { MatomoService } from '@shared/modules/analytics';
import { Effect, pipe } from 'effect';
import { HttpStatusCode } from 'axios';

const LOADER_ID = 'profile-loader';

const profileLoader = defineLoader({
  id: LOADER_ID,
  handler: ({ request }) => {
    return pipe(
      ProfileService.getProfile(),
      Effect.tap(profile => Effect.promise(() => i18next.changeLanguage(profile.language))),
      Effect.matchEffect({
        onSuccess: Effect.succeed,
        onFailure: err => {
          if (HttpStatusCode.Unauthorized === err.status) {
            return redirect(createPath(resolvePath(AuthUtils.createLoginLink(true))));
          }

          return err.throwResponse();
        },
      }),
    );
  },
});

const ProfileLoaderContent: FC = () => {
  const profile = useLoader<typeof profileLoader>();

  useEffect(() => {
    Effect.runSync(MatomoService.setAccountTypeDimension(profile.accountType));
  }, [profile.accountType]);

  return (
    <DatesProvider settings={{ locale: profile.language }}>
      <Outlet />
    </DatesProvider>
  );
};

const profileLoaderRoute = defineRoute({
  ns: [],
  loader: profileLoader,
  element: <ProfileLoaderContent />,
  shouldRevalidate: ({ currentUrl, nextUrl, formMethod }) => {
    return (
      currentUrl.pathname + currentUrl.search === nextUrl.pathname + nextUrl.search ||
      formMethod?.toUpperCase() === 'POST'
    );
  },
});

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>(LOADER_ID);
}

export default profileLoaderRoute;
