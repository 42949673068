import Nav from '@layout/nav/Nav';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import * as Styled from './Layout.styles';
import { IconArrowBarToRight } from '@tabler/icons-react';
import { useClickOutside, useLocalStorage, useMediaQuery } from '@mantine/hooks';
import { useTheme } from '@emotion/react';
import MobileHeader from '@layout/header/MobileHeader';
import { LayoutConstants } from '@layout/constants';

const Layout: FC = () => {
  const theme = useTheme();

  const location = useLocation();

  const isMobileLayout = useMediaQuery(
    `(max-width: ${theme.breakpoints[LayoutConstants.LAYOUT_MOBILE_BREAKPOINT]})`,
    undefined,
    { getInitialValueInEffect: false },
  );

  const isMobileLayoutRef = useRef(isMobileLayout);
  isMobileLayoutRef.current = isMobileLayout;

  const [navOpen, setNavOpen] = useLocalStorage({
    key: 'nav-open',
    defaultValue: !isMobileLayout,
    getInitialValueInEffect: false,
  });

  const navRef = useRef<HTMLElement | null>(null);
  const [mobileToggleNavRef, setMobileToggleNavRef] = useState<HTMLElement | null>(null);

  const closeNavOnMobile = useCallback(() => {
    if (isMobileLayoutRef.current) {
      setNavOpen(false);
    }
  }, [setNavOpen]);

  useClickOutside(closeNavOnMobile, null, [mobileToggleNavRef, navRef.current]);

  useEffect(() => {
    closeNavOnMobile();
  }, [closeNavOnMobile, location.pathname]);

  const handleToggleNav = () => {
    setNavOpen(old => !old);
  };

  return (
    <Styled.LayoutContainer
      navbar={<Nav ref={navRef} open={navOpen} isMobileLayout={isMobileLayout} />}
      header={<MobileHeader navOpen={navOpen} toggleNav={handleToggleNav} toggleNavRef={setMobileToggleNavRef} />}
      padding={0}
      $navOpen={navOpen}
    >
      <Styled.LayoutToggleNavButton
        size={34}
        color="dark.3"
        variant="outline"
        radius="xl"
        onClick={handleToggleNav}
        $navOpen={navOpen}
      >
        <IconArrowBarToRight size={18} />
      </Styled.LayoutToggleNavButton>

      <Outlet />
    </Styled.LayoutContainer>
  );
};

export default Layout;
