import { filterEmptyStringToOption } from '@shared/utils/string';
import { User } from '@modules/users/model';
import { pipe, Option } from 'effect';
import { Profile } from '@modules/profile/model';
import { ProfilePredicates } from '@modules/profile/predicates';
import { Role } from '@modules/roles/model';

export namespace UsersUtils {
  type UserNames = Pick<User, 'email' | 'firstName' | 'lastName'>;

  export function getUserInitial({ email, firstName, lastName }: UserNames) {
    return pipe(
      Option.all({
        firstName: filterEmptyStringToOption(firstName),
        lastName: filterEmptyStringToOption(lastName),
      }),
      Option.match({
        onNone: () => email.charAt(0),
        onSome: ({ firstName, lastName }) => `${firstName.charAt(0)}${lastName.charAt(0)}`,
      }),
    );
  }

  export function getUserName({ email, firstName, lastName }: UserNames) {
    return pipe(
      Option.all({
        firstname: filterEmptyStringToOption(firstName),
        lastname: filterEmptyStringToOption(lastName),
      }),
      Option.match({
        onNone: () => email,
        onSome: ({ firstname, lastname }) => `${firstname} ${lastname}`,
      }),
    );
  }

  export function mapUserToParams(user: User): User.Params {
    return {
      accountType: user.account.type,
      accountId: user.account.id,
      lastName: user.lastName ?? '',
      firstName: user.firstName ?? '',
      email: user.email,
      postalAddress: user.postalAddress,
      phoneNumber: user.phoneNumber ?? '',
      language: user.language,
      zoneId: user.zoneId,
      roleId: user.role.id,
    };
  }

  export function canEditMyUsers(profile: Profile, user: User) {
    if (profile.role.isAdmin) return true;

    if (ProfilePredicates.canEdit(Role.AccessKey.MyUsers)) return profile.role.id === user.role.id;

    return false;
  }
}
