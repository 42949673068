import React from 'react';
import profilePageRoute from '@modules/profile/ProfilePage';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import savRedirectRoute from '@modules/profile/SAVRedirectPage';

const profileRoutes = <Route path="profile" {...createRoute(profilePageRoute)} />;

export const savRedirectRoutes = <Route path="sav-redirect" {...createRoute(savRedirectRoute)} />;

export default profileRoutes;
