import React, { FC, PropsWithChildren } from 'react';
import { Box, Button, Group, Modal, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface FormPreventLeaveModalProps {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
}

const FormPreventLeaveModal: FC<PropsWithChildren<FormPreventLeaveModalProps>> = ({ open, onClose, onLeave }) => {
  const { t } = useTranslation('common');

  return (
    <Modal opened={open} onClose={onClose} centered size={500} withCloseButton={false}>
      <Box p={10}>
        <Title size="h2" fw={700} c="dark.5">
          {t('modals.prevent-leave.title')}
        </Title>

        <Text mt="lg">{t('modals.prevent-leave.content')}</Text>

        <Group mt="xl">
          <Button variant="light" onClick={onLeave}>
            {t('modals.prevent-leave.leave')}
          </Button>
          <Button onClick={onClose}>{t('modals.prevent-leave.continue')}</Button>
        </Group>
      </Box>
    </Modal>
  );
};

export default FormPreventLeaveModal;
