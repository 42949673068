import React, { FC } from 'react';
import { Profile } from '@modules/profile/model';
import { Badge, Group, Paper, Title, Text } from '@mantine/core';
import { IconBuilding } from '@tabler/icons-react';
import { Role } from '@modules/roles/model';
import { useTranslation } from 'react-i18next';

interface ProfileInfosProps {
  profile: Profile;
}

const ProfileInfos: FC<ProfileInfosProps> = ({ profile }) => {
  const { t } = useTranslation('roles');

  return (
    <Paper mx={20} p={20} withBorder={false} w="max-content">
      <Title order={3}>
        {profile.firstName} {profile.lastName}
      </Title>

      <Group pt={20}>
        <Badge variant="outline" color="gray.9">
          {Role.roleAccountTypeLabels[profile.accountType]?.(t)}
        </Badge>

        <Group spacing={10}>
          <IconBuilding size={14} />

          <Text size={12} fw={400}>
            {profile.accountName}
          </Text>
        </Group>
      </Group>
    </Paper>
  );
};

export default ProfileInfos;
