import { SelectItem } from '@mantine/core';
import { Translations } from '@core/translations';
import { TFunction } from 'i18next';
import { Option, Order, pipe } from 'effect';

export function getDropdownOptionsFromEnumLabels<E extends string>(labels: Record<E, string>): Array<SelectItem> {
  return Object.keys(labels).map(value => ({ value, label: labels[value as E] }));
}

export function getDropdownOptionsFromTranslatedEnumLabels<E extends string>(
  labels: Translations.TranslatedEnumLabel<E>,
  t: TFunction,
): Array<SelectItem> {
  return Object.keys(labels).map(value => ({ value, label: labels[value as E](t) }));
}

export function getEnumOrder<E extends string>(enumeration: Record<string, E>): Order.Order<E> {
  return Order.make<E>((a, b) => {
    const values = Object.values(enumeration);

    const result = values.indexOf(a) - values.indexOf(b);

    if (result === 0) {
      return 0;
    } else if (result < 0) {
      return -1;
    } else {
      return 1;
    }
  });
}

export function getEnumValueFromDropdownProps<E>({ value }: SelectItem, enumeration: Record<string, E>): E | null {
  return pipe(
    Option.fromNullable(value),
    Option.filterMap(value =>
      typeof value === 'string' && Object.values(enumeration).includes(value as unknown as E)
        ? Option.some(value as unknown as E)
        : Option.none(),
    ),
    Option.getOrNull,
  );
}

export function numberEnumToArray<E extends number>(e: Record<E, number | string>): Array<E> {
  return Object.keys(e)
    .map(x => parseInt(x))
    .filter(x => !isNaN(x)) as unknown as Array<E>;
}
