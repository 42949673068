import React, { FC, useMemo } from 'react';
import { Notification } from '@modules/notifications/model';
import { Badge, Box, Card, Text } from '@mantine/core';
import { useNotificationDisplay } from '@modules/notifications/hooks';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { Link } from 'react-router-dom';
import { DateFormat, getDateFnsLocale, parseDate } from '@shared/modules/dates';
import { formatRelative } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { pipe, Option } from 'effect';

interface NotificationRowProps {
  notification: Notification;
}

const NotificationRow: FC<NotificationRowProps> = ({ notification }) => {
  const { i18n } = useTranslation();

  const display = useNotificationDisplay(notification);

  const date = useMemo(
    () =>
      pipe(
        parseDate(notification.date, DateFormat.LocalDateTime),
        Option.map(date => formatRelative(date, new Date(), { locale: getDateFnsLocale(i18n.language) })),
      ),
    [i18n.language, notification.date],
  );

  return (
    <Box pb={10}>
      {renderNullable(display, ({ type, text, to }) => (
        <Card
          component={Link}
          to={to}
          py={10}
          pr={15}
          pl={notification.seenAt ? 15 : 25}
          withBorder={false}
          radius={12}
          shadow="0 0 20px 0 rgba(0, 0, 0, 0.04)"
          sx={({ colors }) => ({
            background: notification.seenAt
              ? colors.background[0]
              : `linear-gradient(to right, ${colors.lime[9]} 6px, ${colors.background[0]} 6px);`,
          })}
        >
          <Badge size="sm" tt="none" bg="gray.1" c="gray.7" fw={600}>
            {type}
          </Badge>

          <Text my={5} size="sm" fw={500} sx={{ whiteSpace: 'pre-wrap' }}>
            {text}
          </Text>

          {renderOptional(date, date => (
            <Text size="xs" color="background.9" sx={{ '&:first-letter': { textTransform: 'uppercase' } }}>
              {date}
            </Text>
          ))}
        </Card>
      ))}
    </Box>
  );
};

export default NotificationRow;
