import { EffectCache } from '@core/fp';
import { HttpEffect, httpService } from '@core/http';
import { Profile, SAV } from '@modules/profile/model';
import { BucketResource } from '@shared/modules/bucket/model';
import { downloadUrl } from '@shared/utils/download';
import { Effect, Option, pipe } from 'effect';
import { HttpStatusCode } from 'axios';

export namespace ProfileService {
  const URI = `/profile`;

  const getProfileCache = new EffectCache(httpService.get<Profile>(URI));

  export function getProfile(): HttpEffect<Profile> {
    return getProfileCache.getNonCached();
  }

  export function getProfileCached(): HttpEffect<Profile> {
    return getProfileCache.get();
  }

  export function updateProfile(profile: Profile.UpdateProfileParams): HttpEffect {
    return httpService.post(URI, profile);
  }

  export function getTos(): HttpEffect<Option.Option<BucketResource.Light>> {
    return pipe(
      httpService.get<BucketResource.Light>(`${URI}/tos`),
      Effect.matchEffect({
        onSuccess: tos => Effect.succeed(Option.some(tos)),
        onFailure: error =>
          error.status === HttpStatusCode.NotFound ? Effect.succeed(Option.none()) : Effect.fail(error),
      }),
    );
  }

  export function downloadTos(): HttpEffect {
    return pipe(
      getTos(),
      Effect.flatMap(
        Option.match({
          onSome: tos => downloadUrl(tos.originalUrl),
          onNone: () => Effect.unit,
        }),
      ),
    );
  }

  export function acceptTos(): HttpEffect<Profile> {
    return httpService.post(`${URI}/tos/accept`);
  }

  export function getSAVLoginInformations(): HttpEffect<SAV.LoginInformations> {
    return httpService.get(`${URI}/sav/login`);
  }
}
