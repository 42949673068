import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const parcelsSharingRoutes = (
  <Route path="parcels-sharing" {...createLazyRoute(module, m => m.parcelsSharingLayoutRoute)}>
    <Route index {...createLazyRoute(module, m => m.parcelsSharingListRoute)} />
    <Route path="emails" {...createLazyRoute(module, m => m.parcelsSharingEmailsRoute)} />
  </Route>
);

export default parcelsSharingRoutes;
