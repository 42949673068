import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import '@core/translations';

import { SentryUtils } from '@shared/modules/sentry/utils';
import { MatomoService } from '@shared/modules/analytics';

SentryUtils.init();
MatomoService.init();

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(<App />);
}
