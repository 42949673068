import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HttpError } from '@core/http';
import { filterEmptyStringToOption } from '@shared/utils/string';
import config from '@root/config';
import { Effect, Option, pipe } from 'effect';

export namespace SentryUtils {
  type Extras = Record<string, unknown>;

  export function init() {
    if (import.meta.env.PROD) {
      pipe(
        Option.all({
          dsn: filterEmptyStringToOption(config.VITE_SENTRY_DSN),
          environment: filterEmptyStringToOption(config.VITE_ENVIRONMENT),
          release: filterEmptyStringToOption(config.VITE_RELEASE),
        }),
        Effect.flatMap(options =>
          Effect.sync(() => {
            Sentry.init({
              ...options,
              integrations: [new Integrations.BrowserTracing()],
              tracesSampleRate: 1.0,
              attachStacktrace: true,
            });
          }),
        ),
        Effect.orElse(() => Effect.logInfo('Invalid sentry config')),
        Effect.runSync,
      );
    }
  }

  export function logMessage(message: string, severity: Sentry.SeverityLevel = 'error', extra?: Extras) {
    return Effect.sync(() =>
      Sentry.captureMessage(message, {
        level: severity,
        extra,
      }),
    );
  }

  export function logHttpError(
    error: HttpError,
    severity: Sentry.SeverityLevel = 'error',
    message = `[http] error ${error.status} on ${error.url} path`,
    extra?: Extras,
  ) {
    return logMessage(message, severity, {
      ...error.toJson(),
      ...extra,
    });
  }
}
