import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Filter } from '@shared/modules/filter';
import { Range, RangeCursor, RangeResult } from './model';
import { useFetcher, useLocation, useNavigate } from 'react-router-dom';
import { QueryUtils } from '@shared/utils/queries';
import { LoaderReturnType, useLoader } from '@core/router/loader';
import PQueue from 'p-queue';
import { HttpRange } from '@core/http';
import { useSendTask } from '@core/http/hooks';
import { Effect, pipe } from 'effect';

export interface UseRangeReturn<R, F extends Filter = {}> {
  range: Range<R, F>;
  handleLoadPage: (page: number) => void;
  handleFilter: (filter: Partial<F>) => void;
  handleSort: (sort: string | null) => void;
  handleRefreshIndex: (index: number) => void;
  setRange: Dispatch<SetStateAction<Range<R, F>>>;
}

/**
 * Hooks permettant d'utilisation un range à partir d'un loader
 * Il prend un mapper en paramètres pour mapper les données du loader en un RangeResult. La plupart du temps on pourra utiliser `identity` de fp-ts
 *
 * @param mapper - selecteur des données du range.
 */
export function useRange<
  Loader,
  R,
  F extends Filter = {},
  Mapper extends (data: LoaderReturnType<Loader>) => RangeResult<R, F> = (
    data: LoaderReturnType<Loader>,
  ) => RangeResult<R, F>,
>(mapper: Mapper): UseRangeReturn<R, F> {
  const initialData = useLoader<Loader>();

  const mapperRef = useRef(mapper);

  mapperRef.current = mapper;

  const initialRange = useMemo(() => mapperRef.current(initialData), [initialData]);

  const location = useLocation();
  const navigate = useNavigate();

  const fetcher = useFetcher();
  const fetcherRef = useRef(fetcher);
  fetcherRef.current = fetcher;

  const [range, setRange] = useState<Range<R, F>>(() => Range.fromRangeResult(initialRange));

  const rangeRef = useRef(range);
  rangeRef.current = range;

  const queue = useMemo(() => new PQueue({ concurrency: 1 }), []);
  const queuePriority = useRef(0);

  /**
   * Synchronisation avec les données du loader et écrase les anciennes données
   * Les données du loader changent quand les queries changes
   */
  useEffect(() => {
    setRange(Range.fromRangeResult(initialRange));
  }, [initialRange]);

  /**
   * Chargement d'une nouvelle page
   *
   * On utilise alors le fetcher avec en paramètres les nouvelles queries. La query page est alors cachée à l'utilisateur
   */
  const handleLoadPage = useCallback(
    (page: number, force: boolean = false) => {
      const task = () => {
        const range = rangeRef.current;

        if (force || !range.has(RangeCursor.fromPage(page).startIndex)) {
          fetcherRef.current.submit(
            new URLSearchParams(QueryUtils.stringify({ ...range.filter, sort: range.sort, page })),
          );

          return new Promise(resolve => {
            let interval: number;

            let hasRun = false;

            /**
             * Check toutes les 5 ms si l'état du fetcher change
             */
            const waitForResult = () => {
              if (!hasRun) {
                hasRun = fetcherRef.current.state !== 'idle';
              } else if (fetcherRef.current.state === 'idle') {
                setRange(old => old.merge(Range.fromRangeResult(mapperRef.current(fetcherRef.current.data))));

                rangeRef.current = rangeRef.current.merge(
                  Range.fromRangeResult(mapperRef.current(fetcherRef.current.data)),
                );

                resolve(true);

                clearInterval(interval);
              }
            };

            interval = setInterval(waitForResult, 5);

            waitForResult();
          });
        }

        return new Promise(resolve => resolve(false));
      };

      const priority = queuePriority.current + 1;
      queuePriority.current = priority;

      queue.add(task, { priority });
    },
    [queue],
  );

  /**
   * Rafraichi la page concerné par l'index
   */
  const handleRefreshIndex = useCallback(
    (index: number) => {
      handleLoadPage(RangeCursor.fromIndex(index).toPage(), true);
    },
    [handleLoadPage],
  );

  /**
   * Modifie les queries de l'utilisateur pour relancer le fetcher
   */
  const handleFilter = useCallback(
    (newFilter: Partial<F>) => {
      const filter = {
        ...range.filter,
        ...newFilter,
      };

      navigate(
        {
          ...location,
          search: QueryUtils.stringify({ ...filter, sort: range.sort }),
        },
        { replace: true },
      );

      setRange(old => old.setFilter(filter));
    },
    [location, navigate, range.filter, range.sort],
  );

  const handleSort = useCallback(
    (sort: string | null) =>
      navigate(
        {
          ...location,
          search: QueryUtils.stringify({ ...range.filter, sort }),
        },
        { replace: true },
      ),
    [location, navigate, range.filter],
  );

  return {
    range,
    handleLoadPage,
    handleFilter,
    handleSort,
    handleRefreshIndex,
    setRange,
  };
}

export function useStandaloneRange<R>(task: (page: number) => HttpRange<R>) {
  const [loading, setLoading] = useState<boolean>(true);

  const [range, setRange] = useState<Range<R>>(() => Range.fromArray([], {}));

  const rangeRef = useRef(range);
  rangeRef.current = range;

  const queue = useMemo(() => new PQueue({ concurrency: 1 }), []);

  const [, loadPage] = useSendTask(task);

  const handleLoadPage = useCallback(
    (page: number) => {
      if (!rangeRef.current.has(RangeCursor.fromPage(page).startIndex)) {
        setLoading(true);

        const task = pipe(
          loadPage(page),
          Effect.tap(result =>
            Effect.sync(() => {
              const newRange = Range.fromRangeResult(result);

              rangeRef.current = rangeRef.current.merge(newRange);

              setRange(old => old.merge(newRange));
            }),
          ),
          Effect.onExit(() => Effect.sync(() => setLoading(false))),
        );

        queue.add(() => Effect.runPromise(task));
      }
    },
    [loadPage, queue],
  );

  useEffect(() => {
    handleLoadPage(1);
  }, [handleLoadPage]);

  return {
    range,
    loading,
    handleLoadPage,
    setRange,
  };
}
