import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');
const issuesModule = () => import('@modules/issues/module');

const sensorsRoutes = (
  <Route path="sensors">
    <Route index {...createLazyRoute(module, m => m.sensorsListRoute)} />
    <Route path="new" {...createLazyRoute(module, m => m.createSensorRoute)} />

    <Route path=":id" {...createLazyRoute(module, m => m.sensorDetailLayoutRoute, 'sensor-detail')}>
      <Route index {...createLazyRoute(module, m => m.sensorDetailInformationRoute)} />
      <Route path="data" {...createLazyRoute(module, m => m.sensorDetailDataRoute)} />
      <Route path="issues" {...createLazyRoute(module, m => m.sensorDetailIssuesRoute)}>
        <Route path=":issueId" {...createLazyRoute(issuesModule, m => m.issueDetailRoute)} />
      </Route>
      <Route path="tickets" {...createLazyRoute(module, m => m.sensorDetailTicketsRoute)} />
      {/*      <Route path="settings" {...createLazyRoute(module, m => m.sensorDetailSettingsRoute, 'sensor-settings')}>
        <Route path="update" {...createLazyRoute(module, m => m.sensorDetailSettingsUpdateRoute)} />
      </Route>*/}
    </Route>
  </Route>
);

export default sensorsRoutes;
