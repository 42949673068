import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const favouritesRoutes = (
  <Route path="favourites">
    <Route index {...createLazyRoute(module, m => m.favouritesListRoute)} />
  </Route>
);

export const favouritesFullPageRoutes = (
  <Route path="favourites">
    <Route path="new" {...createLazyRoute(module, m => m.createFavouriteLayout)}>
      <Route index {...createLazyRoute(module, m => m.favouritePostalZonesRoute)} />
      <Route path="accounts" {...createLazyRoute(module, m => m.favouriteAccountsRoute)} />
      <Route path="advanced" {...createLazyRoute(module, m => m.createFavouriteAdvancedRoute)} />
    </Route>

    <Route path=":id" {...createLazyRoute(module, m => m.favouriteDetailLayout)}>
      <Route index {...createLazyRoute(module, m => m.favouritePostalZonesRoute)} />
      <Route path="accounts" {...createLazyRoute(module, m => m.favouriteAccountsRoute)} />
      <Route path="advanced" {...createLazyRoute(module, m => m.favouriteDetailAdvancedRoute)} />
    </Route>
  </Route>
);

export default favouritesRoutes;
