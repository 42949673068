import { defaultAxiosInstance } from '@core/http/config';
import { HttpError, HttpRange, HttpEffect } from '@core/http/model';
import { Filter } from '@shared/modules/filter';
import { NestedRangeResult, RangeCursor, RangeResult } from '@shared/modules/range';
import { removeEmptyString } from '@shared/utils/string';
import { AxiosRequestConfig, AxiosResponse, HttpStatusCode } from 'axios';
import { hideApiDownIndicator, showApiDownIndicator } from '@core/http/components/ApiDownIndicator';
import { OAuthService } from '@core/oauth/service';
import { SentryUtils } from '@shared/modules/sentry/utils';
import { Effect, pipe, Schedule } from 'effect';

function sendRequest<R, E>(
  request: (signal: AbortSignal) => Promise<AxiosResponse<R>>,
  raw?: true,
): HttpEffect<R | AxiosResponse<R>, E> {
  const onError = (err: unknown): HttpError<E> => HttpError.fromAxiosError<E>(err as any);

  const retryPolicy = pipe(
    Schedule.union(Schedule.exponential('500 millis'), Schedule.spaced('2 seconds')),
    Schedule.whileInput<HttpError>(err => err.isDownError()),
    Schedule.tapOutput(([, occur]) => {
      if (occur === 1) {
        return showApiDownIndicator;
      } else {
        return Effect.unit;
      }
    }),
  );

  return pipe(
    Effect.tryPromise({
      try: request,
      catch: onError,
    }),
    Effect.retry(retryPolicy),
    Effect.tap(() => hideApiDownIndicator),
    Effect.matchEffect({
      onSuccess: res => Effect.succeed(raw ? res : res.data),
      onFailure: err => {
        if (err.status === HttpStatusCode.Unauthorized) {
          return pipe(
            OAuthService.refreshToken(),
            Effect.mapError(() => err),
            Effect.flatMap(() => sendRequest<R, E>(request, raw)),
          );
        }

        return Effect.fail(err);
      },
    }),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpEffect<R, E>;
function get<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpEffect<AxiosResponse<R>, E>;

function get<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpEffect<R | AxiosResponse<R>, E> {
  return sendRequest(signal => defaultAxiosInstance.get(url, { ...config, signal }), raw);
}

function getRangeFromCursor<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  cursor: RangeCursor,
  filter: F,
  sort: string | null,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    get<RangeResult<R, F>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...cursor,
        ...filter,
        sort,
      },
    }),
    Effect.map(res => ({
      ...res,
      filter,
      sort,
    })),
  );
}

function getRange<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  page: number,
  filter: F,
  sort: string | null,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return getRangeFromCursor(url, RangeCursor.fromPage(page), filter, sort, config);
}

function getFullRange<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  max: number,
  filter: F,
  sort: string | null,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    getRangeFromCursor<R, F, E>(url, new RangeCursor(0, max - 1), filter, sort, config),
    Effect.tap(res => {
      if (res.total > max) {
        return SentryUtils.logMessage(`[getFullRange] Total range is larger than ${max} (${res.total}) on api ${url}`);
      }

      return Effect.unit;
    }),
    Effect.map(res => ({
      ...res,
      total: Math.min(res.total, max),
    })),
  );
}

function getNestedRange<Key extends string, F extends Filter, R extends NestedRangeResult<Key, any, F>, E = unknown>(
  url: string,
  key: Key,
  page: number,
  filter: F,
  sort: string | null,
  config?: AxiosRequestConfig,
): HttpEffect<R, E> {
  return pipe(
    get<R, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...filter,
        ...RangeCursor.fromPage(page),
        sort,
      },
    }),
    Effect.map(res => ({
      ...res,
      [key]: {
        ...res[key],
        filter,
        sort,
      },
    })),
  );
}

function getFullNestedRange<
  Key extends string,
  F extends Filter,
  R extends NestedRangeResult<Key, any, F>,
  E = unknown,
>(url: string, key: Key, max: number, filter: F, sort: string | null, config?: AxiosRequestConfig): HttpEffect<R, E> {
  return pipe(
    get<R, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...filter,
        ...new RangeCursor(0, max - 1),
        sort,
      },
    }),
    Effect.tap(res => {
      if (res[key].total > max) {
        return SentryUtils.logMessage(
          `[getFullNestedRange] Total range is larger than ${max} (${res[key].total}) on api ${url}`,
        );
      }

      return Effect.unit;
    }),
    Effect.map(res => ({
      ...res,
      [key]: {
        ...res[key],
        total: Math.min(res[key].total, max),
        filter,
        sort,
      },
    })),
  );
}

function removeEmptyStringOnBody(body?: any) {
  if (!(body instanceof FormData)) {
    return removeEmptyString(body);
  }

  return body;
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpEffect<R, E>;
function post<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpEffect<AxiosResponse<R>, E>;

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpEffect<R | AxiosResponse<R>, E> {
  return sendRequest(
    signal => defaultAxiosInstance.post(url, removeEmptyStringOnBody(data), { ...config, signal }),
    raw,
  );
}

function put<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpEffect<R, E>;
function put<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpEffect<AxiosResponse<R>, E>;

function put<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpEffect<R | AxiosResponse<R>, E> {
  return sendRequest(
    signal => defaultAxiosInstance.put(url, removeEmptyStringOnBody(data), { ...config, signal }),
    raw,
  );
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpEffect<R, E>;
function del<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpEffect<AxiosResponse<R>, E>;

function del<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpEffect<R | AxiosResponse<R>, E> {
  return sendRequest(signal => defaultAxiosInstance.delete(url, { ...config, signal }), raw);
}

export const httpService = {
  get,
  getRange,
  getRangeFromCursor,
  getFullRange,
  getNestedRange,
  getFullNestedRange,
  post,
  put,
  delete: del,
};
