import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const parcelsRoutes = (
  <Route path="parcels">
    <Route index {...createLazyRoute(module, m => m.parcelsListIndexRoute)} />

    <Route {...createLazyRoute(module, m => m.parcelsListLayoutRoute)}>
      <Route path="list" {...createLazyRoute(module, m => m.parcelsListRoute)} />
      <Route path="map" {...createLazyRoute(module, m => m.parcelsMapRoute)}>
        <Route path=":id" {...createLazyRoute(module, m => m.parcelsMapDetailRoute)} />
      </Route>
    </Route>

    <Route path=":id" {...createLazyRoute(module, m => m.parcelDetailLayoutRoute, 'parcel-detail')}>
      <Route index {...createLazyRoute(module, m => m.parcelDetailSynthesisRoute)} />
      <Route path="info" {...createLazyRoute(module, m => m.parcelDetailInfoRoute)} />
      <Route path="crops/:cropId?" {...createLazyRoute(module, m => m.parcelDetailCropRoute)} />
      <Route path="settings" {...createLazyRoute(module, m => m.parcelDetailSettingsRoute, 'parcel-settings')}>
        <Route path="update" {...createLazyRoute(module, m => m.parcelDetailSettingsUpdateRoute)} />
      </Route>
    </Route>

    <Route path=":id/crops/new" {...createLazyRoute(module, m => m.parcelDetailCreateCropRoute)} />
  </Route>
);

export const parcelsFullPageRoutes = (
  <Route path="parcels">
    <Route path="new" {...createLazyRoute(module, m => m.createParcelLayout)}>
      <Route index {...createLazyRoute(module, m => m.createParcelInfoRoute)} />
      <Route path="crop" {...createLazyRoute(module, m => m.createParcelCropRoute)} />
      <Route path="sensor" {...createLazyRoute(module, m => m.createParcelSensorRoute)} />
    </Route>

    <Route path=":id/weather-forecast" {...createLazyRoute(module, m => m.detailWeatherForecastRoute)} />

    <Route path=":id/histo" {...createLazyRoute(module, m => m.histoLoaderRoute, 'histo-loader')}>
      <Route path="count" {...createLazyRoute(module, m => m.histoCountRoute)} />
      <Route path="risk" {...createLazyRoute(module, m => m.histRiskRoute)} />
      <Route path="weather" {...createLazyRoute(module, m => m.histoWeatherPage)} />
    </Route>
  </Route>
);

export default parcelsRoutes;
