import { z } from 'zod';
import { nonEmptyStringSchema } from '@shared/schemas';
import { Translations } from '@core/translations';
import { Role } from '@modules/roles/model';
import { Account } from '@modules/account/model';
import { Address } from '@shared/modules/address/model';
import { ZoneId } from '@shared/modules/referentials/model';
import { PhoneNumber } from '@shared/modules/phone-number/model';
import { Countries } from '@shared/model';
import { Pest } from '@shared/modules/pest/model';
import { User } from '@modules/users/model';

export namespace Profile {
  export const UpdateProfileParams = z.object({
    firstName: nonEmptyStringSchema,
    lastName: nonEmptyStringSchema,
    phoneNumber: PhoneNumber,
    postalAddress: Address,
    language: z.nativeEnum(Translations.Language),
    zoneId: ZoneId,
  });
  export type UpdateProfileParams = z.infer<typeof UpdateProfileParams>;

  export interface Counter {
    parcelMissingInfo: number;
    unreadNotifications: number;
  }

  export interface InternationalizationSettings {
    country: Countries;
    pests: Array<Pest>;
    noDecisionTree: boolean;
  }
}

export interface Profile {
  id: User.Id;
  email: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  language: Translations.Language;
  accountId: Account.Id;
  accountType: Role.AccountType;
  accountName: string;
  postalAddress: Address;
  zoneId: ZoneId;
  role: Role.Detail;
  areTosAccepted: boolean;
  tosAcceptanceDate: string | null;
  counter: Profile.Counter;
  hasSubscriptionsToRenew: boolean;
  favouriteOnboardingDone: boolean;
  internationalizationSettings: Profile.InternationalizationSettings;
  hasRentContracts: boolean;
}

export namespace SAV {
  export const LimacaptSAVToken = z.string().brand<'LimacaptSAVToken'>();
  export type LimacaptSAVToken = z.infer<typeof LimacaptSAVToken>;
  export interface LoginInformations {
    token: LimacaptSAVToken;
  }
}
