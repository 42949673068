import { defineRoute } from '@core/router';
import { defineLoader } from '@core/router/loader';
import { Effect } from 'effect';
import { QueryUtils } from '@shared/utils/queries';
import { ProfileService } from '@modules/profile/service';
import queryString from 'query-string';
import config from '@root/config';

const loader = defineLoader({
  handler: ({ request }) =>
    Effect.gen(function* (_) {
      const queries = yield* _(QueryUtils.parseFromRequest(request));
      const referrer = yield* _(QueryUtils.getStringQuery(queries, 'referrer'));

      const { token } = yield* _(ProfileService.getSAVLoginInformations());

      yield* _(
        Effect.sync(() =>
          window.location.replace(
            queryString.stringifyUrl({
              url: new URL(`/sign-in/limacapt/${token}`, config.VITE_SAV_LINK).toString(),
              query: { referrer },
            }),
          ),
        ),
      );

      yield* _(Effect.delay(10000)(Effect.unit));
    }),
});

const savRedirectRoute = defineRoute({
  ns: [],
  loader,
});

export default savRedirectRoute;
