import React, { FC } from 'react';
import { ActionIcon, Indicator } from '@mantine/core';
import { IconBellRinging } from '@tabler/icons-react';
import { useHashDisclosure } from '@core/router';
import { useProfile } from '@modules/profile/loader';
import { NOTIFICATIONS_DRAWER_HASH } from '@modules/notifications/components/NotificationsDrawer';

const NotificationsCounter: FC = () => {
  const profile = useProfile();

  const [, drawerTriggers] = useHashDisclosure(NOTIFICATIONS_DRAWER_HASH);

  return (
    <>
      <Indicator color="red" withBorder offset={10} disabled={profile.counter.unreadNotifications === 0}>
        <ActionIcon size={36} c="background.9" radius={10} onClick={drawerTriggers.open}>
          <IconBellRinging size={20} />
        </ActionIcon>
      </Indicator>
    </>
  );
};

export default NotificationsCounter;
