import z from 'zod';
import { LoaderFunctionArgs } from 'react-router-dom';
import { Loader } from './index';
import { HttpError } from '@core/http';
import { parseParams } from '@core/router';
import { Namespace } from 'i18next';
import { TranslationsUtils } from '@core/translations';
import { Effect, Option, pipe } from 'effect';
import loadNamespace = TranslationsUtils.loadNamespace;

export function defineLoader<
  ParamsSchema extends z.ZodType = z.ZodType<unknown>,
  R = unknown,
  ID extends string = never,
>(loader: Loader<ParamsSchema, R, ID>): Loader<ParamsSchema, R, ID> {
  return loader;
}

export function loaderHandler<ParamsSchema extends z.ZodType = z.ZodType<unknown>, R = unknown>(
  ns: Namespace,
  loader?: Loader<ParamsSchema, R>,
): (args: LoaderFunctionArgs) => Promise<R> {
  return args => {
    const program = pipe(
      Option.fromNullable(loader),
      Option.match({
        onNone: () => Effect.succeed({} as R),
        onSome: loader =>
          pipe(
            parseParams(args.params, loader.params),
            Effect.flatMap(params => loader.handler({ request: args.request, params })),
          ),
      }),
    );

    return pipe(
      program,
      Effect.zip(loadNamespace(ns), { concurrent: true }),
      Effect.map(([res]) => res),
      Effect.orDie,
      Effect.catchSomeDefect(defect => {
        if (defect instanceof Response) {
          return Option.some(Effect.succeed(defect as R));
        }

        if (defect instanceof HttpError) {
          return Option.some(Effect.succeed(defect.toResponse() as R));
        }

        return Option.none();
      }),
      Effect.runPromise,
    ).then(res => {
      if (res instanceof Response) {
        throw res;
      }

      return res;
    });
  };
}
