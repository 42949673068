import { Grid, Select, TextInput } from '@mantine/core';
import { Control, Controller, FieldPath, FieldPathByValue, get, UseFormRegister, useFormState } from 'react-hook-form';
import { getDropdownOptionsFromTranslatedEnumLabels } from '@shared/utils/enum';
import { countriesLabel } from '@shared/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Address } from '@shared/modules/address/model';

interface AddressFormProps<TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, Address>> {
  name: TPath;
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues>;
}

function AddressForm<TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, Address>>({
  name,
  register: receivedRegister,
  control,
}: AddressFormProps<TFieldValues, TPath>) {
  const { t } = useTranslation('common');

  const { errors } = useFormState({ control, name });

  const getPath = (path: FieldPath<Address>) => `${name}.${path}` as FieldPath<TFieldValues>;

  const hasError = (path: FieldPath<Address>) => !!get(errors, getPath(path));

  const register = (path: FieldPath<Address>) => receivedRegister(getPath(path));

  return (
    <Grid gutter={15} maw={10000} py={20} px={0}>
      <Grid.Col sm={4} xs={12}>
        <TextInput
          label={t('address.first-line')}
          placeholder={t('address.first-line')}
          error={hasError('firstLine')}
          required
          {...register(`firstLine`)}
        />
      </Grid.Col>

      <Grid.Col sm={4} xs={12}>
        <TextInput
          label={t('address.second-line')}
          placeholder={t('address.second-line')}
          error={hasError('secondLine')}
          {...register('secondLine')}
        />
      </Grid.Col>

      <Grid.Col sm={4} xs={12}>
        <TextInput
          label={t('address.complement')}
          placeholder={t('address.complement')}
          error={hasError('complement')}
          {...register('complement')}
        />
      </Grid.Col>

      <Grid.Col sm={3} xs={6}>
        <TextInput
          label={t('address.postal-code')}
          placeholder={t('address.postal-code')}
          error={hasError('postalCode')}
          required
          {...register('postalCode')}
        />
      </Grid.Col>

      <Grid.Col sm={4} xs={6}>
        <TextInput
          label={t('address.city')}
          placeholder={t('address.city')}
          error={hasError('city')}
          required
          {...register('city')}
        />
      </Grid.Col>

      <Grid.Col sm={4} xs={12}>
        <Controller
          control={control}
          name={getPath('country')}
          render={({ field }) => (
            <Select
              label={t('address.country')}
              placeholder={t('address.country')}
              data={getDropdownOptionsFromTranslatedEnumLabels(countriesLabel, t)}
              required
              error={hasError('country')}
              searchable
              {...field}
            />
          )}
        />
      </Grid.Col>
    </Grid>
  );
}

export default AddressForm;
