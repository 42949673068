import { z } from 'zod';

export enum Environment {
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'production',
  DEMO = 'demo',
}

export const Config = z.object({
  VITE_RELEASE: z.string().nullish(),
  VITE_ENVIRONMENT: z.nativeEnum(Environment),
  VITE_API_PREFIX: z.string().min(1),
  VITE_API_OAUTH_PREFIX: z.string().min(1),
  VITE_API_URL: z.string().url(),
  VITE_APP_TITLE: z.string().min(1),
  VITE_SENTRY_DSN: z.string().url().nullish(),
  VITE_GOOGLE_KEY: z.string().min(1),
  VITE_RECAPTCHA_KEY: z.string().min(1),

  VITE_SAV_LINK: z.string().url(),

  VITE_MATOMO_TRACKER: z.string().url().nullish(),
  VITE_MATOMO_SCRIPT: z.string().url().nullish(),
  VITE_MATOMO_SITE_ID: z
    .string()
    .regex(/^[0-9]$/)
    .nullish(),

  VITE_DEMO_FILTER_FROM: z.string().brand<'LocalDate'>().nullish(),
  VITE_DEMO_FILTER_TO: z.string().brand<'LocalDate'>().nullish(),
});

export type Config = z.infer<typeof Config>;
