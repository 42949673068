import styled from '@emotion/styled';
import { ActionIcon, ActionIconProps, AppShell, createPolymorphicComponent, em } from '@mantine/core';
import { LayoutConstants } from '@layout/constants';
import { styledOptions } from '@styles/utils';

interface NavOpenProps {
  $navOpen: boolean;
}

export const LayoutContainer = styled(AppShell, styledOptions)<NavOpenProps>`
  .mantine-AppShell-main {
    display: flex;
    flex-direction: column;
    padding-left: ${props => em(props.$navOpen ? LayoutConstants.NAV_OPEN_WIDTH : LayoutConstants.NAV_CLOSE_WIDTH)};
    transition: padding-left 0.25s ease-in-out;

    ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
      padding-left: 0;
    }

    ${props => props.theme.fn.largerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
      padding-top: 0;
    }
  }
`;

export const LayoutToggleNavButton = createPolymorphicComponent<'button', ActionIconProps & NavOpenProps>(styled(
  ActionIcon,
  styledOptions,
)<NavOpenProps>`
  position: fixed;
  top: ${em(22)};
  left: ${props => em((props.$navOpen ? LayoutConstants.NAV_OPEN_WIDTH : LayoutConstants.NAV_CLOSE_WIDTH) - 18)};
  z-index: 10000;
  border-color: ${props => props.theme.colors.gray[1]};
  background: ${props => props.theme.colors.background[0]};
  transform: rotate(${props => (props.$navOpen ? '180deg' : '0')});
  transition:
    left 0.25s ease-in-out,
    transform 0.2s ease-in;

  &:hover {
    background: ${props => props.theme.colors.background[0]};
  }

  ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    display: none;
  }
`);
