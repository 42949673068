import { Account } from '@modules/account/model';
import { Profile } from '@modules/profile/model';
import { Role } from '@modules/roles/model';
import { ProfilePredicates } from '@modules/profile/predicates';

export namespace AccountUtils {
  export function getLink(account: Account.Light, profile: Profile) {
    if (
      account.type === Role.AccountType.Farmer &&
      ProfilePredicates.canAccess(Role.AccessKey.FarmerAccounts)(profile)
    ) {
      return `/farmers/${account.id}`;
    }

    if (
      account.type === Role.AccountType.Provider &&
      ProfilePredicates.canAccess(Role.AccessKey.ProviderAccounts)(profile)
    ) {
      return `/providers/${account.id}`;
    }

    return null;
  }
}
