import { em, MantineProvider, rem } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';
import { MantineThemeOverride } from '@mantine/core';

import 'dayjs/locale/fr.js';
import 'dayjs/locale/en.js';
import 'dayjs/locale/de.js';
import 'dayjs/locale/es.js';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import { IconCalendar, IconClock } from '@tabler/icons-react';
import { LocalizedDayJsFormat } from '@shared/modules/dates';

dayjs.extend(localizedFormat);

const theme: MantineThemeOverride = {
  fontFamily: "'Figtree', sans-serif",
  headings: {
    sizes: {
      h1: {
        fontSize: rem(26),
        fontWeight: 700,
      },
      h2: {
        fontSize: rem(24),
        fontWeight: 600,
      },
      h3: {
        fontSize: rem(21),
        fontWeight: 600,
      },
      h4: {
        fontSize: rem(18),
        fontWeight: 700,
      },
      h5: {
        fontSize: rem(16),
        fontWeight: 700,
      },
    },
  },
  primaryColor: 'lime',
  primaryShade: 9,
  colors: {
    lime: [
      '#ecf9d5',
      '#dcf7c9',
      '#d8f5a2',
      '#c0eb75',
      '#a9e34b',
      '#94d82d',
      '#82c91e',
      '#74b816',
      '#66a80f',
      '#4d8b3f',
    ],
    yellow: [
      '#fff9db',
      '#fff3bf',
      '#ffec99',
      '#f7e807',
      '#fad400',
      '#fcc419',
      '#fab005',
      '#f59f00',
      '#f08c00',
      '#e67700',
    ],
    background: [
      '#fff',
      '#f7f7f7',
      '#faf8f8',
      '#fdf9f4',
      '#f7efe5',
      '#f3efed',
      '#d7d4d3',
      '#cec7c4',
      '#c2b3af',
      '#908886',
    ],
  },
  components: {
    Button: {
      defaultProps: {
        radius: 'lg',
        size: 'md',
      },
    },
    Anchor: {
      styles: {
        root: {
          textDecoration: 'underline',
        },
      },
    },
    TextInput: {
      defaultProps: {
        size: 'md',
        radius: 'md',
      },
    },
    Textarea: {
      defaultProps: {
        size: 'md',
        radius: 'md',
      },
    },
    NumberInput: {
      defaultProps: {
        size: 'md',
        radius: 'md',
      },
    },
    PasswordInput: {
      defaultProps: {
        size: 'md',
        radius: 'md',
      },
    },
    Select: {
      defaultProps: {
        size: 'md',
        radius: 'md',
        withinPortal: true,
        zIndex: 100000,
      },
    },
    Checkbox: {
      defaultProps: {
        color: 'lime.7',
      },
    },
    MultiSelect: {
      defaultProps: {
        size: 'md',
        radius: 'md',
        withinPortal: true,
        zIndex: 100000,
      },
    },
    InputWrapper: {
      styles: theme => ({
        label: {
          fontSize: em(14),
          fontWeight: 500,
          paddingBottom: 5,
          color: theme.colors.dark[9],
        },
      }),
    },
    DatePickerInput: {
      defaultProps: {
        size: 'md',
        radius: 'md',
        icon: <IconCalendar color="black" size={18} />,
        valueFormat: LocalizedDayJsFormat.LocalDate,
        popoverProps: {
          withinPortal: true,
        },
      },
    },
    TimeInput: {
      defaultProps: {
        icon: <IconClock color="black" size={18} />,
      },
    },
    Radio: {
      defaultProps: {
        color: 'lime.7',
      },
    },
    Switch: {
      defaultProps: {
        color: 'lime.6',
      },
    },
    Slider: {
      defaultProps: {
        color: 'lime.6',
      },
    },
    Chip: {
      defaultProps: {
        variant: 'filled',
        color: 'lime.7',
      },
    },
    Paper: {
      defaultProps: {
        withBorder: true,
        radius: 16,
      },
    },
    Tooltip: {
      defaultProps: {
        withinPortal: true,
        size: 'xs',
      },
    },
    Popover: {
      defaultProps: {
        zIndex: 100000,
      },
    },
    Modal: {
      styles: {
        root: {
          position: 'relative',
          zIndex: 100000,
        },
        content: {
          border: 'none !important',
        },
      },
      defaultProps: theme => ({
        overlayProps: {
          color: theme.colors.gray[2],
          blur: 2,
        },
        closeButtonProps: {
          size: 36,
          bg: 'background.2',
          c: 'background.9',
          radius: 10,
          sx: {
            svg: {
              width: 20,
            },
          },
        },
      }),
    },
    Drawer: {
      styles: {
        root: {
          position: 'relative',
          zIndex: 100000,
        },
      },
      defaultProps: {
        overlayProps: {
          opacity: 0,
        },
      },
    },
    Divider: {
      defaultProps: {
        color: 'background.5',
      },
    },
  },
};

const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <Notifications position="top-right" zIndex={1000000} />
      {children}
    </MantineProvider>
  );
};

export default MantineTheme;
