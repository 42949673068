import styled from '@emotion/styled';
import { em, Tabs } from '@mantine/core';
import { LayoutConstants } from '@layout/constants';
import { styledOptions } from '@styles/utils';

export const PageTabsContainer = styled.div`
  position: sticky;
  top: 0;
  background: ${props => props.theme.colors.background[2]};
  height: 50px;
  overflow: hidden;
  z-index: 10000;

  ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    top: ${LayoutConstants.MOBILE_HEADER_HEIGHT}px;
  }
`;

export const PageTabsContent = styled.div`
  padding: 10px 20px;
  overflow-x: auto;
`;

export const PageTab = styled(Tabs.Tab, styledOptions)<{ $icon: string; $activeIcon: string }>`
  position: relative;
  padding: 0;
  background: ${props => props.theme.colors.background[0]};

  a {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 8px 0 36px;
    text-decoration: none;
    font-size: ${em(14)};
    font-weight: 600;
    color: ${props => props.theme.colors.background[9]};
    background-image: url(${props => props.$icon});
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: left 8px center;
  }

  &[data-active='true'] {
    a {
      color: ${props => props.theme.colors.background[0]};
      background-image: url(${props => props.$activeIcon});
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -7px;
      left: 50%;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 7px solid ${props => props.theme.colors.lime[9]};
      transform: translateX(-50%);
    }
  }
`;
