import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

export const usersRoutes = (
  <>
    <Route path="users">
      <Route index {...createLazyRoute(module, m => m.usersListRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.userDetailRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createUserRoute)} />
    </Route>

    <Route path="my-users">
      <Route index {...createLazyRoute(module, m => m.myUsersListRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createMyUserRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.myUserDetailRoute)} />
    </Route>
  </>
);

export default usersRoutes;
