import { AxiosError, HttpStatusCode } from 'axios';
import { RangeResult } from '@shared/modules/range';
import { Filter } from '@shared/modules/filter';
import { Effect, Option, pipe } from 'effect';
import { throwResponse } from '@core/router';
import { RemoteData } from '@core/fp';

export const TRANSACTION_ID_HEADER_KEY = 'X-Transaction-ID';

export class HttpError<T = unknown> {
  readonly _tag = 'HttpError';

  constructor(
    public readonly status: HttpStatusCode,
    public readonly message?: string,
    public readonly url?: string,
    public readonly transactionId?: string,
    public readonly data?: T,
  ) {}

  log = () => {
    return Effect.logError(
      `[HttpError] status ${this.status}, url: ${this.url}, message: ${this.message}, transaction: ${this.transactionId}`,
    );
  };

  isDownError = () => {
    return this.status > 500;
  };

  toJson = () => {
    return {
      status: this.status,
      message: this.message,
      url: this.url,
      transactionId: this.transactionId,
      data: this.data,
    };
  };

  toResponse = () => {
    return new Response(this.message, { status: this.status });
  };

  throwResponse = () => {
    return throwResponse(this.toResponse());
  };

  fail = (): Effect.Effect<never, HttpError, never> => {
    return Effect.fail(this);
  };

  static fromStatusCode<E = unknown>(status: HttpStatusCode, message?: string): HttpError<E> {
    return new HttpError(status, message);
  }

  static readonly default = HttpError.fromStatusCode(HttpStatusCode.InternalServerError);
  static readonly notFound = HttpError.fromStatusCode(HttpStatusCode.NotFound);
  static readonly forbidden = HttpError.fromStatusCode(HttpStatusCode.Forbidden);

  static fromAxiosError<E = unknown>(error: AxiosError<E>): HttpError<E> {
    return pipe(
      Option.fromNullable(error.response),
      Option.match({
        onNone: () => new HttpError(HttpStatusCode.ServiceUnavailable),
        onSome: res => {
          const status = res.status === 0 ? HttpStatusCode.ServiceUnavailable : res.status;

          const message = pipe(
            Option.fromNullable(res.data as { message?: string }),
            Option.flatMapNullable(data => data.message),
            Option.map(message => message.toString()),
            Option.orElse(() => Option.fromNullable(error.message)),
            Option.getOrElse(() => 'unknown'),
          );

          const url = pipe(
            Option.fromNullable(res.config),
            Option.flatMapNullable(config => config.url),
            Option.getOrUndefined,
          );

          const transactionId = pipe(
            Option.fromNullable(error.config?.headers),
            Option.flatMapNullable(headers => headers.get(TRANSACTION_ID_HEADER_KEY)),
            Option.map(transactionId => `${transactionId}`),
            Option.getOrUndefined,
          );

          return new HttpError(status, message, url, transactionId, res.data);
        },
      }),
    );
  }
}

export type HttpEffect<R = unknown, E = unknown> = Effect.Effect<never, HttpError<E>, R>;
export type HttpRemoteData<R = unknown, E = unknown> = RemoteData<HttpError<E>, R>;
export type HttpRange<R = unknown, F extends Filter = {}, E = unknown> = HttpEffect<RangeResult<R, F>, E>;
