import { DateRangeFilter } from '@shared/modules/dates';
import { Favourite } from '@modules/favourites/model';
import { ProfilePredicates } from '@modules/profile/predicates';
import { Role } from '@modules/roles/model';
import { ReadonlyArray, Predicate } from 'effect';
import { Profile } from '@modules/profile/model';

export namespace Dashboard {
  export type Filter = DateRangeFilter & Favourite.Filter.WithFavouriteFilter;

  export namespace Tabs {
    export enum Path {
      Activity = 'activity',
      Sensors = 'sensors',
      Support = 'support',
      Metrics = 'metrics',
      Qos = 'quality-of-service',
    }

    const dsAndProviders = ProfilePredicates.hasAccountType(Role.AccountType.DeSangosse, Role.AccountType.Provider);
    const onlyDS = ProfilePredicates.hasAccountType(Role.AccountType.DeSangosse);

    export const entries: ReadonlyArray<{ path: Path; canAccess: Predicate.Predicate<Profile> }> = [
      {
        path: Path.Activity,
        canAccess: ProfilePredicates.and(dsAndProviders, ProfilePredicates.canAccess(Role.AccessKey.Parcels)),
      },
      {
        path: Path.Sensors,
        canAccess: ProfilePredicates.and(
          dsAndProviders,
          ProfilePredicates.or(
            ProfilePredicates.canAccess(Role.AccessKey.Sensors),
            ProfilePredicates.canAccess(Role.AccessKey.Defaults),
          ),
        ),
      },
      {
        path: Path.Support,
        canAccess: ProfilePredicates.and(onlyDS, ProfilePredicates.canAccess(Role.AccessKey.DashboardMaintenance)),
      },
      {
        path: Path.Metrics,
        canAccess: ProfilePredicates.and(onlyDS, ProfilePredicates.canAccess(Role.AccessKey.DashboardUsage)),
      },
      {
        path: Path.Qos,
        canAccess: ProfilePredicates.and(onlyDS, ProfilePredicates.canAccess(Role.AccessKey.DashboardQos)),
      },
    ];
  }
}
