import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import UpdateProfileForm from '@modules/profile/components/UpdateProfileForm';
import { defineAction, useAction } from '@core/router/action';
import { ProfileService } from '@modules/profile/service';
import { Profile } from '@modules/profile/model';
import { useProfile } from '@modules/profile/loader';
import { BottomPage, TopPage } from '@layout/page';
import { Translation, useTranslation } from 'react-i18next';
import Seo from '@shared/modules/seo/Seo';
import { Button, Group, Title } from '@mantine/core';
import ProfileInfos from '@modules/profile/components/infos/ProfileInfos';

const actions = {
  update: defineAction({
    type: 'update',
    payload: Profile.UpdateProfileParams,
    handler: ({ payload }) => ProfileService.updateProfile(payload),
    flashOptions: { success: () => <Translation>{t => t('flash.success.update')}</Translation> },
  }),
};

const ProfilePage: FC = () => {
  const { t } = useTranslation('profile');
  const profile = useProfile();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Profile.UpdateProfileParams>({
    resolver: zodResolver(Profile.UpdateProfileParams),
    defaultValues: {
      firstName: profile.firstName ?? '',
      lastName: profile.lastName ?? '',
      phoneNumber: profile.phoneNumber ?? '',
      postalAddress: profile.postalAddress,
      language: profile.language,
      zoneId: profile.zoneId,
    },
  });

  const [loading, updateProfile] = useAction(actions.update);

  return (
    <>
      <Seo title={t('title')} />

      <TopPage>
        <Title order={1} size="h3">
          {t('title')}
        </Title>
      </TopPage>

      <ProfileInfos profile={profile} />

      <EnhancedForm ref={formRef} form={form} onSubmit={updateProfile} preventLeave>
        <UpdateProfileForm />
      </EnhancedForm>

      <BottomPage>
        <Group position="right">
          <Button type="submit" loading={loading} onClick={handleFormSubmit}>
            {t('update.actions.save')}
          </Button>
        </Group>
      </BottomPage>
    </>
  );
};

const profilePageRoute = defineRoute({
  ns: 'profile',
  element: <ProfilePage />,
  actions,
});

export default profilePageRoute;
