import z from 'zod';
import { nonEmptyStringSchema } from '@shared/schemas';
import { User } from '@modules/users/model';
import { Role } from '@modules/roles/model';
import { Address } from '@shared/modules/address/model';
import { Equivalence, Order } from 'effect';
import { Countries } from '@shared/model';

export namespace Account {
  export const Id = z.string().uuid().brand<'AccountId'>();
  export type Id = z.infer<typeof Id>;

  export const EqAccountId = Equivalence.mapInput(Equivalence.string, (id: Account.Id) => id);
  export const OrdAccountId = Order.mapInput(Order.string, (id: Account.Id) => id);

  export interface RangeItem {
    id: Id;
    name: string;
    postalAddress: Address;
    adminEmail: string | null;
    sensorNumber: number;
    parcelNumber: number;
  }

  export const CreateAdminParams = User.Params.omit({
    accountId: true,
    accountType: true,
  });
  export type CreateAdminParams = z.infer<typeof CreateAdminParams>;

  export const CreateParams = z.object({
    admin: CreateAdminParams,
  });
  export type CreateParams = z.infer<typeof CreateParams>;

  export const Params = z.object({
    name: nonEmptyStringSchema,
    siret: z.string().nullish(),
    postalAddress: Address,
    comment: z.string().nullish(),
  });
  export type Params = z.infer<typeof Params>;

  export const UpdateParams = Params.extend({
    country: z.nativeEnum(Countries),
  });
  export type UpdateParams = z.infer<typeof UpdateParams>;

  export interface Light {
    id: Id;
    type: Role.AccountType;
    name: string;
    postalAddress?: Address;
  }

  export interface Profile {
    accountName: string;
    siret: string;
    postalAddress: Address;
    parcelNumber: number;
    sellingSensorNumber: number;
    rentingSensorNumber: number;
  }

  export const ProfileUpdate = z.object({
    name: nonEmptyStringSchema,
    siret: z.string().nullish(),
    postalAddress: Address,
  });
  export type ProfileUpdate = z.infer<typeof ProfileUpdate>;

  export interface SearchItem {
    id: Id;
    name: string;
    type: Role.AccountType;
  }
}

export interface Account {
  id: Account.Id;
  name: string;
  siret: string | null;
  postalAddress: Address;
  country: Countries;
  comment: string | null;
  parcelNumber: number;
  sellingSensorNumber: number;
  rentingSensorNumber: number;
}
