import { HttpRange, httpService } from '@core/http';
import { Notification } from '@modules/notifications/model';

export namespace NotificationsService {
  const URI = '/notifications';

  export function getRange(page: number): HttpRange<Notification> {
    return httpService.getRange(URI, page, {}, null);
  }
}
