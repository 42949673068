import React, { FC, useEffect, useMemo } from 'react';
import { HttpError } from '@core/http';

import * as Styled from './ErrorPage.styles';
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { Button, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { renderNullable } from '@shared/utils/render';

import errorImage from '@assets/images/error.svg';
import { HttpStatusCode } from 'axios';

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound }) => {
  const internalError = useRouteError();

  const { t } = useTranslation();

  useEffect(() => {
    if (internalError) {
      console.error(internalError);

      if (!isRouteErrorResponse(internalError)) {
        Sentry.captureException(internalError, {
          level: 'error',
        });
      } else if (![HttpStatusCode.NotFound, HttpStatusCode.Forbidden].includes(internalError.status)) {
        Sentry.captureMessage(`[ErrorPage] capture response with ${internalError.status} code`, {
          level: 'error',
          extra: { response: internalError },
        });
      }
    }
  }, [internalError]);

  const [title, description] = useMemo(() => {
    const getTextFromStatus = (status: number) => {
      switch (status) {
        case HttpStatusCode.NotFound:
          return [t('error-page.not-found.title'), t('error-page.not-found.description')];
        case HttpStatusCode.Forbidden:
          return [t('error-page.forbidden.title'), t('error-page.forbidden.description')];
        default:
          return [`Error ${status}`];
      }
    };

    if (internalError) {
      if (isRouteErrorResponse(internalError)) {
        return getTextFromStatus(internalError.status);
      }

      return [t('errors.technical')];
    }

    return getTextFromStatus(error.status);
  }, [error.status, internalError, t]);

  return (
    <Styled.ErrorPageContainer>
      <img src={errorImage} alt="Error" width={318} height={190} />

      <Title my={25}>{title}</Title>

      {renderNullable(description, description => (
        <Text mb={25} maw={560}>
          {description}
        </Text>
      ))}

      <Button component={Link} to="/">
        {t('error-page.action')}
      </Button>
    </Styled.ErrorPageContainer>
  );
};

export default ErrorPage;
