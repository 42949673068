import styled from '@emotion/styled';
import { em } from '@mantine/core';

export const TopPageContainer = styled.div`
  display: flex;
  padding: 15px 40px 15px 20px;
  background: ${props => props.theme.colors.background[2]};
  align-items: center;
`;

export const TopPageLeft = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
`;

export const TopPageBreadcrumbs = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 0;
  list-style: none;

  li {
    position: relative;
    padding-right: 12px;
    line-height: 1.2;

    a {
      color: #000;
      text-decoration: none;
      font-size: ${em(10)};
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 6px;
      right: 5px;
      display: block;
      width: 2px;
      height: 2px;
      background: #000;
      border-radius: 100%;
    }
  }
`;
