import styled from '@emotion/styled';
import { ActionIcon, ActionIconProps, createPolymorphicComponent, Popover } from '@mantine/core';

export const UserMenuDroUserMenuDropdown = styled(Popover.Dropdown)`
  ${props => props.theme.fn.smallerThan('xs')} {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;

    > div {
      width: 100%;
    }
  }
`;

export const UserMenuCloseButton = createPolymorphicComponent<'button', ActionIconProps>(styled(ActionIcon)`
  ${props => props.theme.fn.largerThan('xs')} {
    display: none;
  }
`);
