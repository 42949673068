import { TFunction } from 'i18next';

export namespace Translations {
  export enum Language {
    French = 'fr',
    English = 'en',
    German = 'de',
    Spanish = 'es',
  }

  export type TranslationInterpolation = (t: TFunction) => string;

  export type TranslatedEnumLabel<E extends string> = Record<E, TranslationInterpolation>;

  export const languageLabels: TranslatedEnumLabel<Language> = {
    [Language.French]: t => t('language.fr', { ns: 'common' }),
    [Language.English]: t => t('language.en', { ns: 'common' }),
    [Language.German]: t => t('language.de', { ns: 'common' }),
    [Language.Spanish]: t => t('language.es', { ns: 'common' }),
  };

  export type Key = string;
}
