import styled from '@emotion/styled';
import { Box, BoxProps, createPolymorphicComponent, em } from '@mantine/core';

export const VirtualizedListContainer = styled.div<{ $isMobile: boolean }>`
  width: 100%;

  ${props =>
    !props.$isMobile &&
    `
    padding: 15px 20px 10px;
    background: ${props.theme.colors.background[0]};
    border-radius: 12px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.04);
  `}
`;

export const VirtualizedTableHeaderContainer = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: grid;
  grid-column-gap: 10px;
  align-items: center;
  padding: 5px 0;
  min-height: 50px;
`);

export const VirtualizedDesktopRowContainer = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: grid;
  grid-column-gap: 10px;
  min-height: 55px;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.background[5]};
  font-size: ${em(12)};
`);

export const VirtualizedMobileRowContainer = styled.div`
  background: ${props => props.theme.colors.background[0]};
  border-radius: 12px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.04);
`;
