import React from 'react';
import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

export const farmersRoutes = (
  <>
    <Route path="farmers">
      <Route index {...createLazyRoute(module, m => m.farmersListRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createFarmerRoute)} />

      <Route path=":id" {...createLazyRoute(module, m => m.farmerDetailLoaderRoute, 'detail-farmer')}>
        <Route {...createLazyRoute(module, m => m.farmerDetailLayoutRoute)}>
          <Route index {...createLazyRoute(module, m => m.farmerDetailInfos)} />
          <Route path="users" {...createLazyRoute(module, m => m.farmerDetailUsers)} />
          <Route path="sensors" {...createLazyRoute(module, m => m.farmerDetailSensors)} />
          <Route path="contracts" {...createLazyRoute(module, m => m.farmerContractsListRoute)} />
        </Route>

        <Route path="contracts">
          <Route path="new" {...createLazyRoute(module, m => m.createFarmerContractRoute)} />
          <Route path=":contractId" {...createLazyRoute(module, m => m.farmerContractDetailRoute)} />
        </Route>
      </Route>
    </Route>

    <Route path="contracts-mine" {...createLazyRoute(module, m => m.farmerMyContractsListRoute)} />
  </>
);

export default farmersRoutes;
