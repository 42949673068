import styled from '@emotion/styled';
import { em, TextInput } from '@mantine/core';

export const SearchFilterContainer = styled.div`
  position: relative;
  height: 36px;
  margin-right: 10px;
  z-index: 10;

  ${props => props.theme.fn.smallerThan('sm')} {
    width: 36px;
  }
`;

export const SearchFilter = styled(TextInput)`
  ${props => props.theme.fn.smallerThan('sm')} {
    position: absolute;
    width: unset;

    input {
      width: 36px;
      padding-left: ${em(24)} !important;
      transition: all 0.15s ease-in-out;

      &:focus {
        width: calc(100vw - 40px);
        padding-left: ${em(36)} !important;
      }
    }
  }
`;
