import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { LayoutConstants } from '@layout/constants';

export const MissingInfoCounterContainer = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  background: ${props => props.theme.colors.background[2]};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.background[6]};
  text-decoration: none;
  color: #000;

  ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    padding: 4px;
    background: #fff;
  }
`;

export const MissingInfoCounterText = styled.span`
  padding: 0 5px;
  font-size: 12px;

  ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    display: none;
  }
`;

export const MissingInfoCounter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  background: ${props => props.theme.colors.yellow[3]};
  border-radius: 100%;
  line-height: 1;

  ${props => props.theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)} {
    position: absolute;
    top: -4px;
    right: -9px;
  }
`;
