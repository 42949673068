import React, { CSSProperties } from 'react';
import { Virtualizer } from '@tanstack/react-virtual';
import { Range, RangeCursor, VirtualizedRenderer } from '@shared/modules/range';
import { renderOptional } from '@shared/utils/render';
import VirtualizedLoadingRow from '@shared/modules/range/components/VirtualizedLoadingRow';
import { useThrottledCallback } from 'use-debounce';

interface SimpleVirtualizedListProps<T, TScrollElement extends Window | Element> {
  virtualizer: Virtualizer<TScrollElement, Element>;
  range: Range<T>;
  loadPage: (page: number) => void;
  children: VirtualizedRenderer<T>;
}

function VirtualizedListContent<T, TScrollElement extends Window | Element>({
  virtualizer,
  range,
  loadPage,
  children,
}: SimpleVirtualizedListProps<T, TScrollElement>) {
  const handleLoadRow = (index: number) => {
    const page = Math.floor(index / RangeCursor.DEFAULT_SIZE) + 1;

    loadPage(page);
  };

  const throttledLoadRow = useThrottledCallback(handleLoadRow, 20);

  return (
    <div style={{ position: 'relative', height: virtualizer.getTotalSize(), width: '100%' }}>
      {virtualizer.getVirtualItems().map(row => {
        const style: CSSProperties = {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${row.start}px)`,
          willChange: 'transform',
        };

        return renderOptional(
          range.get(row.index),
          item => (
            <div ref={virtualizer.measureElement} key={row.key} style={style} data-index={row.index}>
              {children(item, row.index)}
            </div>
          ),
          () => <VirtualizedLoadingRow key={row.key} style={style} index={row.index} loadRow={throttledLoadRow} />,
        );
      })}
    </div>
  );
}

export default VirtualizedListContent;
