import React, { FC } from 'react';
import { defineRoute, useHashDisclosure } from '@core/router';
import { Navigate, Outlet } from 'react-router-dom';
import { useProfile } from '@modules/profile/loader';
import { useDisclosure } from '@mantine/hooks';
import { useAcceptTosRedirection } from '@modules/accept-tos/hooks';
import { useOnBoardingRedirection } from '@modules/onboarding/hooks';
import NotificationsDrawer, { NOTIFICATIONS_DRAWER_HASH } from '@modules/notifications/components/NotificationsDrawer';
import NotificationsModal from '@modules/notifications/components/NotificationsModal';

const ProfileInteractionsPage: FC = () => {
  const profile = useProfile();

  const [notificationsDrawerOpened, notificationsDrawerTriggers] = useHashDisclosure(NOTIFICATIONS_DRAWER_HASH);
  const [notificationsModalOpened, notificationsModalTriggers] = useDisclosure(profile.counter.unreadNotifications > 0);

  const acceptTosRedirection = useAcceptTosRedirection();
  const onBoardingRedirection = useOnBoardingRedirection();

  const redirectRoute = acceptTosRedirection ?? onBoardingRedirection;

  if (redirectRoute) {
    return <Navigate to={redirectRoute} replace />;
  }

  return (
    <>
      <Outlet />

      <NotificationsModal opened={notificationsModalOpened} onClose={notificationsModalTriggers.close} />
      <NotificationsDrawer opened={notificationsDrawerOpened} onClose={notificationsDrawerTriggers.close} />
    </>
  );
};

const profileInteractionsRoute = defineRoute({
  ns: 'profile',
  element: <ProfileInteractionsPage />,
});

export default profileInteractionsRoute;
