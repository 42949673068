import { Function } from 'effect';

export namespace RemoteData {
  export interface Pending {
    readonly _tag: 'Pending';
  }

  export interface Success<A> {
    readonly _tag: 'Success';
    readonly success: A;
  }

  export interface Failure<E> {
    readonly _tag: 'Failure';
    readonly failure: E;
  }

  export function pending<E = never, A = never>(): RemoteData<E, A> {
    return { _tag: 'Pending' };
  }

  export function success<E = never, A = never>(success: A): RemoteData<E, A> {
    return { _tag: 'Success', success };
  }

  export function failure<E = never, A = never>(failure: E): RemoteData<E, A> {
    return { _tag: 'Failure', failure };
  }

  export function isPending<E, A>(self: RemoteData<E, A>): self is Pending {
    return self._tag === 'Pending';
  }

  export function isSuccess<E, A>(self: RemoteData<E, A>): self is Success<A> {
    return self._tag === 'Success';
  }

  export function isFailure<E, A>(self: RemoteData<E, A>): self is Failure<E> {
    return self._tag === 'Failure';
  }

  export const match: {
    <E, B, A, C = B, D = B>(options: {
      readonly onPending: Function.LazyArg<B>;
      readonly onSuccess: (a: A) => C;
      readonly onFailure: (e: E) => D;
    }): (self: RemoteData<E, A>) => B | C | D;
    <E, B, A, C = B, D = B>(
      self: RemoteData<E, A>,
      options: {
        readonly onPending: Function.LazyArg<B>;
        readonly onSuccess: (a: A) => C;
        readonly onFailure: (e: E) => D;
      },
    ): B | C | D;
  } = Function.dual(
    2,
    <E, B, A, C = B, D = B>(
      self: RemoteData<E, A>,
      {
        onPending,
        onSuccess,
        onFailure,
      }: {
        readonly onPending: Function.LazyArg<B>;
        readonly onSuccess: (a: A) => C;
        readonly onFailure: (e: E) => D;
      },
    ): B | C | D =>
      isSuccess(self) ? onSuccess(self.success) : isFailure(self) ? onFailure(self.failure) : onPending(),
  );
}

export type RemoteData<E, A> = RemoteData.Pending | RemoteData.Success<A> | RemoteData.Failure<E>;
