import React, { FC } from 'react';

import { Helmet } from 'react-helmet-async';

import config from '@root/config';
import { Option, pipe, ReadonlyArray } from 'effect';

interface SeoProps {
  title?: string | ReadonlyArray.NonEmptyArray<string>;
}

const baseTitle = config.VITE_APP_TITLE;

const Seo: FC<SeoProps> = ({ title }) => {
  const metaTitle = pipe(
    Option.fromNullable(title),
    Option.match({
      onNone: () => baseTitle,
      onSome: t => (typeof t === 'string' ? t : t.join(' - ')),
    }),
  );

  return <Helmet title={metaTitle} />;
};

export default Seo;
