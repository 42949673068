import { z } from 'zod';
import { nonEmptyStringSchema } from '@shared/schemas';
import { Parcel } from '@modules/parcels/model';
import { Sensor } from '@modules/sensors/model';
import { Account } from '@modules/account/model';
import { Dispatch, SetStateAction } from 'react';
import { StringifiableRecord } from 'query-string';

export namespace Favourite {
  export const Id = z.string().uuid().brand('FavouriteId');
  export type Id = z.infer<typeof Id>;

  export interface Item {
    id: Id;
    name: string;
    isFavourite: boolean;
    postalZones: Array<string>;
    cropTypes: Array<Parcel.Crop.Type>;
  }

  export const AccountSelection = z.discriminatedUnion('type', [
    z.object({ type: z.literal('all-selected') }),
    z.object({ type: z.literal('some-selected'), selected: z.array(Account.Id).min(1) }),
  ]);
  export type AccountSelection = z.infer<typeof AccountSelection>;

  export const Params = z.object({
    name: nonEmptyStringSchema,
    isFavourite: z.boolean(),
    postalZones: z.array(nonEmptyStringSchema),
    selection: AccountSelection,
    // Parcels
    linkedSensorsAndParcels: z.boolean(),
    cropTypes: z.array(z.nativeEnum(Parcel.Crop.Type)),
    seedingTypes: z.array(z.nativeEnum(Parcel.Crop.SeedingType)),
    risks: z.array(z.nativeEnum(Parcel.Risk.Level)),
    // Sensors
    faultySensors: z.boolean(),
    sensorSerialNumbers: z.array(nonEmptyStringSchema),
    sensorStates: z.array(z.nativeEnum(Sensor.State)),
    sensorUsages: z.array(z.nativeEnum(Sensor.Usage)),
    // Tags
    tags: z.array(nonEmptyStringSchema),
  });
  export type Params = z.infer<typeof Params>;

  export const PostalZonesBody = z.object({ postalZones: z.array(z.object({ value: z.string() })) });
  export type PostalZonesBody = z.infer<typeof PostalZonesBody>;

  export const AccountsBody = Params.pick({ selection: true });
  export type AccountsBody = z.infer<typeof AccountsBody>;

  export type ParamsContext = [Params, Dispatch<SetStateAction<Params>>];

  export namespace Referential {
    export interface PostalZonesFilter {
      search?: string | null;
      postalZones?: Array<string> | null;
    }

    export interface AccountFilter extends PostalZonesFilter {
      selection: AccountSelection;
    }
  }

  export namespace Filter {
    export const Id = Favourite.Id.or(z.literal('none')).catch(() => 'none' as const);
    export type Id = z.infer<typeof Id>;

    export interface WithFavouriteFilter extends StringifiableRecord {
      favouriteId: Filter.Id;
    }
  }
}

export interface Favourite extends Favourite.Params {
  id: Favourite.Id;
}
