import { Role } from '@modules/roles/model';
import { Profile } from '@modules/profile/model';
import { Account } from '@modules/account/model';
import { Predicate, ReadonlyArray } from 'effect';

export namespace ProfilePredicates {
  export function hasAccountType(
    ...accountTypes: ReadonlyArray.NonEmptyArray<Role.AccountType>
  ): Predicate.Predicate<Profile> {
    return profile => accountTypes.includes(profile.accountType);
  }

  export function canAccess(access: Role.AccessKey): Predicate.Predicate<Profile> {
    return profile => [Role.Right.ReadOnly, Role.Right.FullAccess].includes(profile.role[access]);
  }

  export function canEdit(access: Role.AccessKey): Predicate.Predicate<Profile> {
    return profile => Role.Right.FullAccess === profile.role[access];
  }

  export function isAdmin(): Predicate.Predicate<Profile> {
    return profile => profile.role.isAdmin;
  }

  export function isAccount(id: Account.Id): Predicate.Predicate<Profile> {
    return profile => profile.accountId === id;
  }

  export function or(...predicates: ReadonlyArray.NonEmptyArray<Predicate.Predicate<Profile>>) {
    return Predicate.some(predicates);
  }

  export function and(...predicates: ReadonlyArray.NonEmptyArray<Predicate.Predicate<Profile>>) {
    return Predicate.every(predicates);
  }

  export function hasDecisionTree(): Predicate.Predicate<Profile> {
    return or(
      hasAccountType(Role.AccountType.DeSangosse),
      profile => !profile.internationalizationSettings.noDecisionTree,
    );
  }
}
