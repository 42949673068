import React, { FC, PropsWithChildren } from 'react';

import * as Styled from './TopPage.styles';
import UserMenu from '@layout/user/UserMenu';
import { ActionIcon, Box, Group } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link, To, useNavigate } from 'react-router-dom';
import { renderOptional } from '@shared/utils/render';
import MissingInfoCounter from '@layout/missing-info/MissingInfoCounter';
import { LayoutConstants } from '@layout/constants';
import NotificationsCounter from '@layout/notifications/NotificationsCounter';
import { Option, pipe } from 'effect';
import { toNonEmptyArray } from '@shared/utils/array';

export interface TopPageBreadCrumb {
  title: string;
  to: To;
}

export interface TopPageProps {
  withBackButton?: boolean;
  withRightSection?: boolean;
  backLink?: To;
  breadcrumbs?: Array<TopPageBreadCrumb>;
}

export const TopPage: FC<PropsWithChildren<TopPageProps>> = ({
  withBackButton = false,
  withRightSection = true,
  backLink,
  breadcrumbs: receivedBreadcrumbs,
  children,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (backLink) {
      navigate(backLink, { replace: true });
    } else {
      navigate(-1);
    }
  };

  const breadcrumbs = pipe(Option.fromNullable(receivedBreadcrumbs), Option.flatMap(toNonEmptyArray));

  return (
    <Styled.TopPageContainer>
      <Styled.TopPageLeft>
        {withBackButton ? (
          <ActionIcon mr={15} size={36} variant="outline" color="background.5" c="background.9" onClick={handleGoBack}>
            <IconArrowLeft size={24} />
          </ActionIcon>
        ) : null}

        <Box>
          {renderOptional(breadcrumbs, breadcrumbs => (
            <Styled.TopPageBreadcrumbs>
              {breadcrumbs.map((breadcrumb, i) => (
                <li key={i}>
                  <Link to={breadcrumb.to}>{breadcrumb.title}</Link>
                </li>
              ))}
            </Styled.TopPageBreadcrumbs>
          ))}

          {children}
        </Box>
      </Styled.TopPageLeft>

      {withRightSection ? (
        <Group
          noWrap
          spacing={6}
          sx={theme => ({ [theme.fn.smallerThan(LayoutConstants.LAYOUT_MOBILE_BREAKPOINT)]: { display: 'none' } })}
        >
          <MissingInfoCounter />
          <NotificationsCounter />
          <UserMenu />
        </Group>
      ) : null}
    </Styled.TopPageContainer>
  );
};
