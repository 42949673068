import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const issuesRoutes = (
  <Route path="issues" {...createLazyRoute(module, m => m.issuesListRoute)}>
    <Route path=":issueId" {...createLazyRoute(module, m => m.issueDetailRoute)} />
  </Route>
);

export default issuesRoutes;
