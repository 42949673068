import { z } from 'zod';
import { Countries } from '@shared/model';

export const GPSLocation = z.object({
  lat: z.number().min(-90).max(90),
  long: z.number().min(-180).max(180),
});

export type GPSLocation = z.infer<typeof GPSLocation>;

export namespace GoogleMaps {
  export const defaultOptionsByCountry: Record<Countries, google.maps.MapOptions> = {
    [Countries.FR]: {
      center: { lat: 46.9839695, lng: 1.7773997 },
      zoom: 6,
    },
    [Countries.UK]: {
      center: { lat: 54.869122, lng: -4.6702071 },
      zoom: 5.2,
    },
    [Countries.AU]: {
      center: { lat: -25.274398, lng: 133.775136 },
      zoom: 4,
    },
    [Countries.DE]: {
      center: { lat: 51.165691, lng: 10.451526 },
      zoom: 6,
    },
    [Countries.ES]: {
      center: { lat: 40.463667, lng: -3.74922 },
      zoom: 6,
    },
  };

  export const defaultOptions: google.maps.MapOptions = {
    ...defaultOptionsByCountry[Countries.FR],
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    zoomControl: false,
    mapTypeId: 'hybrid',
    clickableIcons: false,
    draggableCursor: 'grab',
    draggingCursor: 'grabbing',
    scaleControl: true,
    zoomControlOptions: {
      position: 4.0,
    },
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };
}
