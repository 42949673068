import React from 'react';
import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const accountProfilePageRoute = () => import('@modules/account/AccountProfilePage');

export const accountRoutes = (
  <Route path="account/profile" {...createLazyRoute(accountProfilePageRoute, m => m.default)} />
);

export default accountRoutes;
