import { AuthService } from '@modules/auth/service';
import { defineRoute, redirect } from '@core/router';
import { defineLoader } from '@core/router/loader';
import { AuthUtils } from '@modules/auth/utils';
import { Effect, pipe } from 'effect';

const loader = defineLoader({
  handler: () =>
    pipe(
      AuthService.logout(),
      Effect.flatMap(() => redirect(AuthUtils.createLoginLink(false))),
    ),
});

const logoutRoute = defineRoute({
  ns: [],
  loader,
});

export default logoutRoute;
