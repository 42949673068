import React, { FC } from 'react';

import * as Styled from './MobileHeader.styles';
import { LayoutConstants } from '@layout/constants';
import { ActionIcon, Group } from '@mantine/core';
import { IconAlignJustified, IconArrowBarLeft } from '@tabler/icons-react';

import logo from '@assets/logos/logo-small.svg';
import UserMenu from '@layout/user/UserMenu';
import MissingInfoCounter from '@layout/missing-info/MissingInfoCounter';
import NotificationsCounter from '@layout/notifications/NotificationsCounter';

interface MobileHeaderProps {
  navOpen: boolean;
  toggleNav: () => void;
  toggleNavRef: (element: HTMLElement | null) => void;
}

const MobileHeader: FC<MobileHeaderProps> = ({ navOpen, toggleNav, toggleNavRef }) => {
  return (
    <Styled.MobileHeaderContainer height={LayoutConstants.MOBILE_HEADER_HEIGHT}>
      <ActionIcon ref={toggleNavRef} c="dark.7" onClick={toggleNav}>
        {navOpen ? <IconArrowBarLeft /> : <IconAlignJustified />}
      </ActionIcon>

      <img src={logo} alt="Logo limacapt" width={50} height={40} />

      <Group noWrap spacing={6} position="right" sx={{ flex: '1 1 auto' }}>
        <MissingInfoCounter />
        <NotificationsCounter />
        <UserMenu />
      </Group>
    </Styled.MobileHeaderContainer>
  );
};

export default MobileHeader;
