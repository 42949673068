import { OAuth } from '@core/oauth/model';
import { HttpStatusCode } from 'axios';

export namespace OAuthUtils {
  import OAuthHttpError = OAuth.OAuthHttpError;
  import OAuthErrorCode = OAuth.OAuthErrorCode;

  export function getOAuthErrorCode(error: OAuthHttpError): OAuthErrorCode | null {
    if (error.status === HttpStatusCode.BadRequest) {
      return Object.values(OAuthErrorCode).find(code => code === error.data?.error) ?? null;
    }

    return null;
  }
}
