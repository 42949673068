import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const favouriteModule = () => import('@modules/favourites/module');

const onBoardingRoutes = (
  <Route path="onboarding">
    <Route path="favourites" {...createLazyRoute(favouriteModule, m => m.favouritesOnBoardingRoute)} />
  </Route>
);

export default onBoardingRoutes;
