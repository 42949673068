import { z } from 'zod';
import { Role } from '@modules/roles/model';
import { LightAddress } from '@shared/modules/address/model';
import { Account } from '@modules/account/model';
import { emailSchema, NonEmptyString, nonEmptyStringSchema } from '@shared/schemas';
import { GPSLocation } from '@shared/modules/maps/model';
import { Sensor } from '@modules/sensors/model';
import i18next, { Translations, TranslationsUtils } from '@core/translations';
import { TFunction } from 'i18next';
import { FilterQueryParser, SearchFilter } from '@shared/modules/filter';
import { genericDateRangeFilterParser, LocalDate, LocalTime } from '@shared/modules/dates';
import { Direction, Percentage, Temperature, Velocity, Volume } from '@shared/model';
import { Signal } from '@shared/modules/signal/model';
import { Battery } from '@shared/modules/battery/model';
import { StringifiableRecord } from 'query-string';
import { Issue } from '@modules/issues/model';
import type { Favourite } from '@modules/favourites/model';
import { Effect, pipe } from 'effect';
import { QueryUtils } from '@shared/utils/queries';
import { getEnumOrder } from '@shared/utils/enum';
import { InternationalizationSettings } from '@modules/settings/internationalization/model';
import { Pest, PestCount, PestCountWithDate } from '@shared/modules/pest/model';
import { NestedRangeResult } from '@shared/modules/range';

export namespace Parcel {
  import TranslatedEnumLabel = Translations.TranslatedEnumLabel;
  import DateTime = Sensor.DateTime;

  export const Id = z.string().uuid().brand<'ParcelId'>();
  export type Id = z.infer<typeof Id>;

  export interface RangeItem {
    id: Id;
    parcelLabel: string;
    accountId: Account.Id;
    accountLabel: string;
    accountType: Role.AccountType;
    address: LightAddress;
    crop: Crop.Type;
    seedingType: Crop.SeedingType | null;
    sensorId: Sensor.Id | null;
    sensorSignal: Signal.Strength | null;
    sensorBattery: Battery.Level | null;
    sensorSerialNumber: string | null;
    risk: Risk.Level | null;
    riskId: Risk.Id | null;
    gpsLocation: GPSLocation;
    issues: Issue.Info | null;
    sharingMode: Parcel.Sharing.Mode;
    hasDecisionTreeVersion: boolean;
    layoutType: InternationalizationSettings.Layout;
    activity: Activity | null;
    countPests: PestCount;
  }

  export interface Filter extends SearchFilter, Favourite.Filter.WithFavouriteFilter {
    onlyMine: boolean | null;
    missingInfoOnly: boolean | null;
    treatedParcel: boolean | null;
    notTreatedParcel: boolean | null;
  }

  export namespace Risk {
    export const Id = z.string().uuid().brand<'RiskId'>();
    export type Id = z.infer<typeof Id>;

    export enum Level {
      UnMeasured = 'unmeasured',
      Low = 'low',
      Medium = 'medium',
      High = 'high',
      MissingAnswer = 'missing_answer',
    }

    export const levelLabel: TranslatedEnumLabel<Level | 'nc'> = {
      [Level.UnMeasured]: t => t('risk.level.unmeasured', { ns: 'parcels' }),
      [Level.Low]: t => t(`risk.level.${Level.Low}`, { ns: 'parcels' }),
      [Level.Medium]: t => t(`risk.level.${Level.Medium}`, { ns: 'parcels' }),
      [Level.High]: t => t(`risk.level.${Level.High}`, { ns: 'parcels' }),
      [Level.MissingAnswer]: t => t(`risk.level.${Level.MissingAnswer}`, { ns: 'parcels' }),
      nc: t => t('risk.level.nc', { ns: 'parcels' }),
    };

    export interface Detail {
      riskId: Risk.Id;
      lastUpdate: Sensor.DateTime;
      level: Risk.Level;
      adviceId: string | null;
    }

    export enum Question {
      GroundPreparation = 'groundPreparation',
      GroundWork = 'groundWork',
      WillRoll = 'willRoll',
      FavourableWeather = 'favourableWeather',
      ResidualPellet = 'residualPellet',
    }

    export const questionLabel = TranslationsUtils.createTranslatedEnumLabel(
      Question,
      question => t => t(`risk.questions.${question}`, { ns: 'parcels' }),
    );

    export const Answer = z.object({
      name: z.nativeEnum(Question),
      answer: z.boolean(),
    });
    export type Answer = z.infer<typeof Answer>;
  }

  export enum Activity {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
  }

  export enum SoilType {
    Argileux = 'argileux',
    LimonoArgileux = 'limono-argileux',
    ArgiloCalcaire = 'argilo-calcaire',
    Limoneux = 'limoneux',
    SabloLimoneux = 'sablo-limoneux',
    Sableux = 'sableux',
    ArgiloSableux = 'argilo-sableux',
  }

  export const soilTypeLabels = TranslationsUtils.createTranslatedEnumLabel(
    SoilType,
    type => t => t(`soil-type.${type}`, { ns: 'parcels' }),
  );

  export namespace Sharing {
    export enum Mode {
      Private = 'private',
      Public = 'public',
      Targeted = 'targeted',
    }

    export interface Targeted {
      email: string;
    }

    export interface CheckResult {
      unknownEmailCount: number;
      knownEmailCount: number;
    }

    export interface CheckError {
      email: string;
    }

    export const Params = z.object({
      parcelIds: z.array(Parcel.Id),
      emails: z.array(emailSchema),
    });
    export type Params = z.infer<typeof Params>;
  }

  export const CreateParcelParamsInner = z.object({
    label: nonEmptyStringSchema,
    soilType: z.nativeEnum(SoilType),
    accountId: Account.Id,
    address: LightAddress,
    gpsLocation: GPSLocation,
    dsComment: z.string().nullish(),
    customerComment: z.string().nullish(),
    sensorId: Sensor.Id.nullish(),
    sharingMode: z.nativeEnum(Parcel.Sharing.Mode),
    sharedEmails: z.array(emailSchema),
  });

  export interface CreateParcelBody {
    info?: CreateParcelInfoBody;
    crop?: Crop.Params;
    sensor?: CreateParcelSensorBody;
  }

  export const UpdateParams = z.object({
    label: nonEmptyStringSchema,
    soilType: z.nativeEnum(SoilType),
    address: LightAddress,
    gpsLocation: GPSLocation,
    comment: z.string().nullish(),
    sharingMode: z.nativeEnum(Parcel.Sharing.Mode),
    sharedEmails: z.array(emailSchema),
  });
  export type UpdateParams = z.infer<typeof UpdateParams>;

  export namespace Crop {
    export const Id = z.string().uuid().brand<'CropId'>();
    export type Id = z.infer<typeof Id>;

    export enum Stage {
      NonSeme = 'non_seme',
      Seme = 'seme',
      Leve = 'leve',
      F1 = 'f1',
      F2 = 'f2',
      F3 = 'f3',
      F4 = 'f4',
      F5 = 'f5',
      F6 = 'f6',
      DebutTallage = 'debut_tallage',
    }

    export const stageLabels: TranslatedEnumLabel<Stage> = {
      [Stage.NonSeme]: t => t(`crop.stage.${Stage.NonSeme}`, { ns: 'parcels' }),
      [Stage.Seme]: t => t(`crop.stage.${Stage.Seme}`, { ns: 'parcels' }),
      [Stage.Leve]: t => t(`crop.stage.${Stage.Leve}`, { ns: 'parcels' }),
      [Stage.F1]: t => t(`crop.stage.leaf`, { ns: 'parcels', count: 1 }),
      [Stage.F2]: t => t(`crop.stage.leaf`, { ns: 'parcels', count: 2 }),
      [Stage.F3]: t => t(`crop.stage.leaf`, { ns: 'parcels', count: 3 }),
      [Stage.F4]: t => t(`crop.stage.leaf`, { ns: 'parcels', count: 4 }),
      [Stage.F5]: t => t(`crop.stage.leaf`, { ns: 'parcels', count: 5 }),
      [Stage.F6]: t => t(`crop.stage.leaf`, { ns: 'parcels', count: 6 }),
      [Stage.DebutTallage]: t => t(`crop.stage.${Stage.DebutTallage}`, { ns: 'parcels' }),
    };

    export enum Type {
      Colza = 'colza',
      Mais = 'mais',
      CerealesHivers = 'cereales_hivers',
      Tournesol = 'tournesol',
      Autre = 'autre',
    }

    export const typeSort = getEnumOrder(Type);

    export const typeLabels = TranslationsUtils.createTranslatedEnumLabel(
      Type,
      type => t => t(`crop.type.${type}`, { ns: 'parcels' }),
    );

    export namespace Previous {
      export enum Type {
        Avoine = 'avoine',
        Betterave = 'betterave',
        Ble = 'ble',
        BleDePrintemps = 'ble_de_printemps',
        Chanvre = 'chanvre',
        Chicoree = 'chicoree',
        Colza = 'colza',
        Epinard = 'epinard',
        Feverole = 'feverole',
        HaricotVert = 'haricot_vert',
        Jachere = 'jachere',
        Lentille = 'lentille',
        Lin = 'lin',
        Lupin = 'lupin',
        Luzerne = 'luzerne',
        Mais = 'mais',
        Millet = 'millet',
        Mourtarde = 'mourtarde',
        Oeillette = 'oeillette',
        Oignon = 'oignon',
        Orge = 'orge',
        OrgeDePrintemps = 'orge_de_printemps',
        Pois = 'pois',
        PommeDeTerre = 'pomme_de_terre',
        PrairieTemporaire = 'prairie_temporaire',
        Sarrasin = 'sarrasin',
        Seigle = 'seigle',
        Soja = 'soja',
        Sorgho = 'sorgho',
        Tournesol = 'tournesol',
        Trefle = 'trefle',
        Triticale = 'triticale',
        Vesce = 'vesce',
        Vigne = 'vigne',
      }

      export const typeLabels = TranslationsUtils.createTranslatedEnumLabel(
        Type,
        type => t => t(`crop.type.previous.${type}`, { ns: 'parcels' }),
      );
    }

    export enum SeedingType {
      SemisDirect = 'semis_direct',
      TCS = 'tcs',
      Labour = 'labour',
      Biologique = 'biologique',
    }

    export const seedingTypeLabels = TranslationsUtils.createTranslatedEnumLabel(
      SeedingType,
      type => t => t(`crop.seeding-type.${type}`, { ns: 'parcels' }),
    );

    const estimatedSeedingQuantityByCrop: { [key in Type]?: [number, number] } = {
      [Type.CerealesHivers]: [500000, 5000000],
      [Type.Colza]: [200000, 700000],
      [Type.Tournesol]: [50000, 95000],
      [Type.Mais]: [60000, 150000],
    };

    export const Params = (t: TFunction) => {
      const safeTranslation = (key: string) =>
        i18next.exists(key, { ns: 'parcels' }) ? t(key, { ns: 'parcels' }) : undefined;

      return z
        .object({
          year: z.coerce.number().int(),
          previousCrop: z.nativeEnum(Previous.Type).nullish(),
          currentCrop: z.nativeEnum(Type),
          stage: z.nativeEnum(Stage),
          noTolerance: z.boolean(),
          estimatedSeedingDate: nonEmptyStringSchema.brand<'LocalDate'>(),
          actualSeedingDate: z.string().brand<'LocalDate'>().nullish(),
          seedingType: z.nativeEnum(SeedingType).nullish(),
          plantCover: z.boolean(),
          plantSpecies: z.string().nullish(),
          estimatedSeedingQuantity: z
            .number()
            .int()
            .positive(safeTranslation('crop.form.errors.estimated-seeding-quantity.positive'))
            .nullish(),
        })
        .superRefine(({ estimatedSeedingQuantity, currentCrop }, ctx) => {
          if (typeof estimatedSeedingQuantity === 'number' && Object.values(Type).includes(currentCrop)) {
            const seedingQuantityRange = estimatedSeedingQuantityByCrop[currentCrop];

            if (seedingQuantityRange) {
              const [min, max] = seedingQuantityRange;

              if (estimatedSeedingQuantity < min || estimatedSeedingQuantity > max) {
                const language = TranslationsUtils.parseLanguage(i18next.language);

                const format = (value: number) => Intl.NumberFormat(language).format(value);

                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  path: ['estimatedSeedingQuantity'],
                  message: t('crop.form.errors.estimated-seeding-quantity.range', {
                    ns: 'parcels',
                    min: format(min),
                    max: format(max),
                  }),
                });
              }
            }
          }
        });
    };

    export type Params = z.infer<ReturnType<typeof Params>>;

    export interface Light
      extends Pick<Crop, 'id' | 'currentCrop' | 'seedingType' | 'plantCover' | 'estimatedSeedingDate'> {
      previousCrop: Previous.Type | null;
      phenologicalStage: Stage;
      lastIntervention?: Intervention | null;
    }

    export interface Campaign {
      parcelId: Parcel.Id;
      cropId: Id;
      year: number;
    }

    export namespace Intervention {
      export const Id = z.string().uuid().brand<'InterventionId'>();
      export type Id = z.infer<typeof Id>;

      export enum Type {
        SoilWork = 'soil_work',
        Seeding = 'seeding',
        Product = 'product',
        Rolling = 'rolling',
      }

      export const typeLabels = TranslationsUtils.createTranslatedEnumLabel(
        Type,
        type => t => t(`crop.intervention.type.${type}`, { ns: 'parcels' }),
      );

      export enum SoilWork {
        Labour = 'labour',
        TravailProfond = 'travail_profond',
        TravailSuperficiel = 'travail_superficiel',
        Affinage = 'affinage',
      }

      export const soilWorkLabels = TranslationsUtils.createTranslatedEnumLabel(
        SoilWork,
        type => t => t(`crop.intervention.soil-work.${type}`, { ns: 'parcels' }),
      );

      export enum SlugProduct {
        Alfaro = 'Alfaro',
        AllowinDuo = 'AllowinDuo',
        AntiEclor = 'AntiEclor',
        Baboxx = 'Baboxx',
        Balesta = 'Balesta',
        Buccata = 'Buccata',
        Carakol = 'Carakol',
        Carakol3 = 'Carakol3',
        CarakolBlue = 'CarakolBlue',
        Cekumeta5 = 'Cekumeta5',
        ContreLimaceNature = 'ContreLimaceNature',
        Ecometal = 'Ecometal',
        FauconPro = 'FauconPro',
        Fennec = 'Fennec',
        Fenometal = 'Fenometal',
        Ferrex = 'Ferrex',
        Firescale = 'Firescale',
        Gusto3 = 'Gusto3',
        HelexiomDuo = 'HelexiomDuo',
        HelitoxB = 'HelitoxB',
        HelitoxQdx = 'HelitoxQdx',
        Ironclad = 'Ironclad',
        IroncladEvo = 'IroncladEvo',
        IroncladMantra = 'IroncladMantra',
        IronmaxMg = 'IronmaxMg',
        IronmaxPro = 'IronmaxPro',
        Kolflor = 'Kolflor',
        LimaOro15 = 'LimaOro15',
        LimaOro30Gr = 'LimaOro30Gr',
        LimaOro50Gb = 'LimaOro50Gb',
        LimadisqueNature = 'LimadisqueNature',
        Limafer = 'Limafer',
        Limakill5G = 'Limakill5G',
        Limarion = 'Limarion',
        LimarionB = 'LimarionB',
        LucioPro = 'LucioPro',
        Magnette = 'Magnette',
        Mataluma30Gr = 'Mataluma30Gr',
        Mataluma50Gb = 'Mataluma50Gb',
        Metalixion = 'Metalixion',
        MetalixionBlue = 'MetalixionBlue',
        MetarexDuo = 'MetarexDuo',
        Metash = 'Metash',
        Metash30Gb = 'Metash30Gb',
        Minixx = 'Minixx',
        MollustopNature = 'MollustopNature',
        Musica = 'Musica',
        NocaSluxx = 'NocaSluxx',
        Opposum = 'Opposum',
        Pixxela = 'Pixxela',
        Sanlim5G = 'Sanlim5G',
        Seedmix = 'Seedmix',
        Skaelim = 'Skaelim',
        SkaelimBlue = 'SkaelimBlue',
        SluggoProd = 'SluggoProd',
        SluxxHp = 'SluxxHp',
        SluxxPro = 'SluxxPro',
        SunboPro = 'SunboPro',
        Superdehyde = 'Superdehyde',
        Superdehyde30Gr = 'Superdehyde30Gr',
        Surikate = 'Surikate',
        Taste = 'Taste',
        TechnoIntens = 'TechnoIntens',
        Turbodisque = 'Turbodisque',
        Turbopads = 'Turbopads',
        Ultimus = 'Ultimus',
        WariorBlue = 'WariorBlue',
        WariorExtra = 'WariorExtra',
        WariorQdx = 'WariorQdx',
        Wifi5 = 'Wifi5',
        XenonmaxPro = 'XenonmaxPro',
      }

      export const slugProductLabels = TranslationsUtils.createTranslatedEnumLabel(
        SlugProduct,
        product => t => t(`crop.intervention.slug-product.${product}`, { ns: 'parcels' }),
      );

      interface DefaultIntervention {
        id: Intervention.Id;
        cropId: Crop.Id;
        date: LocalDate;
        comment: string | null;
      }

      export interface SeedingIntervention extends DefaultIntervention {
        interventionType: Type.Seeding;
      }

      export interface SoilWorkIntervention extends DefaultIntervention {
        interventionType: Type.SoilWork;
        soilWork: SoilWork;
      }

      export interface ProductIntervention extends DefaultIntervention {
        interventionType: Type.Product;
        slugProduct: SlugProduct;
      }

      export interface RollingIntervention extends DefaultIntervention {
        interventionType: Type.Rolling;
      }

      export const CommonParams = z.object({
        date: LocalDate,
        comment: z.string().nullish(),
      });
      export type CommonParams = z.infer<typeof CommonParams>;

      export const Params = z.discriminatedUnion('interventionType', [
        CommonParams.extend({ interventionType: z.literal(Type.Seeding) }),
        CommonParams.extend({ interventionType: z.literal(Type.SoilWork), soilWork: z.nativeEnum(SoilWork) }),
        CommonParams.extend({ interventionType: z.literal(Type.Product), slugProduct: z.nativeEnum(SlugProduct) }),
        CommonParams.extend({ interventionType: z.literal(Type.Rolling) }),
      ]);
      export type Params = z.infer<typeof Params>;
    }

    export type Intervention =
      | Intervention.SeedingIntervention
      | Intervention.SoilWorkIntervention
      | Intervention.ProductIntervention
      | Intervention.RollingIntervention;
  }

  export interface Crop {
    id: Crop.Id;
    year: number;
    previousCrop: Crop.Previous.Type | null;
    currentCrop: Crop.Type;
    stage: Crop.Stage;
    noTolerance: boolean;
    estimatedSeedingDate: LocalDate;
    actualSeedingDate: LocalDate | null;
    seedingType: Crop.SeedingType | null;
    plantCover: boolean;
    plantSpecies: string | null;
    estimatedSeedingQuantity: number | null;
  }

  export const AffectationParams = z.object({
    parcelId: Id,
    sensorId: Sensor.Id,
  });
  export type AffectationParams = z.infer<typeof AffectationParams>;

  export const CreateParcelParams = (t: TFunction) =>
    z.object({
      parcel: CreateParcelParamsInner,
      crop: Crop.Params(t),
    });
  export type CreateParcelParams = z.infer<ReturnType<typeof CreateParcelParams>>;

  export const CreateParcelInfoBody = CreateParcelParamsInner.omit({
    sensorId: true,
  });
  export type CreateParcelInfoBody = z.infer<typeof CreateParcelInfoBody>;

  export const CreateParcelSensorBody = CreateParcelParamsInner.pick({ sensorId: true });
  export type CreateParcelSensorBody = z.infer<typeof CreateParcelSensorBody>;

  export interface Light {
    id: Id;
    label: string;
  }

  export interface WeatherForecast {
    tempMin: Temperature;
    tempMax: Temperature;
    rain: Volume;
    humidity: Percentage;
    wind: Velocity;
  }

  export interface WeatherForecastByTime {
    time: LocalTime;
    temp: Temperature;
    rain: Volume;
    humidity: Percentage;
    windVelocity: Velocity;
    windDirection: Direction;
  }

  export interface WeatherForecastByDay {
    date: LocalDate;
    hours: Array<WeatherForecastByTime>;
  }

  export interface WeatherForecastDetail {
    today: WeatherForecastByDay | null;
    tomorrow: WeatherForecastByDay | null;
    afterTomorrow: WeatherForecastByDay | null;
    fourthDay: WeatherForecastByDay | null;
  }

  export interface Synthesis {
    id: Id;
    label: NonEmptyString;
    risk: Risk.Detail | null;
    count: PestCountWithDate | null;
    weather: Sensor.Weather;
    todayForecast: WeatherForecast | null;
    tomorrowForecast: WeatherForecast | null;
    location: GPSLocation;
    sensor: Sensor.Light | null;
    crop: Crop.Light;
    lateralPictures: Array<Sensor.File>;
    layoutType: InternationalizationSettings.Layout;
  }

  export namespace Histo {
    export namespace Risk {
      export interface RangeItem extends PestCount {
        date: DateTime;
        level: Parcel.Risk.Level | null;
        adviceId: string | null;
        riskId: Parcel.Risk.Id;
        rain: number;
        minTemperature: number | null;
        maxTemperature: number | null;
        cropStage: Parcel.Crop.Stage | null;
        interventionTypes: Array<InterventionItem>;
      }

      export interface Item {
        date: DateTime;
        level: Parcel.Risk.Level | null;
        adviceId: string | null;
        rain: number;
        slugs: Array<[Pest, number]>;
        snails: Array<[Pest, number]>;
        interventions: Array<InterventionItem>;
      }

      export interface InterventionItem {
        id: Parcel.Crop.Intervention.Id;
        interventionType: Parcel.Crop.Intervention.Type;
        soilWork: Parcel.Crop.Intervention.SoilWork | null;
        date: LocalDate;
      }

      export interface Filter extends StringifiableRecord {
        startDate: LocalDate | null;
        endDate: LocalDate | null;
      }

      export interface RequiredFilter extends StringifiableRecord {
        startDate: LocalDate;
        endDate: LocalDate;
      }

      export const filterParser: FilterQueryParser<Filter> = query =>
        Effect.all({
          startDate: QueryUtils.getSchemaQuery(query, LocalDate, 'startDate'),
          endDate: QueryUtils.getSchemaQuery(query, LocalDate, 'endDate'),
        });

      export const requiredFilterParser: FilterQueryParser<RequiredFilter> = query =>
        pipe(
          genericDateRangeFilterParser(query, 'startDate', 'endDate'),
          Effect.map(([startDate, endDate]) => ({ startDate, endDate })),
        );
    }

    export interface Risk<F extends StringifiableRecord>
      extends NestedRangeResult<'range', Parcel.Histo.Risk.RangeItem, F> {
      periodCrop: Parcel.Crop.Type;
    }

    export namespace Weather {
      export interface Measure {
        date: Sensor.DateTime;
        temp: Temperature;
        rain: Volume;
        humidity: Percentage;
      }
    }

    export interface Weather {
      hourly: boolean;
      data: Array<Weather.Measure>;
    }
  }
}

export interface Parcel {
  id: Parcel.Id;
  label: string;
  soilType: Parcel.SoilType;
  owner: Account.Light;
  address: LightAddress;
  location: GPSLocation;
  dsComment: string | null;
  customerComment: string | null;
  sensor: Sensor.Light | null;
  sharingMode: Parcel.Sharing.Mode;
  targetedSharings: Array<Parcel.Sharing.Targeted>;
}
