import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const SupportDrawerCard = styled(Link)`
  display: flex;
  align-items: center;
  padding: 20px 14px;
  background: ${props => props.theme.colors.background[4]};
  border-radius: 10px;
  text-decoration: none;

  > div {
    &:first-of-type {
      flex: 0 0 40px;
      margin-right: 10px;
    }
  }
`;
