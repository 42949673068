import React, { FC, useState } from 'react';
import { DateFormat, DateRangeFilter, formatDate, parseDate } from '@shared/modules/dates';
import { Group, Select } from '@mantine/core';
import { Translations } from '@core/translations';
import { addDays, differenceInDays, isEqual, startOfToday, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DatePickerInput, DatePickerValue } from '@mantine/dates';
import { Option, pipe, ReadonlyArray, Tuple } from 'effect';

type DatesFilterPresetType = 'today' | 'week' | 'month' | 'custom';

const MAX_RANGE_DIFFERENCE = 30;

interface DatesFilterPreset {
  label: Translations.TranslationInterpolation;
  preset?: () => [Date, Date];
}

export const dateFiltersPresets = {
  today: {
    label: t => t('dates.filter.today'),
    preset: () => {
      const now = startOfToday();

      return [now, now];
    },
  },
  week: {
    label: t => t('dates.filter.last-week'),
    preset: () => {
      const now = startOfToday();

      return [subDays(now, 6), now];
    },
  },
  month: {
    label: t => t('dates.filter.last-month'),
    preset: () => {
      const now = startOfToday();

      return [subDays(now, 30), now];
    },
  },
  custom: {
    label: t => t('dates.filter.custom'),
  },
} satisfies Record<DatesFilterPresetType, DatesFilterPreset>;

function datesToFilter([from, to]: [Date, Date]): DateRangeFilter {
  return { from: formatDate(from), to: formatDate(to) };
}

interface DatesFilterProps {
  filter: DateRangeFilter;
  onChange: (filter: DateRangeFilter) => void;
}

const DatesFilter: FC<DatesFilterProps> = ({ filter, onChange }) => {
  const { t } = useTranslation();

  const [currentDate, setCurrentDate] = useState<[Date | null, Date | null]>(() =>
    Tuple.tuple(parseDate(filter.from, DateFormat.LocalDate, null), parseDate(filter.to, DateFormat.LocalDate, null)),
  );

  const [from, to] = currentDate;

  const [preset, setPreset] = useState<DatesFilterPresetType>(() =>
    pipe(
      Option.all([Option.fromNullable(from), Option.fromNullable(to)]),
      Option.flatMap(([from, to]) =>
        pipe(
          Object.entries(dateFiltersPresets),
          ReadonlyArray.findFirst(([, value]) => {
            if ('preset' in value) {
              const [presetFrom, presetTo] = value.preset();

              return isEqual(from, presetFrom) && isEqual(to, presetTo);
            }

            return false;
          }),
        ),
      ),
      Option.match({
        onNone: () => 'custom',
        onSome: ([preset]) => preset as DatesFilterPresetType,
      }),
    ),
  );

  const data = Object.entries(dateFiltersPresets).map(([value, { label }]) => ({ value, label: label(t) }));

  const handlePresetChange = (preset: DatesFilterPresetType) => {
    setPreset(preset);

    const presetValue = dateFiltersPresets[preset];

    if ('preset' in presetValue) {
      const presetDates = presetValue.preset();

      setCurrentDate(presetDates);
      onChange(datesToFilter(presetDates));
    }
  };

  const handleDatePickerChange = ([from, to]: DatePickerValue<'range'>) => {
    if (from && to) {
      const checkedTo = differenceInDays(to, from) > MAX_RANGE_DIFFERENCE ? addDays(from, MAX_RANGE_DIFFERENCE) : to;

      onChange(datesToFilter([from, checkedTo]));
      setCurrentDate([from, checkedTo]);
    } else {
      setCurrentDate([from, to]);
    }
  };

  return (
    <Group spacing={10}>
      <Select size="sm" value={preset} data={data} onChange={handlePresetChange} />

      {!('preset' in dateFiltersPresets[preset]) ? (
        <DatePickerInput
          type="range"
          size="sm"
          value={currentDate}
          onChange={handleDatePickerChange}
          allowSingleDateInRange={true}
          maxDate={new Date()}
          placeholder={t('dates.filter.placeholder')}
        />
      ) : null}
    </Group>
  );
};

export default DatesFilter;
