import { z } from 'zod';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { filterEmptyStringToOption } from '@shared/utils/string';
import { Function, Option } from 'effect';

export const PhoneNumber = z.string().refine(phoneNumber =>
  Option.match(filterEmptyStringToOption(phoneNumber), {
    onNone: Function.constTrue,
    onSome: isValidPhoneNumber,
  }),
);

export type PhoneNumber = z.infer<typeof PhoneNumber>;
