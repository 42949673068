import { Effect, Option, pipe, Ref } from 'effect';
import { EffectUniq } from '@core/fp/uniq';

export class EffectCache<E, A> {
  private cache = Ref.unsafeMake<Option.Option<A>>(Option.none());
  private uniq = new EffectUniq<E, A>();

  constructor(private effect: Effect.Effect<never, E, A>) {}

  getCached = () => {
    return Ref.get(this.cache);
  };

  getNonCached = (): Effect.Effect<never, E, A> => {
    return pipe(
      this.uniq.lock(this.effect),
      Effect.tap(data => Ref.set(this.cache, Option.some(data))),
    );
  };

  get = (): Effect.Effect<never, E, A> => {
    return pipe(
      this.getCached(),
      Effect.flatMap(
        Option.match({
          onSome: Effect.succeed,
          onNone: this.getNonCached,
        }),
      ),
    );
  };

  clear = () => {
    return Ref.set(this.cache, Option.none());
  };
}
