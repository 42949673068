import React, { CSSProperties, FC, useEffect } from 'react';

interface VirtualizedLoadingRowProps {
  index: number;
  style: CSSProperties;
  loadRow: (index: number) => void;
}

const VirtualizedLoadingRow: FC<VirtualizedLoadingRowProps> = ({ index, style, loadRow }) => {
  useEffect(() => {
    loadRow(index);
  });

  return <div style={style} />;
};

export default VirtualizedLoadingRow;
