import React, { FC, PropsWithChildren } from 'react';

import * as Styled from './AuthLayout.styles';

import logo from '@assets/logos/logo.svg';
import logoDS from '@assets/logos/desangosse.svg';

import authBackground480 from '@assets/images/auth/auth-background-480.jpg';
import authBackground720 from '@assets/images/auth/auth-background-720.jpg';
import authBackground960 from '@assets/images/auth/auth-background-960.jpg';
import authBackground1440 from '@assets/images/auth/auth-background-1440.jpg';
import authBackground1920 from '@assets/images/auth/auth-background-1920.jpg';
import generateSizes from 'html-sizes';
import { useTheme } from '@emotion/react';

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  return (
    <Styled.AuthLayoutContainer>
      <Styled.AuthLayoutBackgroundWrapper>
        <img
          sizes={generateSizes({ [theme.fn.smallerThan('md')]: '100vw', default: '50vw' }, { maxDPR: 2 })}
          src={authBackground480}
          srcSet={`${authBackground480} 480w, ${authBackground720} 720w, ${authBackground960} 960w, ${authBackground1440} 1440w, ${authBackground1920} 1920w`}
          alt="Background"
        />
      </Styled.AuthLayoutBackgroundWrapper>

      <Styled.AuthLayoutContentContainer>
        <Styled.AuthLayoutLogoWrapper>
          <img src={logo} width={107} height={107} alt="logo" />
          <img src={logoDS} width={140} height={100} alt="logo" />
        </Styled.AuthLayoutLogoWrapper>

        <Styled.AuthLayoutContentWrapper>
          <Styled.AuthLayoutContent>{children}</Styled.AuthLayoutContent>
        </Styled.AuthLayoutContentWrapper>
      </Styled.AuthLayoutContentContainer>
    </Styled.AuthLayoutContainer>
  );
};

export default AuthLayout;
