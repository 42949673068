import { filterEmptyStringToOption } from '@shared/utils/string';
import config from '@config/index';
import { Role } from '@modules/roles/model';
import { Translations } from '@core/translations';
import { Export } from '@shared/modules/exports/model';
import { Effect, Option, pipe } from 'effect';

export namespace MatomoService {
  export function init() {
    pipe(
      Option.all({
        tracker: filterEmptyStringToOption(config.VITE_MATOMO_TRACKER),
        script: filterEmptyStringToOption(config.VITE_MATOMO_SCRIPT),
        siteId: filterEmptyStringToOption(config.VITE_MATOMO_SITE_ID),
      }),
      Effect.tap(config =>
        Effect.all([
          pushInstruction('enableLinkTracking'),
          pushInstruction('setTrackerUrl', config.tracker),
          pushInstruction('setSiteId', config.siteId),
        ]),
      ),
      Effect.flatMap(config =>
        Effect.sync(() => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.src = config.script;

          document.head.appendChild(script);
        }),
      ),
      Effect.orElse(() => Effect.logInfo('Invalid matomo config')),
      Effect.runSync,
    );
  }

  function pushInstruction(method: string, ...args: Array<unknown>) {
    return Effect.sync(() => {
      const filterArgs = args.filter(arg => arg != null);

      (window._paq = window._paq || []).push([method, ...filterArgs]);
    });
  }

  function syncPageInfo(url = window.location.href, pageTitle = window.document.title) {
    return pipe(
      Effect.all([pushInstruction('setDocumentTitle', pageTitle), pushInstruction('setCustomUrl', url)]),
      Effect.asUnit,
    );
  }

  export function trackPageView(url?: string, pageTitle?: string) {
    return pipe(Effect.all([syncPageInfo(url, pageTitle), pushInstruction('trackPageView')]), Effect.asUnit);
  }

  function setCustomDimension(id: number, value: string) {
    return pushInstruction('setCustomDimension', id, value);
  }

  export function setAccountTypeDimension(accountType: Role.AccountType) {
    return setCustomDimension(1, accountType);
  }

  export function setLanguageDimension(language: Translations.Language) {
    return setCustomDimension(2, language);
  }

  function trackEvent(category: string, action: string, name?: string, value?: string) {
    return pipe(
      Effect.all([syncPageInfo(), pushInstruction('trackEvent', category, action, name, value)]),
      Effect.asUnit,
    );
  }

  export function trackLogin() {
    return trackEvent('connect', 'login');
  }

  export function trackExport(type: Export.Type, format: Export.Format) {
    return trackEvent('export', 'download', `${type}/${format}`);
  }
}
