import React, { FC } from 'react';
import { useStandaloneRange } from '@shared/modules/range';
import { NotificationsService } from '@modules/notifications/service';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ActionIcon, Box, Divider, Title, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import VirtualizedListContent from '@shared/modules/range/components/VirtualizedListContent';
import NotificationRow from '@modules/notifications/components/row/NotificationRow';

export const NOTIFICATIONS_SCROLL_CLASSNAME = 'notifications-scroller';

interface NotificationsListProps {
  onClose: () => void;
}

const NotificationsList: FC<NotificationsListProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const { loading, range, handleLoadPage } = useStandaloneRange(NotificationsService.getRange);

  const virtualizer = useVirtualizer({
    count: range.total,
    getScrollElement: () => document.querySelector(`.${NOTIFICATIONS_SCROLL_CLASSNAME}`),
    estimateSize: () => 20,
  });

  return (
    <>
      {loading ? <DebouncedLineLoader /> : null}

      <Box pos="sticky" top="0" p={20} pb={0} bg="background.0" sx={{ zIndex: 1 }}>
        <ActionIcon size={36} variant="outline" color="background.5" c="background.9" onClick={onClose}>
          <IconArrowRight />
        </ActionIcon>

        <Title fz="lg" my={15}>
          {t('notifications.title')}
        </Title>

        <Divider />
      </Box>

      <Box p={20} pt={15}>
        {!loading && range.total === 0 ? (
          <Text mt={20} align="center">
            {t('notifications.placeholder')}
          </Text>
        ) : (
          <VirtualizedListContent virtualizer={virtualizer} range={range} loadPage={handleLoadPage}>
            {notification => <NotificationRow notification={notification} />}
          </VirtualizedListContent>
        )}
      </Box>
    </>
  );
};

export default NotificationsList;
