import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

export const subscriptionsRoutes = (
  <>
    <Route path="subscriptions">
      <Route index {...createLazyRoute(module, m => m.subscriptionsListRoute)} />
      <Route path="new" {...createLazyRoute(module, m => m.createSubscriptionRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.detailSubscriptionRoute)} />
    </Route>

    <Route path="subscriptions-mine">
      <Route index {...createLazyRoute(module, m => m.mySubscriptionsListRoute)} />
      <Route path=":id" {...createLazyRoute(module, m => m.detailMySubscriptionRoute)} />
    </Route>
  </>
);

export default subscriptionsRoutes;
