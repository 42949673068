import { defineRoute, redirect } from '@core/router';
import { defineLoader } from '@core/router/loader';
import { Effect, pipe } from 'effect';
import z from 'zod';
import { OAuthService } from '@core/oauth/service';
import { OAuth } from '@core/oauth/model';
import { QueryUtils } from '@shared/utils/queries';

const params = z.object({
  token: OAuth.AccessToken,
});

const loader = defineLoader({
  params,
  handler: ({ params, request }) =>
    pipe(
      OAuthService.saveOAuthTokensInStorage({ access_token: params.token, refresh_token: null }),
      Effect.tap(() =>
        pipe(
          QueryUtils.parseFromRequest(request),
          Effect.flatMap(queries => QueryUtils.getStringQuery(queries, 'referrer')),
          Effect.flatMap(referrer => redirect(referrer ?? '/')),
        ),
      ),
    ),
});

const tokenLoginRoute = defineRoute({
  ns: 'login',
  loader,
});

export default tokenLoginRoute;
