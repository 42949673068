import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

export const rolesRoutes = (
  <Route path="roles">
    <Route index {...createLazyRoute(module, m => m.rolesListRoute)} />
    <Route path="new" {...createLazyRoute(module, m => m.createRoleRoute)} />
    <Route path=":id" {...createLazyRoute(module, m => m.roleDetailRoute)} />
  </Route>
);

export default rolesRoutes;
