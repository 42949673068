import styled from '@emotion/styled';
import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';

export const BottomPageContainer = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  background: ${props => props.theme.colors.background[0]};
  border-top: 1px solid ${props => props.theme.colors.background[4]};
  box-shadow: 0 -14px 8px 0 rgba(255, 255, 255, 0.45);
  z-index: 100;
`);
